import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  InputLabel,
  useMediaQuery,
  CircularProgress,
  Checkbox,
  IconButton,
  Typography,
  FormControlLabel,
} from "@mui/material";
import Styles from "./styles/reservation.module.css";
import { StandardInputField } from "../../components/standard-input-field/standardInputField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import OptionPill from "../../components/option-pill/OptionPill";
import "./styles/reservation.module.css";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  CELL_TYPE,
  ENDPOINT,
  TOAST_CONFIG,
  USER_TYPE,
} from "../../config/constants";
import {
  resetCustomerReservationData,
  setCustomerReservationData,
  setRestaurantsList,
} from "../../redux/slices/masterDataSlice";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import PDR from "../../assets/images/icon_pdr.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  addMinutesToTime,
  convertDateFormat,
  dataURLtoFile,
  formatAMPM,
  formatISODateString,
} from "../../utils";
import { resetCustomerData } from "../../redux/slices/customerSlice";
import BookingTicket from "../../components/booking-ticket/BookingTicket";
import { Decrypt } from "../../Crypto-Helper";
import dayjs from "dayjs";
import Ticket from "../../components/ticket/Ticket";
import TimeSelector from "../../components/time-selector/TimeSelector";
import TBL1Active from "../../assets/icons/1-table-active.svg";
import TBL1Inactive from "../../assets/icons/1-table-inactive.svg";
import TBL1Arrived from "../../assets/icons/1-table-arrived.svg";
import TBL2Active from "../../assets/icons/2-table-active.svg";
import TBL2Inactive from "../../assets/icons/2-table-inactive.svg";
import TBL2Arrived from "../../assets/icons/2-table-arrived.svg";
import TBL4Active from "../../assets/icons/4-table-active.svg";
import TBL4Inactive from "../../assets/icons/4-table-inactive.svg";
import TBL4Arrived from "../../assets/icons/4-table-arrived.svg";
import TBL6Active from "../../assets/icons/6-table-active.svg";
import TBL6Inactive from "../../assets/icons/6-table-inactive.svg";
import TBL6Arrived from "../../assets/icons/6-table-arrived.svg";
import PDR6Active from "../../assets/icons/6-pdr-active.svg";
import PDR6Inactive from "../../assets/icons/6-pdr-inactive.svg";
import PDR6Arrived from "../../assets/icons/6-pdr-arrived.svg";
import PDR8Active from "../../assets/icons/8-pdr-active.svg";
import PDR8Inactive from "../../assets/icons/8-pdr-inactive.svg";
import PDR8Arrived from "../../assets/icons/8-pdr-arrived.svg";
import PDR10Active from "../../assets/icons/10-pdr-active.svg";
import PDR10Inactive from "../../assets/icons/10-pdr-inactive.svg";
import PDR10Arrived from "../../assets/icons/10-pdr-arrived.svg";
import PDR12Active from "../../assets/icons/12-pdr-active.svg";
import PDR12Inactive from "../../assets/icons/12-pdr-inactive.svg";
import PDR12Arrived from "../../assets/icons/12-pdr-arrived.svg";
import RL from "@mui/icons-material/West";
import LR from "@mui/icons-material/East";
import TB from "@mui/icons-material/South";
import BT from "@mui/icons-material/North";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Controls from "../../components/controls/Controls";

const slotProps = {
  textField: {
    placeholder: "Date",
    variant: "standard",

    sx: {
      width: "100%",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
      // marginRight: "30px",
      marginBottom: "45px",
    },
    // inputProps: {
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   marginBottom: "5px",
    // },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const timeSlotProps = {
  textField: {
    placeholder: "Time",
    variant: "standard",
    sx: {
      marginBottom: "45px",
      width: "50%",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
    },
    // inputProps: {
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   marginBottom: "5px",
    // },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const Reservation = ({ edit }) => {
  const isMobile = useMediaQuery("(max-width:650px)");
  const { clientId, reservationId } = useParams();
  const decryptedClientId = clientId ? Decrypt(clientId) : null;
  const decryptedReservationId = reservationId ? Decrypt(reservationId) : null;
  const navigate = useNavigate();
  const { logout, role } = useAuth();
  const dispatch = useDispatch();
  const { restaurantsList, server, customerReservationData } = useSelector(
    (state) => state.masterData
  );
  const { mobileNumber, countryCode } = useSelector((state) => state.customer);
  const [layout, setLayout] = useState(null);
  const [includeRemark, setIncludeRemark] = useState(true);
  const [restaurant, setRestaurant] = useState(null);
  const [bookedTbl, setBookedTbl] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [bookingTicket, setBookingTicket] = useState(null);
  const [minuteSteps, setMinuteSteps] = useState(30);
  const [updateState, setUpdateState] = useState(0);
  const [restIndex, setRestIndex] = useState(0);
  const [themeDetails, setThemeDetails] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNo: mobileNumber,
    date: dayjs(new Date()),
    time: null,
    peopleCount: "",
    restId: "",
    gender: "Male",
    userId: decryptedClientId ? decryptedClientId : null,
    tableDetails: null,
    remark: "",
    tableNumber: ""
    
  });
  const [time, setTime] = useState({
    hh: formatAMPM(new Date())?.hours,
    mm: formatAMPM(new Date())?.minutes,
    meridiem: formatAMPM(new Date())?.ampm,
  });
  const [formErrors, setFormErrors] = useState({});

  const fnameRef = useRef(null);
  const lnameRef = useRef(null);
  const mobileRef = useRef(null);
  const dateRef = useRef(null);
  const paxRef = useRef(null);
  const remarkRef = useRef(null);

  const blurAllInputs = () => {
    if (fnameRef.current) fnameRef.current.blur();
    if (lnameRef.current) lnameRef.current.blur();
    if (mobileRef.current) mobileRef.current.blur();
    if (dateRef.current) dateRef.current.blur();
    if (paxRef.current) paxRef.current.blur();
    if (remarkRef.current) remarkRef.current.blur();
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetCustomerReservationData());
  //   };
  // }, [decryptedClientId]);

  useEffect(() => {
    // getAllRestaurants();
    setBookedTbl([]);
    getAllReservations();
  }, [time, formData.date, formData.restId]);

  useEffect(() => {
    if (restaurantsList?.data?.data?.original?.length) {
      setFormData({
        ...formData,
        restId: restaurantsList?.data?.data?.original[restIndex]?.id,
      });
      setRestaurant(restaurantsList?.data?.data?.original[restIndex]);
      setThemeDetails(restaurantsList?.data?.theme_details);
      setMinuteSteps(
        restaurantsList?.data?.data?.original[restIndex]?.time_interval
      );
      setLayout(
        JSON.parse(
          restaurantsList?.data?.data?.original[restIndex]?.restroLayout
        )
      );
    }
  }, [restaurantsList, loading, updateState]);

  useEffect(() => {
    customerReservationData &&
      setFormData((prev) => ({ ...prev, ...customerReservationData }));
  }, [updateState, customerReservationData]);

  const getReservationDetails = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("reservationId", decryptedReservationId);
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_RESERVATION_BY_ID
          : ENDPOINT.GET_RESERVATION_BY_ID,
        payload,
        true
      );
      if (resp?.response?.data?.status === 200) {
        setUserData(resp?.response?.data);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        // toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const handleClickTable = (tbl) => {
    blurAllInputs();
    if (tbl?.booked) {
      return;
    }
    const tblIdx = bookedTbl.findIndex((t) => t.x === tbl.x && t.y === tbl.y);
    if (tblIdx >= 0) {
      const arrCopy = [...bookedTbl];
      arrCopy.splice(tblIdx, 1);
      setBookedTbl(arrCopy);
    } else {
      setBookedTbl((prev) => [...prev, tbl]);
    }
  };

  const getUserDetails = async () => {
    setLoading(true);
    try {
      await GET(
        `${
          role === USER_TYPE.MANAGER
            ? ENDPOINT.GET_USER_FOR_ADMIN
            : ENDPOINT.GET_USER
        }/${decryptedClientId}`
      ).then((resp) => {
        if (resp?.response?.data?.status === 200) {
          const userData = {
            // ...resp?.response?.data?.data,
            firstName: resp?.response?.data?.data?.name,
            lastName: resp?.response?.data?.data?.lastName,
            countryCode: resp?.response?.data?.data?.countryCode,
            gender: resp?.response?.data?.data?.gender,
          };
          dispatch(setCustomerReservationData(userData));
          // setFormData((prev) => ({ ...prev, ...userData }));
          setUpdateState((prev) => prev++);
        } else if (
          resp?.response?.data?.status === 401 &&
          resp?.response?.data?.message === "User Token Expired"
        ) {
          // toast.error("Your session has expired!", TOAST_CONFIG);
          logout();
        } else {
          toast.error(resp?.response?.data?.message, TOAST_CONFIG);
        }
      });
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const setUserData = async (data) => {
    const userData = {
      firstName: data?.data?.name,
      lastName: data?.data?.lastName,
      contactNo: data?.data?.contactNo,
    };
    setFormData((prev) => ({ ...prev, ...userData }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      ((name === "firstName" || name === "lastName") && value.length > 20) ||
      (name === "remark" &&
        (includeRemark ? value.length > 30 : value.length > 500)) ||
      (name === "peopleCount" && value < 0) ||
      ((name === "peopleCount" || name === "contactNo") && isNaN(value))
    ) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date,
    });
    setFormErrors({
      ...formErrors,
      date: "",
    });
  };
  const handleTimeChange = (time) => {
    setFormData({
      ...formData,
      time,
    });
    setFormErrors({
      ...formErrors,
      time: "",
    });
  };

  const handleGenderOptionPillClick = (newGenderOption) => {
    setFormData({
      ...formData,
      gender: newGenderOption,
    });
  };

  const handleRestaurantOptionPillClick = (newRestaurantOption) => {
    setFormData({
      ...formData,
      restId: newRestaurantOption.id,
    });
    setLayout(JSON.parse(newRestaurantOption?.restroLayout));
    setRestaurant(newRestaurantOption);
    setMinuteSteps(newRestaurantOption?.time_interval);
    setBookedTbl([]); 
  };

  const handleTime = (e) => {
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: "",
    }));
    setTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    const errors = {};
    if (!formData.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!formData.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!formData.contactNo) {
      errors.contactNo = "Phone is required";
    }
    if (formData.peopleCount === '0') {
      errors.peopleCount = "No. of People is required";
    }

    if (!formData.peopleCount) {
      errors.peopleCount = "No. of People is required";
    }
    if (!formData.date) {
      errors.date = "Date is required";
    }
    if (!time.hh) {
      errors.hh = "required";
    }
    if (!time.mm) {
      errors.mm = "required";
    }
    if (!time.meridiem) {
      errors.meridiem = "required";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const payload = new FormData();
      decryptedClientId && payload.append("userId", decryptedClientId);
      payload.append("firstName", formData.firstName);
      payload.append("lastName", formData.lastName);
      payload.append("countryCode", countryCode);
      payload.append("contactNo", formData.contactNo);
      payload.append("restId", formData.restId);
      payload.append("peopleCount", formData.peopleCount);
      payload.append("gender", formData.gender);
      payload.append("booking_remark", formData.remark);
      payload.append("showRemark", includeRemark ? 1 : 0);
      bookingTicket &&
        payload.append(
          "bookinCard",
          bookingTicket
            ? dataURLtoFile(
                bookingTicket,
                `${formData.firstName}${formData?.lastName}-booking.png`
              )
            : ""
        );

      if (bookedTbl?.length) {
        bookedTbl.forEach((tbl) => {
          payload.append("tableDetails[]", tbl ? JSON.stringify(tbl) : "");
          payload.append("tableNumber[]", tbl ? tbl.number : "");
        });
      } else {
        payload.append(
          "tableDetails[]",
          JSON.stringify({
            x: "",
            y: "",
            type: "",
            number: "",
            booked: false,
            id: 0,
            booking_date: "",
            booking_time: "",
            booking_remark: "",
            people_count: 0,
            userId: 0,
            name: "",
            lastName: "",
            contactNo: 0,
            created_at: "",
            updated_at: "",
            staff_id: 0,
            category: "",
          })
        );
        payload.append("tableNumber[]", "");
      }

      payload.append(
        "booking_date",
        formatISODateString(new Date(formData.date).toISOString())
      );
      payload.append(
        "booking_time",
        `${time.hh}:${time.mm}:00 ${time.meridiem}`
      );
      payload.append("sendingBy", server);

      try {
        setSubmitting(true);
        const response = await POST_MULTIPART(
          ENDPOINT.ADD_RESERVATION,
          payload,
          true
        );
        if (response?.response?.data?.status === 200) {
          toast.success(response?.response?.data?.message, TOAST_CONFIG);
          dispatch(resetCustomerData());
          dispatch(resetCustomerReservationData());
          navigate("/customer-search", { replace: true });
        } else if (
          response?.response?.data?.status === 401 &&
          response?.response?.data?.message === "User Token Expired"
        ) {
          logout();
        } else {
          toast.error(response?.response?.data?.message, TOAST_CONFIG);
        }
      } catch (error) {
        toast.error(error.message, TOAST_CONFIG);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getAllReservations = async () => {
    try {
      const payload = new FormData();
      payload.append(
        "reservationDate",
        formatISODateString(new Date(formData.date).toISOString())
      );
      payload.append("fromTime", `${time.hh}:${time.mm}:00 ${time.meridiem}`);
      // payload.append(
      //   "toTime",
      //   `${
      //     parseInt(time.mm) === 0
      //       ? time.hh
      //       : parseInt(time.hh) + 1 < 10
      //       ? "0" + (parseInt(time.hh) + 1)
      //       : parseInt(time.hh) + 1
      //   }:${parseInt(time.mm) === 0 ? 30 : "00"}:00 ${time.meridiem}`
      // );
      payload.append(
        "toTime",
        addMinutesToTime(
          `${time.hh}:${time.mm}:00 ${time.meridiem}`,
          restaurant?.occupancy_time
            ? parseInt(restaurant?.occupancy_time)
            : 120
        )
      );
      const resp = await POST_MULTIPART(
        ENDPOINT.GET_ALL_RESERVATION_BY_DATE_TIME,
        payload,
        true
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const getAllRestaurants = async () => {
    try {
      const resp = await GET(ENDPOINT.GET_ALL_RESTAURANTS);
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const checkTableSelected = (x, y) => {
    let result = false;
    let index = bookedTbl.findIndex((tbl) => tbl.x === x && tbl.y === y);
    if (index >= 0) {
      result = true;
    }
    return result;
  };

  const getTableShape = (table, isActive) => {
    const { isArrived } = table;
    switch (parseInt(table?.capacity)) {
      case 1:
        return (
          <img
            className={Styles.tblImage}
            src={
              isActive && isArrived
                ? TBL1Arrived
                : isActive
                ? TBL1Active
                : TBL1Inactive
            }
          />
        );
      case 2:
        return (
          <img
            className={Styles.tblImage}
            src={
              isActive && isArrived
                ? TBL2Arrived
                : isActive
                ? TBL2Active
                : TBL2Inactive
            }
          />
        );
      case 4:
        return (
          <img
            className={Styles.tblImage}
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                ? TBL4Active
                : TBL4Inactive
            }
          />
        );
      case 6:
        return (
          <img
            className={Styles.tblImage}
            src={
              isActive && isArrived
                ? TBL6Arrived
                : isActive
                ? TBL6Active
                : TBL6Inactive
            }
          />
        );
      default:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                ? TBL4Active
                : TBL4Inactive
            }
          />
        );
    }
  };

  const getPDRShape = (pdr, isActive) => {
    const { isArrived } = pdr;
    switch (parseInt(pdr?.capacity)) {
      case 6:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                ? PDR6Active
                : PDR6Inactive
            }
          />
        );
      case 8:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR8Arrived
                : isActive
                ? PDR8Active
                : PDR8Inactive
            }
          />
        );
      case 10:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR10Arrived
                : isActive
                ? PDR10Active
                : PDR10Inactive
            }
          />
        );
      case 12:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR12Arrived
                : isActive
                ? PDR12Active
                : PDR12Inactive
            }
          />
        );
      default:
        return (
          <img
            className={Styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                ? PDR6Active
                : PDR6Inactive
            }
          />
        );
    }
  };

  const getEntranceDirection = (cell) => {
    switch (cell?.direction) {
      case "top-down":
        return <TB />;
      case "down-top":
        return <BT />;
      case "left-right":
        return <LR />;
      case "right-left":
        return <RL />;
      default:
        return null;
    }
  };

  const getCell = (row, col) => {
    const cellData = layout?.data?.filter(
      (cell) => cell.y === col && cell.x === row
    );

    if (cellData.length) {
      const cell = cellData[0];
      if (cell.type === CELL_TYPE.TABLE)
        return (
          <div
            // className={`${Styles.tableCell} ${
            //   checkTableSelected(cell?.x, cell?.y) || cell?.booked
            //     ? Styles.active
            //     : ""
            // }`}
            className={Styles.tableCell}
            onClick={() => handleClickTable(cell)}
          >
            {getTableShape(
              cell,
              checkTableSelected(cell?.x, cell?.y) || cell?.booked
            )}
            <span className={Styles.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.PDR)
        return (
          <div
            // className={`${Styles.pdrCell} ${
            //   checkTableSelected(cell?.x, cell?.y) || cell?.booked
            //     ? Styles.active
            //     : ""
            // }`}
            className={Styles.tableCell}
            onClick={() => handleClickTable(cell)}
          >
            {getPDRShape(
              cell,
              checkTableSelected(cell?.x, cell?.y) || cell?.booked
            )}
            <span className={Styles.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.BAR)
        return (
          <div className={Styles.cellInside}>
            <div id="bar" className={Styles.barCell}></div>
          </div>
        );
      if (cell.type === CELL_TYPE.ENTRANCE)
        return (
          <div id="rntrance" className={Styles.redCarpet}>
            {getEntranceDirection(cell)}
          </div>
        );
      return cell.type;
    } else return <div className={Styles.emptyCell}></div>;
  };

  function renderGrid() {
    const grid = [];

    for (let i = 0; i < layout?.row; i++) {
      const row = [];
      for (let j = 0; j < layout?.col; j++) {
        // You can customize the content of each grid cell here
        row.push(
          <div className={Styles.gridCell} key={`${i}-${j}`}>
            {/* {`${j}-${i}`} */}
            {getCell(j, i)}
          </div>
        );
      }
      grid.push(
        <div className={Styles.gridRow} key={i}>
          {row}
        </div>
      );
    }

    return grid;
  }
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={4} lg={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                  marginX: isMobile ? "20px" : "65px",
                  mb: isMobile ? "50px" : "120px",
                }}
                className={Styles.resveationLeftBox}
              >
                <InputLabel sx={{ marginBottom: "20px", fontSize: "24px" }}>
                  Add Reservation
                </InputLabel>
                <StandardInputField
                  inputRef={fnameRef}
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  error={formErrors.firstName}
                  helperText={formErrors.firstName}
                />
                <StandardInputField
                  inputRef={lnameRef}
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  error={formErrors.lastName}
                  helperText={formErrors.lastName}
                />
                <StandardInputField
                  inputRef={mobileRef}
                  type="tel"
                  placeholder="Phone"
                  name="contactNo"
                  value={formData.contactNo}
                  onChange={handleInputChange}
                  error={formErrors.contactNo}
                  helperText={formErrors.contactNo}
                />
                <Box
                  sx={{
                    width: !isMobile ? "359px" : "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className={Styles.dateTimeCtaWrap}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      format="DD/MM/YYYY"
                      value={formData.date}
                      slotProps={slotProps}
                      error={formErrors.date}
                      helperText={formErrors.date}
                      onChange={(newValue) => handleDateChange(newValue)}
                      slots={{
                        textField: (props) => (
                          <TextField
                            {...props}
                            inputRef={dateRef}
                            error={formErrors.date}
                            helperText={formErrors.date}
                          />
                        ),
                      }}
                    />
                    {/* <MobileTimePicker
                      slotProps={timeSlotProps}
                      value={formData.time}
                      minutesStep={parseInt(minuteSteps)}
                      format="hh:mm"
                      onChange={(newValue) => handleTimeChange(newValue)}
                      slots={{
                        textField: (props) => (
                          <TextField
                            {...props}
                            error={formErrors.time}
                            helperText={formErrors.time}
                          />
                        ),
                      }}
                    /> */}
                  </LocalizationProvider>
                </Box>
                <Box
                  sx={{
                    marginBottom: "25px",
                    width: "100%",
                  }}
                >
                  <TimeSelector
                    values={time}
                    onChange={handleTime}
                    error={{
                      hh: formErrors.hh,
                      mm: formErrors.mm,
                      meridiem: formErrors.meridiem,
                    }}
                  />
                </Box>
                <StandardInputField
                  inputRef={paxRef}
                  type="number"
                  placeholder="No. of People"
                  name="peopleCount"
                  value={formData.peopleCount}
                  onChange={handleInputChange}
                  error={formErrors.peopleCount}
                  helperText={formErrors.peopleCount}
                />
                <Box
                  sx={{
                    marginBottom: "30px",
                    width: !isMobile ? "359px" : "100%",
                  }}
                  className={Styles.resturantWidth}
                >
                  <Box
                    sx={{
                      borderBottom: "2px solid #D9D9D9",
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        mb: "10px",
                        width: "280px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Restaurants
                    </InputLabel>
                  </Box>
                  <Box
                    sx={{ width: !isMobile ? "359px" : "100%", flexGrow: "1" }}
                    className={Styles.resturantWidth}
                  >
                    {restaurantsList?.data?.data?.original?.map(
                      (restaurant, idx) => (
                        <OptionPill
                          key={restaurant.id}
                          active={formData.restId === restaurant.id}
                          text={restaurant.name}
                          onClick={() => {
                            handleRestaurantOptionPillClick(restaurant);
                            setRestIndex(idx);
                          }}
                        />
                      )
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginBottom: "30px",
                    width: !isMobile ? "359px" : "100%",
                  }}
                  className={Styles.resturantWidth}
                >
                  <Box
                    sx={{
                      borderBottom: "2px solid #D9D9D9",
                      marginBottom: "15px",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        mb: "10px",
                      }}
                    >
                      Gender
                    </InputLabel>
                  </Box>
                  <Box
                    sx={{ width: !isMobile ? "359px" : "100%", flexGrow: "1" }}
                  >
                    <OptionPill
                      active={formData.gender === "Male"}
                      text="Male"
                      onClick={() => handleGenderOptionPillClick("Male")}
                    />
                    <OptionPill
                      active={formData.gender === "Female"}
                      text="Female"
                      onClick={() => handleGenderOptionPillClick("Female")}
                    />
                  </Box>
                </Box>
                <StandardInputField
                  type="text"
                  placeholder="Remark"
                  name="remark"
                  value={formData.remark}
                  onChange={handleInputChange}
                  error={formErrors.remark}
                  helperText={formErrors.remark}
                  sx={{ marginBottom: 1 }}
                  isMultiline={!includeRemark}
                />
                {!includeRemark && (
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{ textAlign: "end", color: "grey" }}
                    >
                      Characters limit : {formData?.remark?.length || 0}/500
                    </Typography>
                  </Box>
                )}
                <FormControlLabel
                  control={<Checkbox checked={includeRemark} />}
                  label="Include remark on ticket"
                  onChange={(e) => {
                    if (e.target.checked && formData?.remark?.length > 30) {
                      toast.warning("Remark should have only 30 characters.");
                      // setFormData((prev) => ({
                      //   ...prev,
                      //   remark: "",
                      // }));
                      return;
                    }
                    setIncludeRemark(e.target.checked);
                  }}
                  sx={{
                    mt: 2,
                  }}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12} md={8} lg={8}>
              <Box
                sx={{
                  position: "relative",
                  mx: 3,
                }}
              >
                <TransformWrapper
                  initialScale={0.8}
                  minScale={0.2}
                  centerZoomedOut
                  initialPositionX={0}
                  initialPositionY={0}
                  centerOnInit
                >
                  <Controls />
                  <TransformComponent
                    wrapperStyle={{
                      width: "100%",
                      border: "2px solid #e3e3e3",
                      borderRadius: "5px",
                      touchAction: "none",
                    }}
                  >
                    <div
                      id="content"
                      // className={Styles.layoutScroll}
                    >
                      {renderGrid()}
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              </Box>
              {restaurant &&
              formData?.firstName &&
              formData?.lastName &&
              formData?.date &&
              time &&
              formData?.peopleCount ? (
                <div className={Styles.layoutContainer}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginX: isMobile ? "20px" : "65px",
                      mb: 15,
                    }}
                  >
                    <div
                      className={`${themeDetails ? "newTicketWrapper" : "ticketWrapper"}`}
                    >
                      <Ticket
                        restaurant={restaurant}
                        date={formData?.date}
                        time={time}
                        customerName={`${formData?.firstName} ${formData?.lastName}`}
                        peopleCount={formData?.peopleCount}
                        handlBookingTicket={(img) => setBookingTicket(img)}
                        remark={includeRemark ? formData?.remark : null}
                        themeDetails={themeDetails}
                      />
                    </div>
                  </Box>
                </div>
              ) : null}
            </Grid>
          </Grid>
          <div className={Styles.BtnWrapper}>
            <Button
              disabled={submitting}
              size="medium"
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "275px",
              }}
            >
              {submitting ? (
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  size={30}
                />
              ) : (
                "Submit"
              )}
            </Button>
            <div className={Styles.footer}></div>
          </div>
        </>
      )}
    </>
  );
};

export default Reservation;
