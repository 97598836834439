import {
  Box,
  Modal,
  Typography,
  Dialog,
  Divider,
  TextField,
  Button,
  IconButton,
  ToggleButton,
  CircularProgress,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Checkbox,
} from "@mui/material";
import styles from "./styles/bookingDetailsPopupStyle.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ENDPOINT,
  SPENDING_AMOUNTS,
  TOAST_CONFIG,
} from "../../config/constants";
import { toast } from "react-toastify";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  compareTime,
  convertDateFormat,
  convertToAMPM,
  dataURLtoFile,
  formatISODateString,
} from "../../utils";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useAuth } from "../../hooks/useAuth";
import Ticket from "../ticket/Ticket";
import { useSelector } from "react-redux";
import TimeSelector from "../time-selector/TimeSelector";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390,
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: "16px",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  // height: "76%",
};
const timeSelectorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: "16px",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
};

const slotProps = {
  textField: {
    placeholder: "Date",
    variant: "standard",
    sx: {
      width: "50%",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
      marginRight: "30px",
      marginBottom: "45px",
    },
    // inputProps: {
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   marginBottom: "5px",
    // },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const timeSlotProps = {
  textField: {
    placeholder: "Time",
    variant: "standard",
    sx: {
      marginBottom: "45px",
      width: "50%",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
    },
    // inputProps: {
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   marginBottom: "5px",
    // },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const BookingDetailPopup = ({
  open,
  handleClose,
  bookingDetails,
  restId,
  spendings,
  restaurant,
  handleChangeTablePopup,
  themeDetails,
  setBookedTbl 
}) => {
  const isMobile = useMediaQuery("(max-width:650px)");
  const { logout } = useAuth();
  const { restaurantsList, server } = useSelector((state) => state.masterData);
  const [formData, setFormData] = useState(null);
  const [includeRemark, setIncludeRemark] = useState(true);
  const [submitting, setSubmistting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [bookingTicket, setBookingTicket] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [openTimeSelector, setOpenTimeSelector] = useState(false);
  const [time, setTime] = useState({
    hh: bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[0],
    mm: bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[1],
    meridiem:
      bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[2],
  });


  

  useEffect(() => {
    let newData = { ...bookingDetails };
    newData.booking_remark =
      newData?.booking_remark !== "null" ? newData?.booking_remark : "";
    setFormData(newData);
    setTime({
      hh:
        bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[0],
      mm:
        bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[1],
      meridiem:
        bookingDetails && convertToAMPM(bookingDetails?.booking_time, true)[2],
    });
    setIncludeRemark(newData?.booking_remark?.length <= 30 ? true : false);
    return () => {
      setShowCancelConfirmation(false);
      setCancellationReason("");
    };
  }, [bookingDetails]);
  const handleSpendingAmount = (amount) => {
    if (amount === formData?.spending) {
      if (formData?.isCompleted) {
        toast.error("Cannot remove spending amount!", TOAST_CONFIG);
        return;
      }
      setFormData((prev) => ({ ...prev, spending: "" }));
      return;
    }
    setFormData((prev) => ({ ...prev, spending: amount }));
  };

  const handleTime = (e) => {
    setTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeCancellationReason = (e) => {
    setCancellationReason(e.target.value);
  };
  const handleTimeSelectDone = () => {
    setFormData((prev) => ({
      ...prev,
      booking_time: `${time.hh}:${time.mm}:00 ${time.meridiem}`,
    }));
    setOpenTimeSelector(false);
  };

  const handleChangeNoOfPeople = (e) => {
    if (isNaN(e.target.value) || e.target.value < 0) return;
    if ("people_count" in formData) {
      setFormData((prev) => ({ ...prev, people_count: e.target.value }));
      setBookedTbl((prev) => ({ ...prev, people_count: e.target.value }));
    } else if ("peopleCount" in formData) {
      setFormData((prev) => ({ ...prev, peopleCount: e.target.value }));
      setBookedTbl((prev) => ({ ...prev, peopleCount: e.target.value }));
    }
  };
  const handleChangeDate = (date) => {
    setFormData((prev) => ({ ...prev, booking_date: date }));
  };
  const handleChangeTime = (time) => {
    setFormData((prev) => ({
      ...prev,
      booking_time: new Date(time).toLocaleTimeString(),
    }));
  };
  const handleSubmit = async () => {
    try {
      setSubmistting(true);
      // const current
      // if (formData?.spending && compareTime(formData.booking_time)) {
      //   toast.error(
      //     "Spending cannot be added before the booked time",
      //     TOAST_CONFIG
      //   );
      //   return;
      // }
      const payload = new FormData();
      payload.append("reservationId", ('reservationId' in formData ? formData.reservationId :  formData.id));
      payload.append("booking_remark", formData.booking_remark);
      payload.append("spending", formData?.spending || "");
      payload.append("firstName", formData?.name);
      payload.append("lastName", formData?.lastName);
      payload.append("contactNo", formData?.contactNo);
      payload.append("userId", formData?.userId);
      payload.append("staffId", formData?.staff_id);
      payload.append("booking_time", formData.booking_time);
      payload.append("isArrived", formData.isArrived);
      payload.append("showRemark", includeRemark ? 1 : 0);
      payload.append(
        "booking_date",
        formatISODateString(new Date(formData.booking_date).toISOString())
      );
      payload.append(
        "number",
        formData.tableNumber
          ? formData.tableNumber
          : formData.number
          ? formData.number
          : ""
      );
      payload.append("isChanged", 0);
      payload.append("restId", restId);
      payload.append(
        "people_count",
        formData.people_count
          ? formData.people_count
          : formData.peopleCount
          ? formData.peopleCount
          : "01"
      );

      if (formData?.tableDetails) {
        const formDataCopy = { ...formData };
        delete formDataCopy?.tableDetails;
        const parsedTableDetails = JSON.parse(formData?.tableDetails);
        const newTableDetails = {
          ...formDataCopy,
          ...parsedTableDetails,
        };
        payload.append("tableDetails", JSON.stringify(newTableDetails));
      } else {
        payload.append("tableDetails", JSON.stringify(formData));
      }

      bookingTicket && bookingTicket != "" &&
        payload.append(
          "bookinCard",
          bookingTicket && bookingTicket != "" 
            ? dataURLtoFile(
                bookingTicket,
                `${formData.firstName}${formData?.lastName}-booking.png`
              )
            : ""
        );
      payload.append("sendingBy", server);

      const response = await POST_MULTIPART(
        ENDPOINT.UPDATE_RESERVATION_ADMIN,
        payload,
        false
      );
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        handleClose();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setSubmistting(false);
    }
  };

  const handleCancelBooking = async () => {
    setShowCancelConfirmation(true);
  };

  const handleCancel = async () => {
    if (!cancellationReason) {
      toast.warning("Please select cancellation reason.", TOAST_CONFIG);
      return;
    }
    setDeleting(true);
    try {
      const payload = new FormData();
      // payload.append("reservationId", formData.id);
      payload.append("reservationId", ('reservationId' in bookingDetails ? bookingDetails?.reservationId : formData.id));
      payload.append("cancelReason", cancellationReason);
      payload.append("contactNo", formData.contactNo);
      const resp = await POST_MULTIPART(
        ENDPOINT.DELETE_RESERVATION,
        payload,
        true
      );
      if (resp?.response?.data?.status === 200) {
        toast.success(resp?.response?.data?.message, TOAST_CONFIG);
        handleClose();
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setDeleting(false);
    }
  };

  const handleBookingTicket = useCallback(
    (data) => {      
      setBookingTicket(data);
    },
    [refresh]
  );

  const handleIsArrived = (e) => {
    if (e.target.checked) setFormData((prev) => ({ ...prev, isArrived: 1 }));
    else setFormData((prev) => ({ ...prev, isArrived: 0 }));
  };
  const handleTimeSelector = () => {
    if (formData?.isCompleted) {
      toast.error(
        "Cannot update time because booking is already completed",
        TOAST_CONFIG
      );
      return;
    }
    setOpenTimeSelector(true);
  };
  return (
    <Modal
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // position: "fixed",
        overflow: "auto",
        height: "auto",
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`liveTableModal`}
    >
      <Box
        sx={{
          ...style,
          height: isMobile ? "76%" : "80%",
          scrollbarWidth: "none",
          // mt: isMobile ? "auto" : 16,
        }}
        className={`liveTablePopup`}
      >
        <div className={styles.userInfoContainer}>
          <div className={styles.userNameWrapper}>
            <p className={styles.userName}>
              {formData?.name} {formData?.lastName}
            </p>
            <div className={styles.categoryWrapper}>
              {formData?.category?.map((cat, index) => (
                <p key={index} className={styles.categoryInitial}>
                  {cat.alphabets}
                </p>
              ))}
            </div>
          </div>
          <div className={styles.peopleCountWrapper}>
            <p className={styles.label}>No. of People</p>
            {/* <p className={styles.numberOfPeopleCount}>
              {formData?.people_count
                ? `${formData?.people_count}`.length === 1
                  ? "0" + formData?.people_count
                  : formData?.people_count
                : formData?.peopleCount
                ? `${formData?.peopleCount}`.length === 1
                  ? "0" + formData?.peopleCount
                  : formData?.peopleCount
                : "N.A"}
            </p> */}
            <input
              className={styles.numberOfPeopleCount}
              type="number"
              value={
                formData?.people_count
                  ? formData?.people_count
                  : formData?.peopleCount
                  ? formData?.peopleCount
                  : "0"
              }
              onChange={handleChangeNoOfPeople}
            />
           {formData?.reservationId == undefined && <FormControlLabel
              control={<Checkbox checked={formData?.isArrived} />}
              label="Arrived"
              onChange={handleIsArrived}
              sx={{
                mt: 2,
              }}
            />}
          </div>
        </div>
        <Divider sx={{ my: 3 }} />
        <div  className={`${styles.formControlWrapper} ${styles.editWrapper}`}>
          <div>
          <p className={styles.label}>Date & Time Slot</p>
          <div className={styles.dateTimeWrapper}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  // readOnly={formData?.isCompleted ? true : false}
                  disabled={true}
                  inputFormat="DD/MM/YYYY"
                  value={dayjs(formData?.booking_date)}
                  slotProps={slotProps}
                  onChange={(newValue) => {
                    if (formData?.isCompleted) {
                      toast.error(
                        "Cannot update time because booking is already completed",
                        TOAST_CONFIG
                      );
                      return;
                    }
                    handleChangeDate(newValue);
                  }}
                  slots={{
                    textField: (props) => (
                      <p {...props} className={styles.date}>
                        {formData?.booking_date
                          ? new Date(formData?.booking_date).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "short",
                                month: "short",
                                day: "numeric",
                              }
                            )
                          : ""}
                      </p>
                    ),
                  }}
                />
              </LocalizationProvider>
            </div>

            <Divider orientation="vertical" flexItem />

            <div
              className={styles.timeWrapper}
              // onClick={handleTimeSelector}
            >
              {formData?.booking_time &&
                convertToAMPM(formData?.booking_time, true)?.map((item) => (
                  <p className={styles.date}>{item}</p>
                ))}
            </div>
          </div>
          </div>
          <div>
            <p>&nbsp;</p>
            <Box
            sx={{
              textAlign: "right",
              boxSizing: "border-box",
            }}
          >
            <Button
              size="small"
              variant="text"
              onClick={() => {
                localStorage.setItem('includeRemark',includeRemark)
                handleChangeTablePopup(true);
                
              }}
            >
              Edit
            </Button>
          </Box>
          </div>
          
        </div>
        <div className={styles.formControlWrapper}>
          <p className={styles.label}>Remark</p>
          <TextField
            multiline={!includeRemark}
            helperText={
              !includeRemark
                ? `Characters limit : ${formData?.booking_remark?.length}/500`
                : null
            }
            rows={4}
            fullWidth
            placeholder="Add text"
            value={formData?.booking_remark}
            onChange={(e) => {
              if (
                (e.target.value?.length <= 30 && includeRemark) ||
                (e.target.value?.length <= 500 && !includeRemark)
              )
                setFormData((prev) => ({
                  ...prev,
                  booking_remark: e.target.value,
                }));
            }}
            inputProps={{
              style: {
                textAlign: "start",
                color: "#797979",
                fontFamily: "Poppins",
                fontSize: "17.5px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={includeRemark} />}
            label="Include remark on ticket"
            onChange={(e) => {
              if (e.target.checked && formData?.booking_remark?.length > 30) {
                toast.warning("Remark should have only 30 characters.");
                // setFormData((prev) => ({
                //   ...prev,
                //   booking_remark: "",
                // }));
                return;
              }
              setIncludeRemark(e.target.checked);
            }}
            sx={{
              mt: 1,
            }}
          />
        </div>

        <div className={styles.formControlWrapper}>
          <p className={styles.label}>Spending</p>
          <div className={styles.amountContainer}>
            {spendings?.map((item, index) => (
              <ToggleButton
                key={index}
                selected={item === formData?.spending}
                sx={{
                  py: "15px",
                  px: "20px",
                  borderRadius: 9.375,
                  height: 48,
                }}
                onClick={() => handleSpendingAmount(item)}
              >
                <p
                  className={`${styles.amount} ${
                    item === formData?.spending ? styles.active : ""
                  }`}
                >
                  ₹&nbsp;{item ? item : "N.A"}
                </p>
              </ToggleButton>
            ))}
          </div>
        </div>
        <div className="mb-50">
          <Button
            disabled={submitting || deleting}
            variant="contained"
            onClick={handleSubmit}
            sx={{ mr: 2 }}
            size="small"
          >
            {submitting ? (
              <CircularProgress color="primary" size={30} />
            ) : (
              "Submit"
            )}
          </Button>
          {!formData?.isCompleted ? (
            <Button
              disabled={submitting || deleting}
              variant="text"
              onClick={handleCancelBooking}
              size="small"
            >
              {deleting ? (
                <CircularProgress color="primary" size={30} />
              ) : (
                "Cancel Booking"
              )}
            </Button>
          ) : (
            <Button disabled variant="text" size="small">
              Booking completed
            </Button>
          )}
        </div>
        {
          // <Box
          
          // >
          //   <Ticket
          //     edit
          //     restaurant={restaurant}
          //     date={formData?.booking_date}
          //     time={time}
          //     customerName={`${formData?.name} ${formData?.lastName}`}
          //     peopleCount={
          //       formData?.peopleCount
          //         ? formData?.peopleCount
          //         : formData?.people_count
          //     }
          //     handlBookingTicket={(img) => {
          //       setRefresh((prev) => prev++);
          //       handleBookingTicket(img);
          //     }}
          //     remark={includeRemark ? formData?.booking_remark : null}
          //     themeDetails={themeDetails}
          //   />
          // </Box>
        }

        <Modal
          open={openTimeSelector}
          onClose={() => setOpenTimeSelector(false)}
        >
          <Box
            sx={{ ...timeSelectorStyle, width: !isMobile ? "390px" : "80%" }}
          >
            <TimeSelector values={time} onChange={handleTime} />
            <Button variant="contained" onClick={handleTimeSelectDone}>
              Done
            </Button>
          </Box>
        </Modal>
        <Dialog
          open={showCancelConfirmation}
          onClose={() => setShowCancelConfirmation(false)}
        >
          <DialogTitle id="alert-dialog-title">
            Please select cancellation reason.
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={cancellationReason}
              onChange={handleChangeCancellationReason}
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FormControlLabel
                value="Cancelled by customer"
                control={<Radio />}
                label="Cancelled by customer"
              />
              <FormControlLabel
                value="No show"
                control={<Radio />}
                label="No show"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions
            sx={{
              padding: "16px"
             
            }}
          >
            <Button
              size="small"
              variant="text"
              onClick={() => setShowCancelConfirmation(false)}
            >
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleCancel}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default BookingDetailPopup;
