import React from "react";
import { useAuth } from "../hooks/useAuth";
import AccessDenied from "../pages/access-denied/AccessDenied";

const RestrictedRoute = ({ roles, children }) => {
  const { role } = useAuth();
  if (!roles?.includes(role)) {
    return <AccessDenied />;
  }
  return children;
};

export default RestrictedRoute;
