import React from "react";
import Style from "./style.module.scss";

const AboutUs = () => {
  return (
    <div className={Style.aboutUsWrap}>
      <h1>About Us</h1>
      <p>
        We make things - EFFICIENT. EFFORTLESS. EPIC <br />
        We are here to ‘better’ what is already being done. <br />
        What started as a mere thought of saving data with a simple click has
        now turned into an opportunity to harness the power of data! <br />
        We ease Data Collection and Immediate Connection.
        <br />
        Epicness at your fingertips - click, collect & connect!!
      </p>
    </div>
  );
};

export default AboutUs;
