import React, { useEffect, useRef, useState } from "react";
import {
  FormLabel,
  Button,
  TextField,
  Switch,
  CircularProgress,
  Box,
} from "@mui/material";
import Styles from "./styles/createTemplate.module.css";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  ENDPOINT,
  TOAST_CONFIG,
  maxImageSize,
  maxVideoSize,
} from "../../config/constants";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useNavigation, useParams } from "react-router";
import { IMAGE_BASE_URL } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { Decrypt } from "../../Crypto-Helper";
import { setSelectedContacts } from "../../redux/slices/masterDataSlice";

// Regular expression to match Emoji characters
const emojiRegex =
  /[\u{1F600}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;

function CreateTemplate({ edit, quickMessage }) {
  let { templateId } = useParams();
  const dispatch = useDispatch();
  const id = templateId ? Decrypt(templateId) : null;
  const { selectedContacts, server } = useSelector((state) => state.masterData);
  const fileInputRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    externalTemplateName: "",
    message: "",
    image: null,
    headerTitle: "",
  });
  const [showHeader, setShowHeader] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setIsSubmitting] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [attachmentPreview, setAttachmentPreview] = useState(null);
  const [fileType, setFileType] = useState("text");

  useEffect(() => {
    if (edit && id) {
      getTemplate();
    }

    return () => {
      setFileType("");
    };
  }, []);

  const getTemplate = async () => {
    try {
      const resp = await GET(`${ENDPOINT.GET_TEMPLATE_BY_ID}/${id}`);
      if (resp?.response?.data?.status === 200) {
        setFormData(resp?.response?.data?.data);
        setFileType(resp?.response?.data?.data?.templateType);
        resp?.response?.data?.data?.image &&
          setAttachment(resp?.response?.data?.data?.image);
        setShowHeader(resp?.response?.data?.data?.isHeaderTitle ? true : false);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const fileObj = event?.target?.files && event?.target?.files[0];
    if (!fileObj) {
      setFileType("text");
      event.target.value = null;
      return;
    }
    let fileSize = fileObj?.size; // in bytes

    if (fileObj?.type?.includes("image") && fileSize > maxImageSize) {
      toast.error(
        "Image size exceeds 5MB. Please choose a smaller image file.",
        TOAST_CONFIG
      );
      event.target.value = null;
      return; // prevent form submission
    } else if (fileObj?.type?.includes("video") && fileSize > maxVideoSize) {
      toast.error(
        "Video size exceeds 10MB. Please choose a smaller video file.",
        TOAST_CONFIG
      );
      event.target.value = null;
      return; // prevent form submission
    }
    setAttachment(fileObj);
    setFileType(fileObj?.type?.split("/")[0]);
    setAttachmentPreview(URL.createObjectURL(fileObj));

    // 👇️ reset file input
    event.target.value = null;
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    // Check for emojis in the input value
    if (emojiRegex.test(newValue)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Emojis are not allowed.",
      }));
      toast.error("Emojis are not allowed.");
      return;
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({ ...formData, [name]: newValue });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.title.trim()) {
      errors.title = "Title is required";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const payload = new FormData();
      const formDataCopy = { ...formData };
      // delete formDataCopy.externalTemplateName;
      if (attachment) {
        formDataCopy.image = attachment;
        formDataCopy.templateType = fileType;
      } else {
        formDataCopy.image = "";
        formDataCopy.templateType = fileType;
      }
      const keys = Object.keys(formDataCopy);
      keys.forEach((key) => {
        payload.append(key, formDataCopy[key]);
      });

      try {
        setIsSubmitting(true);
        const response = await POST_MULTIPART(
          edit ? ENDPOINT.UPDATE_TEMPLATE : ENDPOINT.CREATE_TEMPLATE,
          payload
        );
        if (response?.response?.data?.status === 200) {
          toast.success(response?.response?.data?.message, TOAST_CONFIG);
          navigate("/templates");
        } else if (
          response?.response?.data?.status === 401 &&
          response?.response?.data?.message === "User Token Expired"
        ) {
          logout();
        } else {
          toast.error(response?.response?.data?.message, TOAST_CONFIG);
        }
      } catch (error) {
        toast.error(error?.message, TOAST_CONFIG);
      } finally {
        setIsSubmitting(false);
        dispatch(setSelectedContacts([]));
      }
    }
  };

  const resetAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
    setFileType("text");
    setFormData((prev) => ({ ...prev, image: null }));
  };

  const handleSendTemplate = async () => {
    setIsSubmitting(true);
    const payload = new FormData();
    selectedContacts.forEach((id) => payload.append("contactNo[]", id));
    payload.append("templateId", "0");
    payload.append("message", formData?.message);
    payload.append("pageHeaderTitle", formData?.headerTitle);
    payload.append("sendingBy", server);

    formData?.isHeaderTitle &&
      payload.append("isHeaderTitle", formData?.isHeaderTitle);
    if (attachment) {
      payload.append("url", attachment);
      payload.append("messageType", fileType);
    } else {
      payload.append("messageType", fileType);
    }

    try {
      const response = await POST_MULTIPART(ENDPOINT.SEND_MESSAGE, payload);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        navigate("/dashboard");
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
      dispatch(setSelectedContacts([]));
    }
  };

  return (
    <>
      <div className={Styles.templateWrapper}>
        {/* {!quickMessage && (
          <FormLabel sx={{ fontSize: "24px" }}>
            {edit ? "Update Template" : "Create New Template"}
          </FormLabel>
        )} */}
        {/* {quickMessage && (
          <FormLabel sx={{ fontSize: "24px" }}>Quick Message</FormLabel>
        )} */}
        <div className={Styles.fieldsWrapper}>
          {!quickMessage && (
            <>
              <FormLabel>External template name</FormLabel>
              <div className={Styles.textField}>
                <TextField
                  variant="outlined"
                  id="externalTemplateName"
                  name="externalTemplateName"
                  placeholder="External template name"
                  autoComplete="off"
                  type="text"
                  fullWidth
                  value={formData?.externalTemplateName}
                  onChange={handleInputChange}
                  error={!!formErrors.externalTemplateName}
                  helperText={formErrors.externalTemplateName}
                />
              </div>
              <FormLabel>Title</FormLabel>
              <div className={Styles.textField}>
                <TextField
                  variant="outlined"
                  id="title"
                  name="title"
                  placeholder="Title Name"
                  autoComplete="off"
                  type="text"
                  fullWidth
                  value={formData.title}
                  onChange={handleInputChange}
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                />
              </div>
            </>
          )}
          <FormLabel>Message</FormLabel>
          <div className={Styles.textField}>
            <TextField
              variant="outlined"
              id="message"
              name="message"
              placeholder="Write your Message Here"
              autoComplete="off"
              type="text"
              fullWidth
              multiline
              rows={3}
              maxRows={4}
              value={formData.message}
              onChange={handleInputChange}
              error={!!formErrors.message}
              helperText={formErrors.message}
              InputProps={{
                classes: {
                  input: Styles.messageInput,
                  root: Styles.messageInputRoot,
                },
              }}
            />
          </div>
          {!quickMessage && (
            <>
              <FormLabel>Attachment</FormLabel>
              <div
                onClick={attachment ? () => {} : handleFileClick}
                className={Styles.attachmentWrapper}
              >
                <input
                  type="file"
                  // accept="image/png, image/gif, image/jpeg"
                  accept="image/jpg,image/jpeg,image/png,image/gif,video/hevc,video/*" //video/mpeg,video/hevc,video/mp4,video/x-m4v,video/avi,video/flv,video/mov
                  ref={fileInputRef}
                  className={Styles.attachmentInput}
                  onChange={handleFileChange}
                />
                {fileType === "image" &&
                (attachmentPreview || formData?.image) ? (
                  <img
                    src={
                      attachmentPreview || `${IMAGE_BASE_URL}${formData?.image}`
                    }
                    alt="Temporary Placeholder"
                    className={Styles.attachmentTempImage}
                  />
                ) : fileType === "video" &&
                  (attachmentPreview || formData?.image) ? (
                  <video controls className={Styles.attachmentTempImage}>
                    <source
                      src={
                        attachmentPreview ||
                        `${IMAGE_BASE_URL}${formData?.image}`
                      }
                      type="video/*"
                    />
                    <source
                      src={
                        attachmentPreview ||
                        `${IMAGE_BASE_URL}${formData?.image}`
                      }
                      type="video/mpeg"
                    />
                    <source
                      src={
                        attachmentPreview ||
                        `${IMAGE_BASE_URL}${formData?.image}`
                      }
                      type="video/mp4"
                    />
                    <source
                      src={
                        attachmentPreview ||
                        `${IMAGE_BASE_URL}${formData?.image}`
                      }
                      type="video/hevc"
                    />
                  </video>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FilePresentIcon
                      sx={{
                        fontSize: 40,
                      }}
                    />
                    Select File
                  </Box>
                )}
              </div>
              {(attachment || attachmentPreview || formData?.image) && (
                <div className={Styles.removeButtonContainer}>
                  <Button
                    size="small"
                    variant="text"
                    color="error"
                    sx={{ padding: 1 }}
                    onClick={resetAttachment}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </>
          )}
          <div className={Styles.headerWrapper}>
            <FormLabel>Header</FormLabel>
            <Switch
              sx={{ marginLeft: "15px" }}
              name="header"
              checked={showHeader}
              onChange={(e) => {
                setShowHeader(e.target.checked);
                setFormData((prev) => ({
                  ...prev,
                  isHeaderTitle: e.target.checked ? 1 : 0,
                }));
              }}
            />
          </div>
          {showHeader && (
            <div className={Styles.textField}>
              <TextField
                variant="outlined"
                id="header"
                name="headerTitle"
                placeholder="Header"
                autoComplete="off"
                type="text"
                fullWidth
                value={formData.headerTitle}
                onChange={handleInputChange}
                error={!!formErrors.headerTitle}
                helperText={formErrors.headerTitle}
              />
            </div>
          )}
        </div>
      </div>
      <div className="footerWrapper">
        <div className={Styles.btnWapper}>
          {!quickMessage && (
            <Button
              disabled={submitting}
              size="medium"
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              {submitting ? (
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  size={30}
                />
              ) : edit ? (
                "Update Template"
              ) : (
                "Create Template"
              )}
            </Button>
          )}
          {quickMessage && (
            <Button
              disabled={submitting}
              size="medium"
              variant="contained"
              fullWidth
              onClick={handleSendTemplate}
            >
              {submitting ? (
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  size={30}
                />
              ) : (
                "Send Message"
              )}
            </Button>
          )}
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
}

export default CreateTemplate;
