import React, { useEffect, useState } from "react";
import {
  FormLabel,
  Button,
  TextField,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import TemplateCard from "./TemplateCard";
import TemplateCardImage from "../../assets/images/templateCardImage.png";
import Styles from "./styles/template.module.css";
import { Search } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { Box } from "@mui/system";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmailPayload,
  setSelectedContacts,
  setTemplates,
} from "../../redux/slices/masterDataSlice";
import { useNavigate } from "react-router";
import TemplateLabel from "./TemplateLabel";
import "./styles/template.module.css";
import NoDataFound from "../../components/no-data-found/NoDataFound";
import { IMAGE_BASE_URL } from "../../config/config";
import { formatISODateString } from "../../utils";

function Template({ sendTemplate }) {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.masterData.templates);
  const { selectedContacts, server, previousScreen, emailPayload, mainFilter } =
    useSelector((state) => state.masterData);

  const {
    reservation,
    reservationExport,
    filterType,
    pageSize,
    filterCount,
    filterMode,
  } = useSelector((state) =>
    state.masterData.emailPayload ? state.masterData.emailPayload : {}
  );
  const [loading, setLoading] = useState(false);
  const [templateToBeSent, setTemplateToBeSent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deletionTemplate, setDeletionTemplate] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [searchData, setSearchData] = useState(data?.data);
  const [searchKey, setSearchKey] = useState("");
  useEffect(() => {
    getTemplates();
  }, [refresh]);

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
    if (e.target.value?.length >= 2) {
      const filteredArray = data?.data?.filter((item) => {
        if (item.title.toLowerCase().includes(e.target.value.toLowerCase()))
          return item;
      });
      setSearchData(filteredArray);
    } else {
      setSearchData(data?.data);
    }
  };

  const getTemplates = async () => {
    try {
      setLoading(true);
      const response = await GET(ENDPOINT.GET_TEMPLATES);
      if (response?.response?.data?.status == 200) {
        dispatch(setTemplates(response?.response?.data));
        setSearchData(response?.response?.data?.data);
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };
  const handleSendTemplate = async () => {
    setIsSubmitting(true);
    const payload = new FormData();
    selectedContacts.forEach((id) => payload.append("contactNo[]", id));
    payload.append("templateId", templateToBeSent?.id);
    payload.append("message", templateToBeSent?.message);
    payload.append("pageHeaderTitle", templateToBeSent?.title);
    payload.append("messageType", templateToBeSent?.templateType);
    if (templateToBeSent?.templateType === "image") {
      payload.append("url", `${IMAGE_BASE_URL}${templateToBeSent?.image}`);
    }
    payload.append("sendingBy", server);
    try {
      const response = await POST_MULTIPART(ENDPOINT.SEND_MESSAGE, payload);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        dispatch(setSelectedContacts([]));
        emailPayload && triggerEmail(templateToBeSent?.id);
        navigate(previousScreen);
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };

  const triggerEmail = async (templateId) => {
    try {
      const payload = new FormData();
      payload.append("batch", pageSize);
      payload.append("templateId", templateId);
      // payload.append("gender", mainFilter.gender === "Male" ? 1 : 0);
      payload.append("isDnd", mainFilter.dnd ? 1 : 0);
      if (!reservation) {
        payload.append("rating", mainFilter.rating);
      }
      if (reservation || reservationExport) {
        payload.append(
          "fromDate",
          mainFilter.fromDate
            ? formatISODateString(new Date(mainFilter.fromDate).toISOString())
            : reservationExport
            ? formatISODateString(new Date().toISOString())
            : ""
        );
        payload.append(
          "toDate",
          mainFilter.toDate
            ? formatISODateString(new Date(mainFilter.toDate).toISOString())
            : reservationExport
            ? formatISODateString(new Date().toISOString())
            : ""
        );
      } else {
        if (mainFilter.startTime) {
          payload.append(
            "fromDate",
            mainFilter.fromDate
              ? `${formatISODateString(
                  new Date(mainFilter.fromDate).toISOString()
                )} ${new Date(mainFilter.startTime).toLocaleTimeString()}`
              : ""
          );
        } else
          payload.append(
            "fromDate",
            mainFilter.fromDate
              ? formatISODateString(new Date(mainFilter.fromDate).toISOString())
              : ""
          );

        if (mainFilter.endTime) {
          payload.append(
            "toDate",
            mainFilter.toDate
              ? `${formatISODateString(
                  new Date(mainFilter.toDate).toISOString()
                )} ${new Date(mainFilter.endTime).toLocaleTimeString()}`
              : ""
          );
        } else
          payload.append(
            "toDate",
            mainFilter.toDate
              ? formatISODateString(new Date(mainFilter.toDate).toISOString())
              : ""
          );
      }
      mainFilter?.age.forEach((item) => payload.append("age[]", item));

      // mainFilter?.gender !== null &&
      //   payload.append("gender", mainFilter?.gender);

      mainFilter?.gender.forEach((item) => payload.append("gender[]", item));

      mainFilter?.category.forEach((item) =>
        payload.append(
          reservation || reservationExport ? "restId[]" : "category[]",
          item
        )
      );
      payload.append("searchType", filterType);
      payload.append("filterType", filterMode);

      mainFilter?.spending.forEach((item) =>
        payload.append("spending[]", item ? `${item}K` : "")
      );
      // payload.append(
      //   "spending",
      //   mainFilter?.spending ? `${mainFilter.spending}K` : ""
      // );

      if (reservation || reservationExport) {
        if (
          mainFilter?.fromTime?.hh &&
          mainFilter?.fromTime?.mm &&
          mainFilter?.fromTime?.meridiem
        )
          payload.append(
            "fromBookingTime",
            `${mainFilter.fromTime.hh}:${mainFilter.fromTime.mm} ${mainFilter.fromTime.meridiem}`
          );
        else payload.append("fromBookingTime", "");

        if (
          mainFilter?.toTime?.hh &&
          mainFilter?.toTime?.mm &&
          mainFilter?.toTime?.meridiem
        ) {
          payload.append(
            "toBookingTime",
            `${mainFilter.toTime.hh}:${mainFilter.toTime.mm} ${mainFilter.toTime.meridiem}`
          );
        } else payload.append("toBookingTime", "");
      }
      payload.append("noOfUserSent", selectedContacts?.length);
      payload.append("filterCount", filterCount);
      const resp = await POST_MULTIPART(ENDPOINT.TRIGGER_EMAIL, payload);
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      dispatch(setEmailPayload(null));
    }
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOpen = (template) => {
    setOpenConfirmationDialog(true);
    setDeletionTemplate(template);
  };
  const confirmDelete = async () => {
    setOpenConfirmationDialog(false);
    const payload = new FormData();
    const keys = Object.keys(deletionTemplate);
    keys.forEach((key) => {
      if (key === "isDeleted") payload.append("isDeleted", 1);
      else payload.append(key, deletionTemplate[key]);
    });
    try {
      setIsSubmitting(true);
      const response = await GET(
        `${ENDPOINT.REMOVE_TEMPLATE}/${deletionTemplate?.id}`
      );
      if (response?.response?.data?.status === 200) {
        setDeletionTemplate(null);
        toast.success("Template deleted successfully!", TOAST_CONFIG);
        setRefresh((prev) => prev + 1);
        // navigate("/templates");
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "80vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  return (
    <>
      <div className={Styles.TemplateWrapper}>
        <FormLabel sx={{ fontSize: "24px" }}>Templates</FormLabel>
        {sendTemplate && (
          <div className={Styles.buttonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => navigate("/quick-message")}
            >
              Quick Message
            </Button>
          </div>
        )}
        <div>
          <TextField
            id="search"
            name="search"
            fullWidth
            autoComplete="off"
            type="text"
            placeholder="Search Template"
            value={searchKey}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                textAlign: "end",
              },
            }}
            sx={{
              marginTop: "20px",
            }}
          />
        </div>
        <TemplateLabel
          data={searchData}
          showActions={!sendTemplate}
          sendTemplate={sendTemplate}
          setTemplateToBeSent={setTemplateToBeSent}
          templateToBeSent={templateToBeSent}
          onDelete={handleOpen}
        />
        {searchData?.length === 0 ? <NoDataFound /> : null}
      </div>
      <div className={Styles.stickeyFooter}>
        <div className={Styles.btnWapper}>
          {sendTemplate ? (
            <Button
              disabled={isSubmitting}
              size="medium"
              variant="contained"
              sx={{
                width: "255px",
              }}
              onClick={handleSendTemplate}
            >
              {isSubmitting ? (
                <CircularProgress color="primary" size={30} />
              ) : (
                "Send"
              )}
            </Button>
          ) : (
            <Button
              size="medium"
              variant="contained"
              sx={{
                width: "255px",
              }}
              onClick={() => navigate("/create-template")}
            >
              Create New
            </Button>
          )}
        </div>
        <div className={Styles.footer}></div>
      </div>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Confirm</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this template ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={confirmDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Template;
