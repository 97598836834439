import React from "react";
import Style from "./styles/templateCard.module.css";
import Edit from "../../assets/images/icon_edit.svg";
import Cancel from "../../assets/images/icon_cancel.svg";
import { IMAGE_BASE_URL } from "../../config/config";
import { Avatar, Grid, IconButton } from "@mui/material";
import { useNavigate } from "react-router";

function TemplateCard({ data, showActions }) {
  const navigate = useNavigate();

  const handleEditTemplate = async (id) => {
    navigate(`/edit-template/${id}`);
  };

  const handleRemoveTemplate = async () => {};
  return (
    <Grid container spacing={5} sx={{ mt: 2 }}>
      {data?.map((template) => (
        <Grid key={template.id} item xs={12} sm={12} md={6} lg={3}>
          <div className={Style.templateCard}>
            <div className={Style.templateImage}>
              <Avatar
                variant="square"
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "20px",
                  backgroundColor: "#F3F3F3",
                }}
                src={`${IMAGE_BASE_URL}${template.image}`}
              />
            </div>
            {/* <img
            src={`${IMAGE_BASE_URL}${template.image}`}
            alt=""
            className={Style.templateImage}
          /> */}
            <div className={Style.templateInfo}>
              <p className={Style.templateTitle}>{template?.title}</p>
              <div className={Style.verticalLine}></div>
              {showActions && (
                <div className={Style.templateActions}>
                  <IconButton
                    aria-label="edit"
                    sx={{ marginX: "10px" }}
                    onClick={() => handleEditTemplate(template?.id)}
                  >
                    <img src={Edit} alt="edit" />
                  </IconButton>
                  <IconButton
                    aria-label="cancel"
                    onClick={() => handleRemoveTemplate(template?.id)}
                  >
                    <img src={Cancel} alt="cancel" />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default TemplateCard;
