import React, { useEffect, useState } from "react";
import Style from "./styles/SpecialOccasions.module.css";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import CustomerList from "../dashboard/CustomerList";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { GET, POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  setAnniversaries,
  setBirthdays,
  setPreviousScreen,
  setSelectedContacts,
} from "../../redux/slices/masterDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import NoDataFound from "../../assets/images/no-data.svg";
import { PAGE_SIZE } from "../../config/config";
import dayjs from "dayjs";
import { convertDateFormat, formatISODateString } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";

const SlotProps = {
  textField: {
    variant: "standard",
    sx: {
      width: "140px",
      padding: "8px 16px",
      backgroundColor: "#F3F3F3",
      cursor: "pointer",
      borderRadius: "8px",
      "& .MuiInput-root": {
        borderBottom: "none",
      },
      "& .MuiInput-root:before": {
        borderBottom: "none",
      },
      "& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
        borderBottom: "none",
      },
      "& .MuiInput-root:after": {
        borderBottom: "none",
      },
    },
    inputProps: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
      cursor: "pointer",
    },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

function SpecialOccasions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { birthdays, anniversaries, selectedContacts } = useSelector(
    (state) => state.masterData
  );
  const { logout, role } = useAuth();
  const [activeTab, setActiveTab] = useState("birthday");
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [loading, setLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(setSelectedContacts([]));
  }, []);

  useEffect(() => {
    getBirthdays();
    getAnniversaries();
  }, [startDate, endDate]);

  const handleTabChange = (name) => {
    setActiveTab(name);
    setCurrentPage(1);
    dispatch(setSelectedContacts([]));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const getBirthdays = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append(
        "fromDate",
        formatISODateString(new Date(startDate).toISOString())
      );
      payload.append(
        "toDate",
        formatISODateString(new Date(endDate).toISOString())
      );
      const resp = await POST_MULTIPART(ENDPOINT.GET_BIRTHDAYS, payload);
      if (resp?.response?.data?.status === 200) {
        dispatch(setBirthdays(resp?.response?.data?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const getAnniversaries = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append(
        "fromDate",
        formatISODateString(new Date(startDate).toISOString())
      );
      payload.append(
        "toDate",
        formatISODateString(new Date(endDate).toISOString())
      );
      const resp = await POST(ENDPOINT.GET_ANNIVERSARIES, payload);
      if (resp?.response?.data?.status === 200) {
        dispatch(setAnniversaries(resp?.response?.data?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAll = (data) => {
    const allIds = data.map((dt) => dt.contactNo);
    dispatch(setSelectedContacts(allIds));
  };

  const deselectAll = () => {
    dispatch(setSelectedContacts([]));
  };

  return (
    <>
      <div className={Style.container}>
        <Box sx={{ marginBottom: "28px" }}>
          <div style={{ display: "flex" }}>
            <div className={Style.startDate}>
              <p className={Style.dateLabel}>Start date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  slotProps={SlotProps}
                  value={startDate}
                  closeOnSelect
                  onChange={(date) => handleStartDateChange(date)}
                />
              </LocalizationProvider>
            </div>
            <div>
              <p className={Style.dateLabel}>End date</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  views={["year", "month", "day"]}
                  inputFormat="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  slotProps={SlotProps}
                  value={endDate}
                  closeOnSelect
                  onChange={(date) => handleEndDateChange(date)}
                />
              </LocalizationProvider>
            </div>
          </div>
        </Box>
        <Grid item xs={4} sx={{ display: "flex", marginBottom: "30px" }}>
          <Button
            variant="contained"
            color={activeTab === "birthday" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "12.5px 0 0 12.5px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => handleTabChange("birthday")}
          >
            <span>Birthdays</span>
          </Button>
          <Button
            variant="contained"
            color={activeTab === "anniversary" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "0 12.5px 12.5px 0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => handleTabChange("anniversary")}
          >
            <span>Anniversaries</span>
          </Button>
        </Grid>

        {activeTab === "birthday" && (
          <Grid container>
            {birthdays?.data?.length ? (
              <>
                {role === USER_TYPE.MANAGER && (
                  <Grid item xs={12} lg={12}>
                    <FormLabel
                      onClick={
                        birthdays?.data?.length === selectedContacts?.length
                          ? deselectAll
                          : () => handleSelectAll(birthdays?.data)
                      }
                      sx={{
                        textDecoration: "",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      {birthdays?.data?.length === selectedContacts?.length
                        ? "Deselect All"
                        : "Select All"}
                    </FormLabel>
                  </Grid>
                )}
                {birthdays?.data?.map((customer) => (
                  <Grid key={customer.id} item xs={12} sm={12} md={6} lg={6}>
                    <CustomerList
                      data={customer}
                      birthday
                      dashboardActions
                      showCheckBox
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="noResultWrapper">
                  <img src={NoDataFound} alt="no-data-found" />
                  <Typography variant="h5">No data found!</Typography>
                </div>
              </Grid>
            )}
          </Grid>
        )}

        {activeTab === "anniversary" && (
          <Grid container>
            {anniversaries?.data?.length ? (
              <>
                {role === USER_TYPE.MANAGER && (
                  <Grid item xs={12} lg={12}>
                    <FormLabel
                      onClick={
                        anniversaries?.data?.length === selectedContacts?.length
                          ? deselectAll
                          : () => handleSelectAll(anniversaries?.data)
                      }
                      sx={{
                        textDecoration: "",
                        "&:hover": { cursor: "pointer" },
                      }}
                    >
                      {anniversaries?.data?.length === selectedContacts?.length
                        ? "Deselect All"
                        : "Select All"}
                    </FormLabel>
                  </Grid>
                )}
                {anniversaries?.data?.map((customer) => (
                  <Grid key={customer.id} item xs={12} sm={12} md={6} lg={6}>
                    <CustomerList
                      data={customer}
                      anniversary
                      dashboardActions
                      showCheckBox
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="noResultWrapper">
                  <img src={NoDataFound} alt="no-data-found" />
                  <Typography variant="h5">No data found!</Typography>
                </div>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 14,
              }}
            >
              {totalPageCount ? (
                <Pagination
                  count={totalPageCount}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </div>
      {selectedContacts?.length > 0 && (
        <div className={Style.footerWrapper}>
          <div className={Style.submitBtnWrapper}>
            <Button
              size="medium"
              variant="contained"
              onClick={() => {
                dispatch(setPreviousScreen(location?.pathname));
                navigate("/send-template");
              }}
            >
              Next
            </Button>
          </div>
          <div className={Style.footer}></div>
        </div>
      )}
    </>
  );
}

export default SpecialOccasions;
