import { TextField, useMediaQuery } from "@mui/material";

export const StandardInputField = (props) => {
  const isMobile = useMediaQuery("(min-width:320px) and (max-width:1024px)");
  const customStyles = {
    marginBottom: "45px",
    width: !isMobile ? "359px" : "100%",
    borderBottom: "2px",
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #D9D9D9",
    },
    ...props.sx,
  };

  const customInputProps = {
    sx: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
      ...props?.inputProps?.sx,
    },
  };
  return (
    <TextField
      {...props}
      multiline={props?.isMultiline}
      variant="standard"
      sx={customStyles}
      inputProps={customInputProps}
      onClick={props.onClick}
      rows={props?.isMultiline ? 4 : 1}
    />
  );
};
