import React from "react";
import Styles from "./styles/MessageCardStyle.module.css";
import { Avatar } from "@mui/material";
import { formatDatetime } from "../../utils";

const CheckInCard = ({ data }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.reservationInfoWrapper}>
        <p className={Styles.userName}>
          {data?.days === 0 ? "Same Day" : data?.days + " Days" || "N.A"}
        </p>
        <p className={Styles.dateTime}>
          From: {data?.check_in ? formatDatetime(data?.check_in, true) : "N.A"}
        </p>
        <p className={Styles.dateTime}>
          To: {data?.check_out ? formatDatetime(data?.check_out, true) : "N.A"}
        </p>
      </div>
    </div>
  );
};

export default CheckInCard;
