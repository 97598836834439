import { ToggleButton } from "@mui/material";
import React from "react";

const OptionPill = ({ onClick, text, active, sx, isFilter, ...props }) => {
  const customStyle = {
    mr: "15px",
    mb: "15px",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  };
  return (
    <ToggleButton
      {...props}
      selected={active}
      sx={{ ...customStyle, ...sx }}
      onClick={onClick}
      value={text}
    >
      <p style={{ width: "max-content" }}>{text}</p>
      {/* {isFilter ? <p style={{ width: "max-content" }}>{text}</p> : text} */}
    </ToggleButton>
  );
};

export default OptionPill;
