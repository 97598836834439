import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./customStyles.css";
import { DateRangePicker } from "react-date-range";
import { InputLabel, Modal, useMediaQuery, Button } from "@mui/material";
import { Box } from "@mui/system";
import { convertDateFormat, formatDatetime } from "../../utils";

const CustomeDateRangePicker = ({ value, handleDone, label }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: value.checkIn ? new Date(value.checkIn) : new Date(),
      endDate: value.checkOut ? new Date(value.checkOut) : new Date(),
      key: "selection",
    },
  ]);

  return (
    <>
      <Box
        sx={{
          borderBottom: "2px solid #D9D9D9",
          marginBottom: "45px",
          width: "50%",
        }}
        onClick={() => setOpen(true)}
      >
        <InputLabel
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            color: !value?.checkIn && "#A2A2A2",
          }}
        >
          {/* {state[0].startDate
            ? formatDatetime(state[0].startDate, true)
            : "Check-In"} */}
          {label}
        </InputLabel>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 390,
            bgcolor: "background.paper",
            boxShadow: 24,
            px: 3,
            py: 4,
            borderRadius: "16px",
            outline: "none",
          }}
          className="datepickerWrap"
        >
          <DateRangePicker
            rangeColors={["#001A27"]}
            color="#001A27"
            className="datePicker"
            editableDateInputs={false}
            onChange={(item) => {
              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
            showMonthAndYearPickers={true}
            staticRanges={[]}
            inputRanges={[]}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => {
                handleDone({
                  startDate: null,
                  endDate: null,
                  key: "selection",
                });
                setState([
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                  },
                ]);
                setOpen(false);
              }}
            >
              Clear
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleDone(state[0]);
                setOpen(false);
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomeDateRangePicker;
