import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const SpendingSelectorMobile = ({ spending, handleChange }) => {
  const [min, setMin] = useState(spending[0]);
  const [max, setMax] = useState(spending[1]);

  useEffect(() => {
    setMin(spending[0]);
    setMax(spending[1]);
  }, [spending]);

  const handleChangeValue = (event) => {
    let values = [min, max];
    if (event.target.name === "min") {
      setMin(event.target.value);
      values[0] = event.target.value;
    } else if (event.target.name === "max") {
      setMax(event.target.value);
      values[1] = event.target.value;
    }

    // if (values[0] && !values[1]) {
    //   values[1] = values[0];
    // } else if (!values[0] && values[1]) {
    //   values[0] = values[1];
    // }

    handleChange(event, values);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-select-small-label">Min</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={min}
          label="Min"
          name="min"
          onChange={handleChangeValue}
        >
          {[...Array(100)].map((value, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}K
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <p>To</p>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-select-small-label">Max</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={max}
          label="Max"
          name="max"
          onChange={handleChangeValue}
        >
          {[...Array(100)].map((value, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}K
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SpendingSelectorMobile;
