import React from "react";
import Style from "./styles/templateLabel.module.css";
import Edit from "../../assets/images/image_edit.svg";
import Cancel from "../../assets/images/icon_cancel.svg";
import { useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/config";
import { Avatar, Grid, IconButton } from "@mui/material";
import { Encrypt } from "../../Crypto-Helper";
import { setTemplateId } from "../../redux/slices/masterDataSlice";
import { useDispatch } from "react-redux";

function TemplateLabel({
  data,
  showActions,
  sendTemplate,
  setTemplateToBeSent,
  templateToBeSent,
  onDelete,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleEditTemplate = async (id) => {
    dispatch(setTemplateId(id));
    navigate(`/edit-template/${Encrypt(id)}`);
  };

  const handleRemoveTemplate = (temp) => {
    onDelete(temp);
  };

  const handleTemplateClick = (template) => {
    if (sendTemplate) {
      setTemplateToBeSent && setTemplateToBeSent(template);
    } else {
      return;
    }
  };

  const getIsTemplateSelected = (id) => {
    let result = false;
    if (templateToBeSent) {
      if (templateToBeSent?.id === id) result = true;
    }

    return result;
  };
  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      {data?.map((template) => (
        <Grid key={template.id} item xs={12} sm={12} md={6} lg={6}>
          <div
            className={
              sendTemplate
                ? `${Style.templateCard} ${
                    getIsTemplateSelected(template.id)
                      ? Style.active
                      : Style.inactive
                  }`
                : Style.templateCard
            }
            onClick={() => handleTemplateClick(template)}
          >
            <div className={Style.templateInfo}>
              <Avatar
                src={`${IMAGE_BASE_URL}${template.image}`}
                alt={template.title}
                width={56}
                height={56}
                className={Style.templateImage}
              />
              <p className={Style.templateTitle}>{template.title}</p>
            </div>
            {showActions ? (
              <div>
                <IconButton
                  aria-label="edit"
                  // sx={{ marginX: "10px" }}
                  onClick={() => handleEditTemplate(template?.id)}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={Edit}
                    alt="edit"
                  />
                </IconButton>
                <IconButton
                  aria-label="cancel"
                  onClick={() => handleRemoveTemplate(template)}
                >
                  <img
                    style={{ height: "20px", width: "20px" }}
                    src={Cancel}
                    alt="cancel"
                  />
                </IconButton>
                {/* <img
                src={Edit}
                width="25px"
                className={Style.actionIcon}
                alt="Edit"
              />
              <img
                src={Cancel}
                width="20px"
                className={Style.actionIcon}
                alt="Cancel"
              /> */}
              </div>
            ) : null}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default TemplateLabel;
