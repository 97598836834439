import React, { useEffect, useMemo, useState } from "react";
import {
  FormLabel,
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
  Button,
  Grid,
  Pagination,
} from "@mui/material";
import CustomerList from "../dashboard/CustomerList";
import Style from "./styles/WhosHere.module.css";
import OptionPill from "../../components/option-pill/OptionPill";
import { Search } from "@mui/icons-material";
import { GET, POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoriesData,
  setPreviousScreen,
  setRestaurantsList,
  setSelectedContacts,
  setWhosHereList,
} from "../../redux/slices/masterDataSlice";
import { toast } from "react-toastify";
import NoDataFound from "../../components/no-data-found/NoDataFound";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE_SIZE } from "../../config/config";

function WhosHere() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, role } = useAuth();
  const { whoseHereList, restaurantsList, selectedContacts } = useSelector(
    (state) => state.masterData
  );
  const [searchKey, setSearchKey] = useState("");
  const [gender, setGender] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    gender: "",
    category: [],
  });

  useEffect(() => {
    searchKey ? searchWhoseHere() : getWhoseHereList();
  }, [formData, currentPage]);

  useEffect(() => {
    dispatch(setSelectedContacts([]));
    getAllRestaurants();
  }, []);

  useEffect(() => {
    const searchData = setTimeout(() => {
      searchKey ? searchWhoseHere() : getWhoseHereList();
    }, 1000);

    return () => clearTimeout(searchData);
  }, [searchKey]);

  const getAllRestaurants = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_ALL_RESTAURANTS_FOR_ADMIN
          : ENDPOINT.GET_ALL_RESTAURANTS
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleSearchChange = (event) => {
    setSearchKey(event.target.value);
  };

  const handleGenderOptionPillClick = (gender) => {
    const isGenderSelected = formData.gender === gender;
    if (isGenderSelected) {
      setFormData({
        ...formData,
        gender: "",
      });
    } else {
      setFormData({
        ...formData,
        gender: gender,
      });
    }
  };

  const getWhoseHereList = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append("gender", formData.gender ? formData.gender : "");
      if (formData?.category?.length)
        formData.category?.forEach((cat) => {
          payload.append("restId[]", cat);
        });
      else payload.append("restId[]", "");
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_WHOS_HERE_FOR_ADMIN
          : ENDPOINT.GET_WHOS_HERE,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setWhosHereList(resp?.response?.data));
        setTotalPageCount(
          resp?.response?.data?.totalRecordsCount > PAGE_SIZE
            ? Math.ceil(resp?.response?.data?.totalRecordsCount / PAGE_SIZE)
            : 0
        );
        setSearchData(resp?.response?.data?.data);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const searchWhoseHere = async () => {
    setLoading(true);
    try {
      const payload = new FormData();
      // payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append("gender", formData.gender ? formData.gender : "");
      payload.append("search", searchKey);
      formData.category?.forEach((cat) => {
        payload.append("restId[]", cat);
      }) || payload.append("restId[]", "");
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_WHOS_HERE_SEARCH_FOR_ADMIN
          : ENDPOINT.GET_WHOS_HERE_SEARCH,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setWhosHereList(resp?.response?.data));
        setTotalPageCount(
          resp?.response?.data?.totalRecordsCount > PAGE_SIZE
            ? Math.ceil(resp?.response?.data?.totalRecordsCount / PAGE_SIZE)
            : 0
        );
        setSearchData(resp?.response?.data?.data);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
    // if (e.target.value?.length >= 2) {
    //   const filteredArray = whoseHereList?.data?.data?.filter((item) => {
    //     if (
    //       item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
    //       item.lastName.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //       return item;
    //   });
    //   setSearchData(filteredArray);
    // } else {
    //   setSearchData(whoseHereList?.data?.data);
    // }
  };

  const handleCategoryOptionPillClick = (newCategoryOption) => {
    const isCategorySelected = formData.category.includes(newCategoryOption.id);

    if (isCategorySelected) {
      setFormData({
        ...formData,
        category: formData.category.filter(
          (category) => category !== newCategoryOption.id
        ),
      });
    } else {
      setFormData({
        ...formData,
        category: [...formData.category, newCategoryOption.id],
      });
      setCurrentPage(1);
    }
  };

  const handleSelectAll = () => {
    const allIds = searchData.map((data) => data.contactNo);
    dispatch(setSelectedContacts(allIds));
  };

  const deselectAll = () => {
    dispatch(setSelectedContacts([]));
  };

  const handleNext = () => {
    navigate("/send-template");
    dispatch(setPreviousScreen(location?.pathname));
  };

  return (
    <>
      <div style={{ margin: "27px 20px" }}>
        <div style={{ marginBottom: "30px" }}>
          <FormLabel sx={{ fontSize: "24px" }}>Who's Here</FormLabel>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontWeight: "500",
              marginBottom: "15px",
              marginRight: "10px",
            }}
          >
            Restaurants:
          </p>
          <div className={Style.restaurantWrapper}>
            {restaurantsList?.data?.data?.original?.map((category) => (
              <OptionPill
                key={category.id}
                active={formData.category.includes(category.id)}
                text={category.name}
                onClick={() => handleCategoryOptionPillClick(category)}
              />
            ))}
          </div>
        </div>
        <div className={Style.emptyLine}></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontWeight: "500",
              marginBottom: "15px",
              marginRight: "10px",
            }}
          >
            Gender:
          </p>
          <div>
            <OptionPill
              active={formData.gender === "Male"}
              text="Male"
              onClick={() => {
                handleGenderOptionPillClick("Male");
                setCurrentPage(1);
              }}
            />
            <OptionPill
              active={formData.gender === "Female"}
              text="Female"
              onClick={() => {
                handleGenderOptionPillClick("Female");
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
        <div className={Style.searchWrapper}>
          <TextField
            id="searchName"
            name="searchName"
            fullWidth
            autoComplete="off"
            type="text"
            placeholder="Search"
            value={searchKey}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{
              sx: {
                textAlign: "end",
              },
            }}
          />
        </div>
        {role === USER_TYPE.MANAGER && searchData?.length > 0 && (
          <FormLabel
            onClick={
              searchData?.length === selectedContacts?.length
                ? deselectAll
                : handleSelectAll
            }
            sx={{ textDecoration: "", "&:hover": { cursor: "pointer" } }}
          >
            {searchData?.length === selectedContacts?.length
              ? "Deselect All"
              : "Select All"}
          </FormLabel>
        )}

        <Grid container spacing={2}>
          {!loading &&
            searchData?.map((customer) => (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <CustomerList key={customer.id} data={customer} showCheckBox />
              </Grid>
            ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                height: "40vh",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" size={50} />
            </Box>
          )}
          <Grid item xs={12} lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
                mb: 15,
              }}
            >
              {totalPageCount ? (
                <Pagination
                  count={totalPageCount}
                  shape="rounded"
                  color="primary"
                  page={currentPage}
                  onChange={(event, value) => setCurrentPage(value)}
                />
              ) : null}
            </Box>
          </Grid>
        </Grid>
        {!loading && searchData?.length === 0 ? <NoDataFound /> : null}
      </div>
      {selectedContacts?.length > 0 && (
        <div className="footerWrapper">
          <div className="submitBtnWrapper">
            <Button size="medium" variant="contained" onClick={handleNext}>
              Next
            </Button>
            <div className="footer"></div>
          </div>
        </div>
      )}
    </>
  );
}

export default WhosHere;
