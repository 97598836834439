import React, { useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  FormLabel,
  TextField,
  Box,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import { resetCustomerData } from "../../redux/slices/customerSlice";
import Styles from "./styles/add-customer.module.css";
const AddLater = () => {
  const navigate = useNavigate();
  const dipatch = useDispatch();
  const { logout } = useAuth();
  const { mobileNumber, countryCode } = useSelector((state) => state.customer);
  const [imagePresent, setImagePresent] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [nameDetails, setNameDetails] = React.useState({
    firstName: "",
    lastName: "",
    profileImage: null,
  });
  const [profile, setProfile] = React.useState(null);
  const inputRef = useRef(null);
  const [error, setError] = React.useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    !mobileNumber && navigate("/customer-search");
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if ((name === "firstName" || name === "lastName") && value.length > 20)
      return;
    setError((prev) => ({ ...prev, [name]: "" }));
    setNameDetails({ ...nameDetails, [name]: value });
  };

  const handleImageError = () => {
    setImagePresent(false);
  };

  const handleSubmit = async () => {
    if (!nameDetails.firstName) {
      setError((prev) => ({ ...prev, firstName: "First Nsme is required" }));
      return;
    }
    if (!nameDetails.lastName) {
      setError((prev) => ({ ...prev, lastName: "Last Nsme is required" }));
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("firstName", nameDetails.firstName);
    formData.append("lastName", nameDetails.lastName);
    formData.append("profileImage", nameDetails.profileImage);
    formData.append("contactNo", mobileNumber);
    formData.append("countryCode", countryCode);
    formData.append("emailId", "");
    formData.append("age", "");
    formData.append("category", "");
    formData.append("remark", "");
    formData.append("dob", "");
    formData.append("anniversaryDate", "");
    formData.append("isWhoseHere", "0");
    formData.append("profileImage", "");
    formData.append("rating", "0");

    try {
      const response = await POST_MULTIPART(ENDPOINT.REGISTER_USER, formData);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        dipatch(resetCustomerData());
        navigate("/customer-search", { replace: true });
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setProfile(fileObj);
    setNameDetails((prev) => ({
      ...prev,
      profileImage: URL.createObjectURL(fileObj),
    }));

    // 👇️ reset file input
    event.target.value = null;
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={Styles.addlaterWrap}
    >
      <input
        style={{ display: "none" }}
        ref={inputRef}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleFileChange}
      />
      <Avatar
        src={nameDetails.profileImage}
        sx={{
          width: 390,
          height: 385,
          marginBottom: "50px",
          backgroundColor: "#eee",
          borderRadius: "15px",
          marginTop: "72px",
        }}
      />
      <FormLabel sx={{ marginBottom: "20px", fontSize: "20px" }}>
        Add Record for Later
      </FormLabel>
      <TextField
        id="firstName"
        name="firstName"
        autoComplete="off"
        type="text"
        placeholder="First Name"
        value={nameDetails.firstName}
        onChange={handleInputChange}
        sx={{
          width: "313px",
          marginBottom: "20px",
        }}
        helperText={error.firstName}
        error={error.firstName}
      />
      <TextField
        id="lastName"
        name="lastName"
        autoComplete="off"
        type="text"
        placeholder="Last Name"
        value={nameDetails.lastName}
        onChange={handleInputChange}
        sx={{
          width: "313px",
          marginBottom: "25px",
        }}
        helperText={error.lastName}
        error={error.lastName}
      />
      <Button
        disabled={isSubmitting}
        size="medium"
        variant="contained"
        onClick={handleSubmit}
        sx={{
          marginBottom: "15px",
          fontWeight: "700",
        }}
      >
        {isSubmitting ? (
          <CircularProgress variant="indeterminate" color="primary" size={30} />
        ) : (
          "Submit"
        )}
      </Button>
    </Box>
  );
};

export default AddLater;
