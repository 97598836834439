import React, { useEffect, useRef, useState } from "react";
import Styles from "./styles.module.css";
import ArrowDown from "../../assets/images/arrow_down.svg";
import Radio from "../../assets/images/radio.svg";
import RadioActive from "../../assets/images/radioActive.svg";

const Dropdown = ({ options, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const clickRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, [open]);

  const handleClickOutside = (e) => {
    if (!clickRef?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div>
      <div className={Styles.container} onClick={handleOpen}>
        <p>
          {options?.filter((item) => item?.value?.toLowerCase() === value)[0]
            ?.name || value}
        </p>
        <img
          className={open ? Styles.activeArrow : ""}
          src={ArrowDown}
          alt="arrow-down"
        />
      </div>

      <div
        className={`${Styles.optionContainer} ${open ? Styles.show : ""}`}
        ref={clickRef}
      >
        <div
          className={Styles.optionWrapper}
          onClick={() => {
            onChange("none");
            setOpen(false);
          }}
        >
          {value?.toLowerCase() === "none" ? (
            <img src={RadioActive} alt="radio" />
          ) : (
            <img src={Radio} alt="radio" />
          )}
          <p>None</p>
        </div>
        {options?.map((item, index) => (
          <div
            key={item?.value}
            className={Styles.optionWrapper}
            onClick={() => {
              onChange(item?.value);
              setOpen(false);
            }}
          >
            {value?.toLowerCase() === item?.value?.toLowerCase() ? (
              <img src={RadioActive} alt="radio" />
            ) : (
              <img src={Radio} alt="radio" />
            )}
            <p>{item?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
