import React, { useEffect, useState } from "react";
import Filtered from "../filtered/Filtered";
import { useDispatch, useSelector } from "react-redux";
import {
  resetMainFilter,
  setActiveDashboardTab,
  setActiveExportTab,
  setOpenCheckInFilter,
  setOpenMainFilter,
  setOpenReservationFilter,
  setPreviousPageSize,
  setSelectedContacts,
  setSelectedIds,
  setSelectedReservations,
} from "../../redux/slices/masterDataSlice";
import { Button, Grid } from "@mui/material";

const Export = () => {
  const dispatch = useDispatch();
  const { activeExportTab } = useSelector((state) => state.masterData);
  const [activeTab, setActiveTab] = useState(activeExportTab || "database");

  useEffect(() => {
    setActiveTab(activeExportTab);
    dispatch(resetMainFilter());
    // dispatch(setOpenMainFilter(true));
    dispatch(setPreviousPageSize(""));
    return () => {
      dispatch(resetMainFilter());
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedIds([]));
      dispatch(setSelectedReservations([]));
      // dispatch(setActiveExportTab(activeTab));
    };
  }, []);

  const handleTabChange = (name) => {
    setActiveTab(name);
    dispatch(setSelectedContacts([]));
    dispatch(resetMainFilter());
    if (name === "database") {
      dispatch(setActiveExportTab("database"));
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedIds([]));
      dispatch(setSelectedReservations([]));
    } else if (name === "reservation") {
      dispatch(setActiveExportTab("reservation"));
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedIds([]));
      dispatch(setSelectedReservations([]));
    }
  };

  return (
    <>
      <div className="container">
        <Grid
          item
          xs={4}
          sx={{ display: "flex", marginBottom: "30px", marginX: "20px" }}
        >
          <Button
            variant="contained"
            color={activeTab === "database" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "12.5px 0 0 12.5px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              dispatch(setActiveExportTab("database"));
              handleTabChange("database");
            }}
          >
            <span>Database</span>
          </Button>
          <Button
            variant="contained"
            color={activeTab === "reservation" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "0 12.5px 12.5px 0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              dispatch(setActiveExportTab("database"));
              handleTabChange("reservation");
            }}
          >
            <span>Reservation</span>
          </Button>
        </Grid>
      </div>
      {activeTab === "database" ? (
        <Filtered exports />
      ) : activeTab === "reservation" ? (
        <Filtered reservationExport />
      ) : null}
    </>
  );
};

export default Export;
