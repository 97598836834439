import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { HOURS, MERIDIUM, MINUTES } from "../../config/constants";
import Style from "./style.module.css";

const TimeSelector = ({ values, onChange, error, removeDefault }) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");

  return (
    <Box
      sx={{
        width: isDesktop ? "359px" : "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControl fullWidth>
        <Select
          variant="standard"
          id="hour"
          defaultValue=""
          value={values.hh}
          displayEmpty
          name="hh"
          onChange={onChange}
          inputProps={{
            style: {
              "& :hover": {
                backgroundColor: "#fff",
              },
            },
          }}
        >
          {!removeDefault && <MenuItem value="">Hours</MenuItem>}
          {HOURS.map((t) => (
            <MenuItem id={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: "red" }}>
          {error?.hh || " "}
        </FormHelperText>
      </FormControl>
      <Box sx={{ mx: 2 }}>:</Box>

      <FormControl fullWidth>
        <Select
          variant="standard"
          id="minutes"
          value={values.mm}
          name="mm"
          onChange={onChange}
          displayEmpty
        >
          {!removeDefault && <MenuItem value="">Minutes</MenuItem>}
          {MINUTES.map((m) => (
            <MenuItem id={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText sx={{ color: "red" }}>
          {error?.mm || " "}
        </FormHelperText>
      </FormControl>
      <Box sx={{ mx: 2 }}>:</Box>
      <FormControl
        fullWidth
        sx={{
          width: "100%",
        }}
      >
        <Select
          variant="standard"
          id="meridiem"
          name="meridiem"
          value={values.meridiem}
          onChange={onChange}
          displayEmpty
        >
          {!removeDefault && <MenuItem value="">AM/PM</MenuItem>}
          {MERIDIUM.map((m) => (
            <MenuItem id={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: "red" }}>
          {error?.meridiem || " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default TimeSelector;
