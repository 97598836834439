import { ENV_VARIABLE } from "../variable";

const environemnt = ENV_VARIABLE[process.env.REACT_APP_ENV];

export const API_BASE_URL = environemnt?.API_BASE_URL;
export const IMAGE_BASE_URL = environemnt?.IMAGE_BASE_URL;
export const RECAPTCHA_SITE_KEY = environemnt?.SITE_KEY;
export const RECAPTCHA_SECRET_KEY = environemnt?.SECRET_KEY;

export const PAGE_SIZE = 10;
