import React, { useEffect, useState } from "react";
import Styles from "./style.module.css";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import OptionPill from "../../components/option-pill/OptionPill";
import Dropdown from "../../components/dropdown/Dropdown";
import { Decrypt } from "../../Crypto-Helper";
import { GET, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  ENDPOINT,
  TEMPLATE_SERVERS,
  TOAST_CONFIG,
  TRIGGER_POINTS,
} from "../../config/constants";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setTemplateId } from "../../redux/slices/masterDataSlice";

const TemplateSetting = () => {
  let { id } = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templateId = id ? Decrypt(id) : null;
  const [formData, setFormData] = useState({
    settingType: 0, //1 : automatic | 0 : manual
    sentUpon: "none",
    chooseServer: "none",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    templateId && getTemplate();
    return () => {
      dispatch(setTemplateId(null));
    };
  }, [templateId]);

  const getTemplate = async () => {
    try {
      const resp = await GET(`${ENDPOINT.GET_TEMPLATE_BY_ID}/${templateId}`);
      if (resp?.response?.data?.status === 200) {
        const formatData = {
          ...resp?.response?.data?.data,
          settingType: resp?.response?.data?.data?.settingType, //1 : automatic | 0 : manual
          sentUpon: resp?.response?.data?.data?.sentUpon || "none",
          chooseServer: resp?.response?.data?.data?.chooseServer || "none",
        };
        setFormData(formatData);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = new FormData();
    const formDataCopy = { ...formData };
    const keys = Object.keys(formDataCopy);
    keys.forEach((key) => {
      payload.append(key, formDataCopy[key]);
    });

    try {
      setIsSubmitting(true);
      const response = await POST_MULTIPART(ENDPOINT.UPDATE_TEMPLATE, payload);
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        navigate("/templates");
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeType = (settingType) => {
    setFormData((prev) => ({ ...prev, settingType: settingType }));
  };

  const handleChangeSentUpon = (param) => {
    setFormData((prev) => ({ ...prev, sentUpon: param?.toLowerCase() }));
  };
  const handleChangeServer = (param) => {
    setFormData((prev) => ({ ...prev, chooseServer: param?.toLowerCase() }));
  };

  return (
    <>
      <div className={Styles.container}>
        <p className={Styles.pageHeader}>Template Setting</p>
        <div className={Styles.formControl}>
          <p className={Styles.label}>Type :</p>
          <OptionPill
            text="Manual"
            sx={{ mb: 0 }}
            active={formData.settingType === 0}
            onClick={() => handleChangeType(0)}
          />
          <OptionPill
            text="Automatic"
            sx={{ mb: 0 }}
            active={formData.settingType === 1}
            onClick={() => handleChangeType(1)}
          />
        </div>
        <div className={Styles.formControl}>
          <p className={Styles.label}>Sent Upon :</p>
          {/* Dropdown here */}
          <Dropdown
            options={TRIGGER_POINTS}
            onChange={handleChangeSentUpon}
            value={formData?.sentUpon}
          />
        </div>
        <div className={Styles.formControl}>
          <p className={Styles.label}>Choose Server :</p>
          {/* Dropdown here */}
          <Dropdown
            options={TEMPLATE_SERVERS}
            onChange={handleChangeServer}
            value={formData?.chooseServer}
          />
        </div>
      </div>
      <div className="footerWrapper">
        <div className="submitBtnWrapper">
          <Button
            disabled={isSubmitting}
            size="medium"
            variant="contained"
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <CircularProgress color="primary" size={35} />
            ) : (
              "Save"
            )}
          </Button>
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
};

export default TemplateSetting;
