"use client";
import React, { useEffect, useRef, useState } from "react";
import bell from "../../assets/icons/bell.svg";
import refresh from "../../assets/icons/refresh.svg";

import cross from "../../assets/icons/cross.svg";

import { Pagination } from "./Pagination";
import "./Notification.scss";

const Notification = () => {
  const [isNotification, setIsNotification] = useState(false);

  const notificationRef = useRef(null);

  const handleClickNotification = () => {
    setIsNotification(!isNotification);
  };

  const handleCloseNotification = () => {
    setIsNotification(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotification(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="notificationWrap" ref={notificationRef}>
      <div className="notificationIcon" onClick={handleClickNotification}>
        <img src={bell} alt="" />
        <span></span>
      </div>

      <div
        className={`notification-dropdown dd ${
          isNotification ? "dropdown-transition" : ""
        }`}
      >
        <div className="arrow-up"></div>
        <div className="shadow">
          <div className="notify">
            <div className="text">
              Notifications <span>0</span>
            </div>
            <div className="refreshAndClose">
              <div className="refreshBtn" title="Refresh">
                <img src={refresh} alt="" />
              </div>
              <div className="closeIcon" onClick={handleCloseNotification}>
                <img src={cross} alt="" />
              </div>
            </div>
          </div>
          <div className="items">
            <ul className="ulItems">
              <li>
                <div className="namePhoto">
                  {/* <div className="photo"></div> */}
                  <div className="name">
                    <b>Booking - Anna Nature for </b> <span>Ritz Carlton</span>
                  </div>
                </div>
                <div className="timeAgo">
                  <span>10 Oct 2024</span>
                  <span className="dots"></span>
                  <span>02:00 PM</span>
                  <span className="dots"></span>
                  <span>4 Pax</span>
                </div>
                <div className="buttonsGroup">
                  <button className="btnDecline">Decline</button>
                  <button className="btnAccept">Reserve</button>
                </div>
              </li>
              <li>
                <div className="namePhoto">
                  {/* <div className="photo"></div> */}
                  <div className="name">
                    <b>Booking - Ketan Pande for </b> <span>Ritz Carlton</span>
                  </div>
                </div>
                <div className="timeAgo">
                  <span>13 Nov 2024</span>
                  <span className="dots"></span>
                  <span>06:00 PM</span>
                  <span className="dots"></span>
                  <span>10 Pax</span>
                </div>
                <div className="buttonsGroup">
                  <button className="btnDecline">Decline</button>
                  <button className="btnAccept">Reserve</button>
                </div>
              </li>
              <li>
                <div className="namePhoto">
                  {/* <div className="photo"></div> */}
                  <div className="name">
                    <b>Booking - Ashish M for </b> <span>Ritz Carlton</span>
                  </div>
                </div>
                <div className="timeAgo">
                  <span>11 Dec 2024</span>
                  <span className="dots"></span>
                  <span>08:00 PM</span>
                  <span className="dots"></span>
                  <span>3 Pax</span>
                </div>
                <div className="buttonsGroup">
                  <button className="btnDecline">Decline</button>
                  <button className="btnAccept">Reserve</button>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
