import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import MessageCard from "./MessageCard";
import { toast } from "react-toastify";
import { ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { POST } from "../../services/axiosRequestHandler";
import NoDataFound from "../no-data-found/NoDataFound";
import { useAuth } from "../../hooks/useAuth";

const MessageTab = ({ customerId, setCustomerName }) => {
  const { logout } = useAuth();
  const [data, setData] = useState([]);
  const [response, setResponse] = useState(null);
  const [previousCount, setPreviousCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCustomerHistory();
  }, [customerId]);

  const getCustomerHistory = async () => {
    const payload = {
      previousCount: previousCount,
      userId: customerId,
    };
    try {
      setLoading(true);
      const response = await POST(ENDPOINT.GET_MESSAGE_HISTORY, payload);
      if (response?.response?.data?.status === 200) {
        setData(response?.response?.data?.data);
        setResponse(response?.response?.data);
        setCustomerName(
          (response?.response?.data?.username?.name || "") +
            " " +
            (response?.response?.data?.username?.lastName || "")
        );
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="loaderWrapper">
        <CircularProgress color="primary" size={56} />
      </div>
    );
  }
  return (
    <Grid container spacing={2}>
      {!loading && data?.length === 0 ? <NoDataFound /> : null}
      {data?.map((item) => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={item.id}>
          <MessageCard data={item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MessageTab;
