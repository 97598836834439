import React, { useState } from "react";
import Styles from "./style.module.css";
import Tabs from "../dashboard/Tabs";
import { Grid } from "@mui/material";
import MessageWhite from "../../assets/images/message_white.svg";
import MessageBlack from "../../assets/images/message_black.svg";
import GlassBlack from "../../assets/images/glass_black.svg";
import GlassWhite from "../../assets/images/glass.svg";
import CalendarWhite from "../../assets/images/calender.svg";
import CalendarBlack from "../../assets/images/calendarRArw.svg";
import MessageCard from "../../components/history/MessageCard";
import ReservationCard from "../../components/history/ReservationCard";
import CheckInCard from "../../components/history/CheckInCard";
import MessageTab from "../../components/history/MessageTab";
import ReservationTab from "../../components/history/ReservationTab";
import CheckInTab from "../../components/history/CheckInTab";
import { useParams } from "react-router-dom";
import { Decrypt } from "../../Crypto-Helper";

const History = () => {
  let { id } = useParams();
  const customerId = id ? Decrypt(id) : null;
  const [activeSubTab, setActiveSubTab] = useState("message");
  const [customerName, setCustomerName] = useState("");
  const tabsData = [
    {
      name: "Message",
      id: "message",
      iconActive: MessageWhite,
      iconInactive: MessageBlack,
      icons: true,
    },
    {
      name: "Reservations",
      id: "reservation",
      iconActive: GlassWhite,
      iconInactive: GlassBlack,
      icons: true,
    },
    {
      name: "Check-In",
      id: "checkIn",
      iconActive: CalendarWhite,
      iconInactive: CalendarBlack,
      icons: true,
    },
  ];
  return (
    <div>
      <div className={Styles.container}>
        <p className={Styles.header}>History: {customerName}</p>
        {/* <p className={Styles.subtitle}>from date to date </p> */}

        <div className={Styles.tabContainer}>
          <Grid container>
            <Tabs
              tabs={tabsData}
              activeTab={activeSubTab}
              setActiveTab={(param) => setActiveSubTab(param)}
            />
          </Grid>
        </div>
        {activeSubTab === "message" ? (
          <MessageTab
            customerId={customerId}
            setCustomerName={setCustomerName}
          />
        ) : null}
        {activeSubTab === "reservation" ? (
          <ReservationTab customerId={customerId} />
        ) : null}
        {activeSubTab === "checkIn" ? (
          <CheckInTab customerId={customerId} />
        ) : null}
      </div>
    </div>
  );
};

export default History;
