import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Styles from "./sidebar.module.scss";
import closeIcon from "../../assets/images/closeIcon.svg";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPE } from "../../config/constants";
import {
  setActiveDashboardTab,
  setActiveExportTab,
  setOpenChooseServerModal,
  setOpenQRModal,
} from "../../redux/slices/masterDataSlice";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  formatDatetime,
  isCurrentDatetimeGreaterThanGivenDatetime,
} from "../../utils";

const SidebarDrawer = styled(SwipeableDrawer)(({ theme }) => ({}));

const MenuItem = styled(RouteLink)(({ theme }) => ({
  fontSize: "24px",
  color: theme.palette.primary.contrastText,
  marginBottom: "35px",
  textDecoration: "none",
  ":hover": {
    cursor: "pointer",
  },
}));

const NoRouteMenuItem = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: theme.palette.primary.contrastText,
  marginBottom: "24px",
  textDecoration: "none",
  ":hover": {
    cursor: "pointer",
  },
}));

const MenuLink = ({ name, path, handleClick, type, callBack }) => {
  if (type === "noRoute") {
    return (
      <NoRouteMenuItem className={Styles.headerLink} onClick={callBack}>
        {name}
      </NoRouteMenuItem>
    );
  }
  return (
    <MenuItem className={Styles.headerLink} to={path} onClick={handleClick}>
      {name}
    </MenuItem>
  );
};

const Sidebar = ({ isSidebarOpen, toggleDrawer }) => {
  const { logout, role } = useAuth();
  const dispatch = useDispatch();
  const { data, lastLogin } = useSelector((state) => state.user);
  const AdminMenuItems = [
    {
      name: "Dashboard",
      path: "/dashboard",
      callBack: () => dispatch(setActiveDashboardTab("todaysList")),
    },
    {
      name: "Live Table",
      path: "/live-table",
    },
    {
      name: "Template",
      path: "/templates",
    },
    {
      name: "Special Occasions",
      path: "/special-occasions",
    },
    {
      name: "Clients List",
      path: "/all-clients",
    },
    {
      name: "Who's Here",
      path: "/whos-here",
    },
    // {
    //   name: "Who's Here Alert",
    //   path: "/whos-here-alert",
    // },
    {
      name: "Choose Server",
      type: "noRoute",
      callBack: () => dispatch(setOpenChooseServerModal(true)),
    },
    {
      name: "QR Code",
      type: "noRoute",
      callBack: () => dispatch(setOpenQRModal(true)),
    },
    {
      name: "Export",
      path: "/export",
    },
    {
      name: "About Us",
      path: "/about-us",
    },
    {
      name: "Privacy Policy",
      path: "/privacy-policy",
    },
  ];

  const StaffMenuItems = [
    {
      name: "Add Record",
      path: "/customer-search",
    },
    {
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      name: "Live Table",
      path: "/live-table",
    },
    {
      name: "Who's Here",
      path: "/whos-here",
    },
    // {
    //   name: "Who's Here Alert",
    //   path: "/whos-here-alert",
    // },
    {
      name: "About Us",
      path: "/about-us",
    },
    {
      name: "Privacy Policy",
      path: "/privacy-policy",
    },
  ];
  const MenuItems =
    role === USER_TYPE.MANAGER ? AdminMenuItems : StaffMenuItems;

  return (
    <SidebarDrawer
      className={`SidebarDrawerMobile`}
      anchor="left"
      open={isSidebarOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
        },
      }}
    >
      <div className={`${Styles.container} ${Styles.sliderWrap}`}>
        <div className={Styles.userDetailsContainer}>
          <div className={Styles.userDataWrapper}>
            <Typography
              variant="h6"
              sx={{
                color: "primary.contrastText",
              }}
            >
              {data?.userName}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "primary.contrastText",
              }}
            >
              {data?.contactNo}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "primary.contrastText",
              }}
            >
              Last Login: {lastLogin ? formatDatetime(lastLogin) : "N.A"}
            </Typography>
          </div>
          <div
            className={Styles.closeIconWrapper}
            onClick={() => toggleDrawer(false)}
          >
            <IconButton>
              <ArrowCircleLeftIcon
                color="secondary"
                sx={{
                  fontSize: 40,
                }}
              />
            </IconButton>
            {/* <img src={closeIcon} alt="close" /> */}
          </div>
        </div>
        <div className={Styles.menuContainer}>
          {MenuItems.map((menuItem, index) => (
            <MenuLink
              name={menuItem?.name}
              path={menuItem?.path}
              key={index}
              handleClick={() => {
                if (menuItem?.path === "/dashboard") {
                  dispatch(setActiveDashboardTab("todaysList"));
                } else if (menuItem?.path === "/export") {
                  dispatch(setActiveExportTab("database"));
                }
                toggleDrawer(false);
              }}
              type={menuItem?.type}
              callBack={menuItem?.callBack}
            />
          ))}
          <div>
            <Button variant="contained" color="secondary" onClick={logout}>
              Logout
            </Button>
            <Box sx={{ mt: 4 }}>
              <p className={Styles.version}>
                Version : {process.env.REACT_APP_VERSION}
              </p>
              {/* <p
                className={Styles.version}
              >{`Copyright (${new Date().getFullYear()} Datalekt)`}</p> */}
              <p className={Styles.version}>Copyright (2021 Datalekt)</p>
            </Box>
          </div>
        </div>
        {/* <div className={Styles.logoutWrapper}>
          <Button
            variant="contained"
            color="secondary"
            onClick={logout}
            sx={{ m: 5 }}
          >
            Logout
          </Button>
        </div> */}
      </div>
    </SidebarDrawer>
  );
};

export default Sidebar;
