import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Header from "../components/header/Header";
import { Box, Container, useMediaQuery } from "@mui/material";
import Filter from "../components/filter/Filter";
import { useEffect } from "react";

export const ProtectedLayout = () => {
  const { user } = useAuth();
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width:1080px)");

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <div>
      <Header />
      <Box sx={{ mt: "116px", zIndex: "2" }}>
        <Outlet />
      </Box>
    </div>
  );
};
