import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { ENDPOINT, ERROR_ACTIONS, TOAST_CONFIG } from "../config/constants";
import { GET, POST } from "./axiosRequestHandler";

export const getList = async (method = "GET", path, payload = {}) => {
  try {
    const resp =
      method === "POST" ? await POST(path, payload) : await GET(path);
    if (resp?.response?.data?.status === 200) {
      return { error: null, data: resp?.response?.data };
    } else if (
      resp?.response?.data?.status === 401 &&
      resp?.response?.data?.message === "User Token Expired"
    ) {
      toast.error("Your session has expired!", TOAST_CONFIG);
      return { error: ERROR_ACTIONS.SESSION_EXPIRED, data: null };
    } else {
      toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      return;
    }
  } catch (error) {
    toast.error(error?.message, TOAST_CONFIG);
  }
};
