import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedContacts } from "../../redux/slices/masterDataSlice";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import Style from "../filtered/styles/Filtered.module.css";
import { Tooltip } from '@mui/material';

function Tabs({ tabs, activeTab, setActiveTab }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [opentable, setOpenTable] = useState(false);

  const handleTouchStart = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000); // Tooltip will disappear after 2 seconds (adjust as needed)
  };
  const handleTouchStartTable = () => {
    setOpenTable(true);
    setTimeout(() => {
      setOpenTable(false);
    }, 2000); // Tooltip will disappear after 2 seconds (adjust as needed)
  };
  const handleTabClick = (taskId) => {
    setActiveTab(taskId);
    dispatch(setSelectedContacts([]));
  };

  return (
    <>
      {tabs?.map((button, index) => (
        <Grid item xs={tabs?.length === 2 ? 6 : 4} key={button.id}>
          <Button
            variant="contained"
            color={activeTab === button.id ? "primary" : "secondary"}
            sx={{
              borderRadius:
                index === 0
                  ? "12.5px 0 0 12.5px"
                  : index === tabs.length - 1
                    ? "0 12.5px 12.5px 0"
                    : "0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "44px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            fullWidth
            onClick={() => handleTabClick(button.id)}
          >

            <div className={Style.tabCount}>
              {button.count}
              <span>{button.name}</span>
            </div>

            {button?.icons ? (
              activeTab === button.id ? (
                <img src={button.iconActive} />
              ) : (
                <img src={button.iconInactive} />
              )
            ) : null}



            {button?.totalCount?.totalPax && <div className={Style.tabIconLeft}>
              <p className={Style.totalIconsWrapper}>

                {activeTab === button.id ? (
                  <Tooltip
                    title="Total Pax"
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    placement="top"
                    arrow
                  >
                    <SupervisorAccountIcon
                      className={Style.iconFont}
                      onTouchStart={handleTouchStart}
                    />
                  </Tooltip>
                ) : (
                  <SupervisorAccountIcon className={Style.iconFont} />
                )}
                <span className={Style.ml1}>
                  {button?.totalCount?.totalPax}
                </span>

              </p>
              <p className={Style.totalIconsWrapper}>
                {activeTab === button.id ? (
                  <Tooltip
                    title="Total Table"
                    open={opentable}
                    onOpen={() => setOpenTable(true)}
                    onClose={() => setOpenTable(false)}
                    arrow
                  >
                    <TableRestaurantIcon
                      className={Style.iconFont}
                      onTouchStart={handleTouchStartTable}
                    />
                  </Tooltip>
                ) : (
                  <TableRestaurantIcon className={Style.iconFont} />
                )}
                <span className={Style.ml1} >
                  {button?.totalCount?.totalTablePdr}
                </span>

              </p>
            </div>}

          </Button>
        </Grid>
      ))}
    </>
  );
}

export default Tabs;
