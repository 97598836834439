import React, { useEffect, useState } from "react";
import Style from "./styles/allClientsList.module.css";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  CircularProgress,
  Box,
  Pagination,
} from "@mui/material";
import CustomerList from "../dashboard/CustomerList";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setClientListActiveTab } from "../../redux/slices/masterDataSlice";
import NoDataFound from "../../components/no-data-found/NoDataFound";
import { PAGE_SIZE } from "../../config/config";
import { getList } from "../../services/userServices";
import { ENDPOINT } from "../../config/constants";
import TotalList from "./TotalList";
import PendingList from "./PendingList";
import CompletedList from "./CompletedList";

function AllClientsList() {
  const dispatch = useDispatch();
  const {
    clientCounts,
    allClientList,
    pendingClientList,
    completedClientList,
    clientListActiveTab,
  } = useSelector((state) => state.masterData);
  const [activeTab, setActiveTab] = useState(
    clientListActiveTab?.toLowerCase()
  );
  const [searchKey, setSearchKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLoading, setTotalLoading] = useState(true);
  const [pendingLoading, setPendingLoading] = useState(true);
  const [completedLoading, setCompletedLoading] = useState(true);

  useEffect(() => {
    searchKey
      ? handleSearch({
          target: {
            value: searchKey,
          },
        })
      : setSearchData(getDataToRender());
  }, [activeTab]);

  const getDataToRender = () => {
    setLoading(true);
    if (activeTab === "total") {
      setTotalPageCount(
        allClientList?.data?.data?.length > PAGE_SIZE
          ? Math.ceil(allClientList?.data?.data?.length / PAGE_SIZE)
          : 0
      );
      setLoading(false);
      return allClientList?.data?.data || [];
    } else if (activeTab === "pending") {
      setTotalPageCount(
        pendingClientList?.data?.data?.length > PAGE_SIZE
          ? Math.ceil(pendingClientList?.data?.data?.length / PAGE_SIZE)
          : 0
      );
      setLoading(false);
      return pendingClientList?.data?.data || [];
    } else if (activeTab === "completed") {
      setTotalPageCount(
        completedClientList?.data?.data?.length > PAGE_SIZE
          ? Math.ceil(completedClientList?.data?.data?.length / PAGE_SIZE)
          : 0
      );
      setLoading(false);
      return completedClientList?.data?.data || [];
    } else {
      setLoading(false);
      return [];
    }
  };

  const handleTabChange = (isTab) => {
    setActiveTab(isTab);
    dispatch(setClientListActiveTab(isTab));
  };

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
    // if (event.target.value?.length >= 2) {
    //   const filteredArray = getDataToRender().filter((item) => {
    //     if (
    //       item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
    //       item.lastName
    //         .toLowerCase()
    //         .includes(event.target.value.toLowerCase()) ||
    //       item.contactNo.includes(event.target.value)
    //     )
    //       return item;
    //   });
    //   setSearchData(filteredArray);
    // } else {
    //   setSearchData(getDataToRender());
    // }
  };

  if (loading) {
    return (
      <div className="loaderWrapper">
        <CircularProgress size={50} />
      </div>
    );
  }
  return (
    <div className={Style.container}>
      <div>
        <Grid item xs={4} sx={{ display: "flex" }}>
          <Button
            variant="contained"
            color={activeTab === "total" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "12.5px 0 0 12.5px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              handleTabChange("total");
              setSearchKey("");
            }}
          >
            <span>Total</span>
          </Button>
          <Button
            variant="contained"
            color={activeTab === "pending" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              handleTabChange("pending");
              setSearchKey("");
            }}
          >
            <span>Pending</span>
          </Button>
          <Button
            variant="contained"
            color={activeTab === "completed" ? "primary" : "secondary"}
            fullWidth
            sx={{
              borderRadius: "0 12.5px 12.5px 0",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              handleTabChange("completed");
              setSearchKey("");
            }}
          >
            <span>Completed</span>
          </Button>
        </Grid>
      </div>
      <div style={{ margin: "30px 0 20px 0" }}>
        <TextField
          id="searchName"
          name="searchName"
          fullWidth
          autoComplete="off"
          type="text"
          placeholder="Search"
          value={searchKey}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            sx: {
              textAlign: "end",
            },
          }}
        />
      </div>
      <div>
        {activeTab === "total" && <TotalList searchKey={searchKey} />}
        {activeTab === "pending" && <PendingList searchKey={searchKey} />}
        {activeTab === "completed" && <CompletedList searchKey={searchKey} />}
        {/* <Grid container spacing={2} sx={{ mb: 12 }}>
          {searchData?.map((customer) => (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CustomerList
                key={customer.id}
                data={customer}
                dashboardActions
                showCheckBox={false}
              />
            </Grid>
          ))}
        </Grid> */}
      </div>
    </div>
  );
}

export default AllClientsList;
