import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  InputLabel,
  Rating,
  Switch,
  TextField,
  Button,
  IconButton,
  Backdrop,
  Dialog,
  DialogContent,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import ImagePreviewIcon from "../../assets/images/image_preview.svg";
import ImageEditIcon from "../../assets/images/image_edit.svg";
import OptionPill from "../../components/option-pill/OptionPill";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Styles from "./styles/add-customer.module.css";
import DatePicker from "../../components/datepicker/DatePicker";
import {
  convertDateFormat,
  formatDatetime,
  formatISODateString,
} from "../../utils";
import DateRangePicker from "../../components/datepicker/DateRangePicker";
import { GET, POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import { ENDPOINT, TOAST_CONFIG, USER_TYPE } from "../../config/constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgeData,
  setCategoriesData,
} from "../../redux/slices/masterDataSlice";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config/config";
import { resetCustomerData } from "../../redux/slices/customerSlice";
import { Decrypt, Encrypt } from "../../Crypto-Helper";
import ErrorPage from "../../components/error/ErrorPage";
import CustomeDateRangePicker from "../../components/date-range-picker/CustomeDateRangePicker";
import dayjs from "dayjs";

const StandardInputField = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(min-width:320px) and (max-width:1024px)");
  const customStyles = {
    marginBottom: "45px",
    borderBottom: "2px",
    width: !isMobile ? "359px" : "80%",
    "& .MuiInput-underline:before": {
      borderBottom: "2px solid #D9D9D9",
    },
    ...props.sx,
  };

  const customInputProps = {
    sx: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
      ...props?.inputProps?.sx,
    },
  };
  return (
    <TextField
      {...props}
      variant="standard"
      sx={customStyles}
      inputProps={customInputProps}
      onClick={props.onClick}
    />
  );
};

const Col = ({ center, children, ...props }) => {
  const customeStyles = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };
  return (
    <Grid
      {...props}
      item
      xs={12}
      sm={12}
      md={12}
      lg={4}
      sx={center ? { ...customeStyles, ...props.sx } : {}}
    >
      {children}
    </Grid>
  );
};

const AddCustomer = (props) => {
  const { edit } = props;
  let { id } = useParams();
  const customerId = id ? Decrypt(id) : null;
  const navigate = useNavigate();
  const { age, categories } = useSelector((state) => state.masterData);
  const { mobileNumber, countryCode } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const { logout, role } = useAuth();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: mobileNumber ?? "",
    email: "",
    designation: "",
    dob: null,
    anniversaryDate: null,
    arrival: null,
    rating: 0,
    age: "",
    category: [],
    dnd: false,
    gender: "",
    whoIsHere: true,
    remarks: "",
    checkInDate: null,
    checkOutDate: null,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    designation: "",
  });
  const [occasionType, setOccasionType] = useState("birthday");
  const [openImage, setOpenImage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profile, setProfile] = useState(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(edit ? true : false);
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    getAgeData();
    getCategoryData();
  }, []);

  useEffect(() => {
    if (edit && customerId) getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoading(true);
    try {
      const resp = await GET(
        `${
          role === USER_TYPE.MANAGER
            ? ENDPOINT.GET_USER_FOR_ADMIN
            : ENDPOINT.GET_USER
        }/${customerId}`
      );
      if (resp?.response?.data?.status === 200) {
        setUserData(resp?.response?.data);
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }
  };

  const setUserData = (data) => {
    setVisitCount(`${data?.visitCount}`);
    const userData = {
      ...data?.data,
      firstName: data?.data?.name,
      lastName: data?.data?.lastName,
      phone: data?.data?.contactNo,
      email: data?.data?.email === "null" ? "" : data?.data?.email,
      designation:
        data?.data?.designation === "null" ? "" : data?.data?.designation,
      dob: data?.data?.dob ? data.data.dob : null,
      anniversaryDate: data?.data?.anniversaryDate
        ? data.data.anniversaryDate
        : null,
      arrival: null,
      rating: data?.data?.rating,
      age: data?.data?.ageId,
      category: data?.data?.category?.map((cat) => cat.id),
      dnd: data?.data?.isDnd,
      gender: data?.data?.gender,
      whoIsHere: data?.data?.isWhoseHere ? true : false,
      remarks: data?.data?.remark === "null" ? "" : data?.data?.remark,
      checkInDate: data?.data?.check_in ? data.data.check_in : null,
      checkOutDate: data?.data?.check_out ? data.data.check_out : null,
      // checkInDate: new Date(),
      // checkOutDate: new Date(),
      profileImage: data?.data?.profileImage
        ? `${IMAGE_BASE_URL}/${data?.data?.profileImage}`
        : null,
    };
    delete userData.check_in;
    delete userData.check_out;
    setFormData(userData);
  };

  const getAgeData = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_AGE_FOR_ADMIN
          : ENDPOINT.GET_AGE
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setAgeData(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };
  const getCategoryData = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_CATEGORIES_FOR_ADMIN
          : ENDPOINT.GET_CATEGORY
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setCategoriesData(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleOccasionChange = (type) => {
    setOccasionType(type);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const validateFirstName = (value) => {
    if (!value) {
      return "First name is required.";
    }
    return "";
  };

  const validateLastName = (value) => {
    if (!value) {
      return "Last name is required.";
    }
    return "";
  };

  const validatePhone = (value) => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(value)) {
      return "Phone number is invalid.";
    }
    return "";
  };

  const validateEmail = (value) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(value)) {
      return "Email is invalid.";
    }
    return "";
  };

  const validateDesignation = (value) => {
    if (!value) {
      return "Designation is required.";
    }
    return "";
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setProfile(fileObj);
    setFormData((prev) => ({
      ...prev,
      profileImage: URL.createObjectURL(fileObj),
    }));

    // 👇️ reset file input
    event.target.value = null;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if ((name === "firstName" || name === "lastName") && value.length > 20)
      return;
    const validationFunc = {
      firstName: validateFirstName,
      lastName: validateLastName,
      phone: validatePhone,
      email: validateEmail,
      designation: validateDesignation,
    }[name];

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: validationFunc(value),
    });
  };

  const handleRemarkChange = (e) => {
    setFormData((prev) => ({ ...prev, remarks: e.target.value }));
  };
  const handleOccasionInputChange = (value) => {
    if (occasionType === "birthday") {
      setFormData({
        ...formData,
        dob: value,
      });
    }
    if (occasionType === "anniversary") {
      setFormData({
        ...formData,
        anniversaryDate: value,
      });
    }
  };

  const handleCheckInDateChange = (value) => {
    setFormData({
      ...formData,
      checkInDate: value,
    });
  };

  const handleCheckInOutChange = (value) => {
    setFormData({
      ...formData,
      checkInDate: value?.startDate,
      checkOutDate: value?.endDate,
    });
  };
  const handleCheckOutDateChange = (value) => {
    setFormData({
      ...formData,
      checkOutDate: value,
    });
  };

  const handleArrivalInputChange = (value) => {
    setFormData({
      ...formData,
      arrival: value,
    });
  };

  const handleOptionPillClick = (propName) => {
    setFormData({
      ...formData,
      [propName]: !formData[propName],
    });
  };

  const handleAgeOptionPillClick = (newAgeOption) => {
    setFormData({
      ...formData,
      age: newAgeOption,
    });
  };

  const handleGenderOptionPillClick = (newGenderOption) => {
    setFormData({
      ...formData,
      gender: newGenderOption,
    });
  };

  const handleCategoryOptionPillClick = (newCategoryOption) => {
    const isCategorySelected = formData.category.includes(newCategoryOption);

    if (isCategorySelected) {
      setFormData({
        ...formData,
        category: formData.category.filter(
          (category) => category !== newCategoryOption
        ),
      });
    } else {
      setFormData({
        ...formData,
        category: [...formData.category, newCategoryOption],
      });
    }
  };

  const handleRatingChange = (newValue) => {
    setFormData({
      ...formData,
      rating: newValue,
    });
  };

  const handleSwitchChange = () => {
    setFormData({
      ...formData,
      whoIsHere: !formData.whoIsHere,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payloadFormData = new FormData();
    payloadFormData.append("firstName", formData.firstName);
    payloadFormData.append("lastName", formData.lastName);
    payloadFormData.append("contactNo", formData.phone);
    payloadFormData.append("countryCode", countryCode);
    payloadFormData.append("emailId", formData.email);
    payloadFormData.append("designation", formData.designation);
    payloadFormData.append("age[]", formData.age ? formData.age : "0");
    formData?.category.forEach((cat) =>
      payloadFormData.append("category[]", cat)
    );
    payloadFormData.append("remark", formData.remarks);
    payloadFormData.append("isDnd", formData.dnd ? 1 : 0);
    payloadFormData.append("gender", formData.gender);
    payloadFormData.append(
      "dob",
      formData.dob
        ? formatISODateString(new Date(formData.dob).toISOString())
        : ""
    );
    payloadFormData.append(
      "anniversaryDate",
      formData.anniversaryDate
        ? formatISODateString(new Date(formData.anniversaryDate).toISOString())
        : ""
    );
    payloadFormData.append("isWhoseHere", formData.whoIsHere ? 1 : 0);
    payloadFormData.append("profileImage", profile);
    payloadFormData.append("rating", formData.rating);
    payloadFormData.append(
      "check_in",
      formData.checkInDate
        ? formatISODateString(new Date(formData.checkInDate).toISOString())
        : ""
    );
    payloadFormData.append(
      "check_out",
      formData.checkOutDate
        ? formatISODateString(new Date(formData.checkOutDate).toISOString())
        : ""
    );

    try {
      const response = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.REGISTER_USER_FOR_ADMIN
          : ENDPOINT.REGISTER_USER,
        payloadFormData
      );
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        dispatch(resetCustomerData());
        role === USER_TYPE.MANAGER
          ? navigate(-1) // "/dashboard"
          : navigate("/customer-search", { replace: true });
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    const payloadFormData = new FormData();

    payloadFormData.append("id", formData.id);
    payloadFormData.append("firstName", formData.firstName);
    payloadFormData.append("lastName", formData.lastName);
    payloadFormData.append("contactNo", formData.phone);
    payloadFormData.append("countryCode", formData.countryCode);
    payloadFormData.append("emailId", formData.email);
    payloadFormData.append("designation", formData.designation);
    payloadFormData.append("age[]", formData.age ? formData.age : "0");
    formData?.category.forEach((cat) =>
      payloadFormData.append("category[]", cat)
    );
    payloadFormData.append("remark", formData.remarks);
    payloadFormData.append("isDnd", formData.dnd ? 1 : 0);
    payloadFormData.append("gender", formData.gender);
    payloadFormData.append(
      "dob",
      formData.dob
        ? formatISODateString(new Date(formData.dob).toISOString())
        : ""
    );
    payloadFormData.append(
      "anniversaryDate",
      formData.anniversaryDate
        ? formatISODateString(new Date(formData.anniversaryDate).toISOString())
        : ""
    );
    payloadFormData.append("isWhoseHere", formData.whoIsHere ? 1 : 0);
    payloadFormData.append("profileImage", profile);
    payloadFormData.append("rating", formData.rating);
    payloadFormData.append(
      "check_in",
      formData.checkInDate
        ? formatISODateString(new Date(formData.checkInDate).toISOString())
        : ""
    );
    payloadFormData.append(
      "check_out",
      formData.checkOutDate
        ? formatISODateString(new Date(formData.checkOutDate).toISOString())
        : ""
    );

    try {
      const response = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.UPDATE_USER_FOR_ADMIN
          : ENDPOINT.UPDATE_USER,
        payloadFormData,
        true
      );
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        dispatch(resetCustomerData());
        navigate(-1);
        // role === USER_TYPE.MANAGER
        //   ? navigate("/dashboard")
        //   : navigate("/customer-search", { replace: true });
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setIsSubmitting(false);
    }
  };
  const isMobile = useMediaQuery("(min-width:320px) and (max-width:1024px)");

  if (edit && !customerId) {
    return <ErrorPage />;
  }
  return (
    <div className={Styles.addCustWrapper}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" size={50} />
        </Box>
      ) : (
        <>
          <Grid
            container
            sx={{ mt: "97px", mb: "100px" }}
            justifyContent="center"
            spacing={2}
          >
            <Col center>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />

              <Dialog
                open={openImage}
                keepMounted
                onClick={() => setOpenImage(false)}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <img
                    style={{
                      width: "500px",
                    }}
                    src={formData.profileImage}
                    alt=""
                  />
                </DialogContent>
              </Dialog>
              {edit && (
                <div className={Styles.visitCount}>
                  <p>
                    {visitCount?.length === 1 ? "0" + visitCount : visitCount}
                  </p>
                </div>
              )}
              <Avatar
                variant="square"
                className={Styles.squareBlock}
                sx={{
                  width: "406px",
                  height: "240px",
                  borderRadius: "20px",
                  backgroundColor: "#F3F3F3",
                }}
                src={formData.profileImage}
              />

              <div className={Styles.imageActionIconContainer}>
                <IconButton
                  disabled={!formData.profileImage}
                  aria-label="preview"
                  onClick={() => setOpenImage(true)}
                  sx={{
                    backgroundColor: "#F3F3F3",
                    "&:hover": { backgroundColor: "#c4c4c4" },
                  }}
                >
                  <img src={ImagePreviewIcon} alt="image-preview" />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "#F3F3F3",
                    "&:hover": { backgroundColor: "#c4c4c4" },
                  }}
                  aria-label="edit"
                  onClick={handleClick}
                >
                  <img src={ImageEditIcon} alt="image-edit" />
                </IconButton>
              </div>
            </Col>
            <Col center column>
              <StandardInputField
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                helperText={errors.firstName}
                error={errors.firstName}
                onChange={handleInputChange}
              />
              <StandardInputField
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                helperText={errors.lastName}
                error={errors.lastName}
                onChange={handleInputChange}
              />
              <StandardInputField
                type="tel"
                placeholder="Phone"
                name="phone"
                value={formData.phone}
                helperText={errors.phone}
                error={errors.phone}
                onChange={handleInputChange}
              />
              <StandardInputField
                error={errors.email}
                type="email"
                placeholder="Email ID"
                name="email"
                value={formData.email}
                helperText={errors.email}
                onChange={handleInputChange}
              />
              <StandardInputField
                type="text"
                placeholder="Designation"
                name="designation"
                value={formData.designation}
                helperText={errors.designation}
                error={errors.designation}
                onChange={handleInputChange}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: !isMobile ? "359px" : "80%",
                  }}
                >
                  <DatePicker
                    views={["year", "month", "day"]}
                    value={
                      occasionType === "birthday"
                        ? formData.dob && formData.dob
                        : formData.anniversaryDate && formData.anniversaryDate
                    }
                    onChange={handleOccasionInputChange}
                    placeholder="Sp. Occasions"
                    onOccasionChange={handleOccasionChange}
                    occasion={occasionType}
                  />
                  {/* <DateRangePicker
                    placeholder="Check-in"
                    checkInDate={formData.checkInDate}
                    checkOutDate={formData.checkOutDate}
                    onChangeCheckInDate={handleCheckInDateChange}
                    onChangeCheckOutDate={handleCheckOutDateChange}
                  /> */}
                  <CustomeDateRangePicker
                    value={{
                      checkIn: formData.checkInDate,
                      checkOut: formData.checkOutDate,
                    }}
                    label={
                      formData?.checkInDate
                        ? formatDatetime(new Date(formData.checkInDate), true)
                        : "Check-In"
                    }
                    handleDone={handleCheckInOutChange}
                  />
                </Box>
              </LocalizationProvider>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    mr: "20px",
                  }}
                >
                  Rating
                </InputLabel>
                <Rating
                  size="large"
                  value={formData.rating}
                  onChange={(event, newValue) => handleRatingChange(newValue)}
                />
              </Box>
              <Box
                sx={{
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mb: "10px",
                    }}
                  >
                    Age
                    {/* {formData?.age === "" ? "Age" : formData?.age} */}
                  </InputLabel>
                </Box>
                <Box
                  sx={{
                    flexGrow: "1",
                  }}
                >
                  {age?.data?.data?.map((ageOption) => (
                    <OptionPill
                      key={ageOption.id}
                      active={formData.age === ageOption.id}
                      text={`${ageOption.ageFrom}-${ageOption.ageTo}`}
                      onClick={() => handleAgeOptionPillClick(ageOption.id)}
                    />
                  ))}
                </Box>
              </Box>
            </Col>
            <Col center sx={{ mb: 10 }}>
              <Box
                sx={{
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mb: "10px",
                      width: "280px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Category
                    {/* {formData.category.length === 0
                  ? "Category"
                  : `${
                      formData.category[formData.category.length - 1]
                    }, ${formData.category.slice(0, -1).join(", ")}`} */}
                  </InputLabel>
                  {/* <div
                sx={{
                  fontSize: "14px",
                  fontStyle: "italic",
                  color: "#999",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                style={{
                  marginBottom: "10px",
                }}
              >
                {formData.category.length > 1 &&
                  `+${formData.category.length - 1} more`}
              </div> */}
                </Box>
                <Box
                  sx={{
                    flexGrow: "1",
                  }}
                >
                  {categories?.data?.data?.map((category) => (
                    <OptionPill
                      key={category.id}
                      active={formData.category.includes(category.id)}
                      text={category.name}
                      onClick={() => handleCategoryOptionPillClick(category.id)}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mb: "10px",
                    }}
                  >
                    Do not Disturb
                  </InputLabel>
                </Box>
                <Box
                  sx={{
                    width: !isMobile ? "359px" : "80%",
                    flexGrow: "1",
                  }}
                >
                  <OptionPill
                    active={formData.dnd}
                    text="DND"
                    onClick={() => handleOptionPillClick("dnd")}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mb: "10px",
                    }}
                  >
                    Gender
                    {/* {formData?.gender === "" ? "Gender" : formData?.gender} */}
                  </InputLabel>
                </Box>
                <Box
                  sx={{
                    width: !isMobile ? "359px" : "80%",
                    flexGrow: "1",
                  }}
                >
                  <OptionPill
                    active={formData.gender === "Male"}
                    text="Male"
                    onClick={() => handleGenderOptionPillClick("Male")}
                  />
                  <OptionPill
                    active={formData.gender === "Female"}
                    text="Female"
                    onClick={() => handleGenderOptionPillClick("Female")}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "45px",
                  width: !isMobile ? "359px" : "80%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // marginBottom: "45px",
                    // width: !isMobile ? "359px" : "75%",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mr: "20px",
                    }}
                  >
                    Who's Here
                  </InputLabel>
                  <Switch
                    size="large"
                    checked={formData.whoIsHere}
                    onChange={handleSwitchChange}
                  />
                </Box>
                {/* {edit && role === USER_TYPE.MANAGER ? ( */}
                  <Button
                    variant="text"
                    onClick={() => navigate(`/history/${Encrypt(customerId)}`)}
                  >
                    History
                  </Button>
                {/* ) : null} */}
              </Box>
              <StandardInputField
                type="text"
                placeholder="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleRemarkChange}
              />
            </Col>
          </Grid>
          <div className={Styles.footerWrapper}>
            <div className={Styles.submitBtnWrapper}>
              {edit ? (
                <Button
                  disabled={isSubmitting}
                  size="medium"
                  variant="contained"
                  sx={{}}
                  onClick={handleUpdate}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      size={30}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              ) : (
                <Button
                  disabled={isSubmitting}
                  size="medium"
                  variant="contained"
                  sx={{}}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? (
                    <CircularProgress
                      variant="indeterminate"
                      color="primary"
                      size={30}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            </div>
            <div className={Styles.footer}></div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddCustomer;
