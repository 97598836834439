import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  role: "staff",
  userType: null,
  data: null,
  loading: false,
  hasError: false,
  errorMessage: "",
  lastLogin: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.hasError = true;
      state.errorMessage = action.payload;
    },
    resetError: (state, action) => {
      state.error = null;
      state.hasError = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setLastLogin: (state, action) => {
      state.lastLogin = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserData,
  setError,
  resetError,
  setLoading,
  setRole,
  setUserType,
  setLastLogin,
} = userSlice.actions;

export default userSlice.reducer;
