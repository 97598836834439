import axios from "axios";
import { API_BASE_URL } from "./config";

const customAxios = (contentType) => {
  const Axios = axios.create({
    baseURL: API_BASE_URL, // Replace with your API base URL
    headers: {
      "Content-Type": contentType,
      "Permissions-Policy":
        "geolocation=(), camera=(), microphone=(), interest-cohort=()",
      // "x-frame-options": "SAMEORIGIN",
      // "X-Content-Type-Options": "nosniff",
      // "Strict-Transport-Security": "max-age=2592000; includeSubDomains",
      // "Content-Security-Policy": "default-src self",
      // "Referrer-Policy": "no-referrer",
    },
  });

  // Request interceptor
  Axios.interceptors.request.use(
    (config) => {
      config.headers["Access-Control-Allow-Origin"] = "*";

      // Modify the request config here (add headers, authentication tokens)
      const accessToken = JSON.parse(localStorage.getItem("token"));

      // If token is present add it to request's Authorization Header
      if (accessToken) {
        if (config.headers) config.headers.token = accessToken;
      }
      return config;
    },
    (error) => {
      // Handle request errors here

      return Promise.reject(error);
    }
  );
  // End of Request interceptor

  // Response interceptor
  Axios.interceptors.response.use(
    (response) => {
      // Modify the response data here
      return response;
    },
    (error) => {
      // Handle response errors here

      return Promise.reject(error);
    }
  );
  // End of Response interceptor
  return Axios;
};

export default customAxios;
