import { encrypt } from "../Crypto-Helper";
import Axios from "../config/Axios";

const axiosForJSON = Axios("application/json");
const axiosForMultipart = Axios("multipart/form-data");

export const GET = async (path, params = "") => {
  let result = {
    response: null,
    status: 500,
  };
  try {
    const response = await axiosForJSON.get(`${path}${params}`);
    if (response) {
      return {
        response,
        status: response.status,
      };
    }
  } catch (error) {}

  return result;
};

export const POST = async (path, payload = {}, isEncrypted = false) => {
  let result = {
    response: null,
    status: 500,
  };
  try {
    const response = await axiosForJSON.post(
      path,
      isEncrypted
        ? {
            _k: encrypt(JSON.stringify(payload)),
          }
        : payload
    );
    if (response) {
      return {
        response,
        status: response.status,
      };
    }
  } catch (error) {}

  return result;
};

export const PUT = async (path, payload) => {
  let result = {
    response: null,
    status: 500,
  };
  try {
    const response = await axiosForJSON.put(path, payload);
    if (response) {
      return {
        response,
        status: response.status,
      };
    }
  } catch (error) {}
  return result;
};

export const POST_MULTIPART = async (
  path,
  payload = {},
  isEncrypted = false
) => {
  let result = {
    response: null,
    status: 500,
  };
  const dt = new FormData();
  if (isEncrypted) {
    let catCounter = 0;
    let tableDetailsCounter = 0;
    let tableNumberCounter = 0;
    for (let [key, value] of payload.entries()) {
      if (key === "bookinCard" || key === "profileImage") {
        dt.append(key, value);
      } else if (key === "category[]") {
        const updatedKey = `category[${catCounter}]`;
        dt.append(encrypt(updatedKey), encrypt(value));
        catCounter++;
      } else if (key === "tableDetails[]") {
        const updatedKey = `tableDetails[${tableDetailsCounter}]`;
        dt.append(encrypt(updatedKey), encrypt(value));
        tableDetailsCounter++;
      } else if (key === "tableNumber[]") {
        const updatedKey = `tableNumber[${tableNumberCounter}]`;
        dt.append(encrypt(updatedKey), encrypt(value));
        tableNumberCounter++;
      } else dt.append(encrypt(key), encrypt(value));
    }
    dt.append("_k", "v");
  }
  try {
    const response = await axiosForMultipart.post(
      path,
      isEncrypted ? dt : payload
    );
    if (response) {
      return {
        response,
        status: response.status,
      };
    }
  } catch (error) {}

  return result;
};
