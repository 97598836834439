import Styles from "./admin-staff-toggle.module.css";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetError, setRole } from "../../redux/slices/userSlice";

function AdminStaffToggle() {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.user);

  const handleChangeRole = (role) => {
    dispatch(setRole(role));
    dispatch(resetError());
  };
  return (
    <div className={`${Styles.container} ${Styles.containerAdminstaff}`}>
      <Box sx={{ mr: "31.25px", height: 45 }}>
        <Box
          component={"p"}
          sx={{
            color: "#001A27",
            fontFamily: "Poppins",
            fontSize: "21.875px",
            fontStyle: "normal",
            fontWeight: role === "admin" ? 700 : 500,
            lineHeight: "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            handleChangeRole("admin");
          }}
        >
          Admin
        </Box>
        {role === "admin" && (
          <Box
            sx={{
              marginTop: "7.9px",
              borderRadius: "6.25px",
              borderBottom: "4.69px solid #D9D9D9",
            }}
          />
        )}
      </Box>
      <Box sx={{ height: 45 }}>
        <Box
          component={"p"}
          sx={{
            color: "#001A27",
            fontFamily: "Poppins",
            fontSize: "21.875px",
            fontStyle: "normal",
            fontWeight: role === "staff" ? 700 : 500,
            lineHeight: "normal",
            cursor: "pointer",
          }}
          onClick={() => {
            handleChangeRole("staff");
          }}
        >
          Staff
        </Box>
        {role === "staff" && (
          <Box
            sx={{
              marginTop: "7.9px",
              borderRadius: "6.25px",
              borderBottom: "4.69px solid #D9D9D9",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default AdminStaffToggle;
