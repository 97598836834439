import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import customerReducer from "./slices/customerSlice";
import masterDataReducer from "./slices/masterDataSlice";
import { DESTROY_SESSION } from "./types";
// const rootReducer = combineReducers({
//   user: userReducer,
//   customer: customerReducer,
//   masterData: masterDataReducer,
// });

// export default rootReducer;

// Combine all reducers.
const appReducer = combineReducers({
  user: userReducer,
  customer: customerReducer,
  masterData: masterDataReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
