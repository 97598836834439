import { useControls } from "react-zoom-pan-pinch";
import "./styles.scss";
import ClearIcon from "@mui/icons-material/Clear";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { IconButton } from "@mui/material";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div className="controls-wrapper">
      <IconButton onClick={() => zoomIn()}>
        <ZoomInIcon />
      </IconButton>
      <IconButton onClick={() => zoomOut()}>
        <ZoomOutIcon />
      </IconButton>
      <IconButton onClick={() => resetTransform()}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};

export default Controls;
