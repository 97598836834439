import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../pages/dashboard/Tabs";
import CustomerList from "../../pages/dashboard/CustomerList";
import { useAuth } from "../../hooks/useAuth";
import Style from "./DashboardTabs.module.css";
import { getList } from "../../services/userServices";
import {
  setActiveDashboardSubTab,
  setCompletedUserList,
  setTodaysListCount,
  setPendingUserList,
  setSelectedContacts,
  setTotalUserList,
  setTodaysTotalListCount,
  setTodaysPendingListCount,
  setTodaysCompletedListCount,
} from "../../redux/slices/masterDataSlice";
import {
  ENDPOINT,
  ERROR_ACTIONS,
  TOAST_CONFIG,
  USER_TYPE,
} from "../../config/constants";
import { Search } from "@mui/icons-material";
import NoDataFound from "../../assets/images/no-data.svg";
import { PAGE_SIZE } from "../../config/config";
import { POST_MULTIPART } from "../../services/axiosRequestHandler";
import { toast } from "react-toastify";

const TodaysListTab = () => {
  const dispatch = useDispatch();
  const {
    activeDashboardTab,
    activeDashboardSubTab,
    todaysListCount,
    totalList,
    pendingList,
    completedList,
    categories,
  } = useSelector((state) => state.masterData);
  const { selectedContacts } = useSelector((state) => state.masterData);
  const { logout, role } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("total");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [refreshOriginalListing, setRefreshOriginalListing] = useState(0);
  const isMobile = useMediaQuery("(max-width:650px)");
  const tabsData = [
    {
      name: "Total",
      id: "total",
      count: todaysListCount?.totalCount,
    },
    {
      name: "Pending",
      id: "pending",
      count: todaysListCount?.pendingCount,
    },
    {
      name: "Completed",
      id: "completed",
      count: todaysListCount?.completedCount,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      !totalList?.data?.data?.length && setLoading(true);
      await getUserList();
      await getPendingList();
      await getCompletedList();
      setLoading(false);
    };
    getData();

    return () => {
      dispatch(setTotalUserList([]));
      dispatch(setPendingUserList([]));
      dispatch(setCompletedUserList([]));
    };
  }, [currentPage]);

  useEffect(() => {
    if (!loading) {
      setSearchData(getDataToRender());
      setTotalPageCount(
        getRecordCount() > PAGE_SIZE
          ? Math.ceil(getRecordCount() / PAGE_SIZE)
          : 0
      );
    }

    return () => {
      dispatch(setTotalUserList([]));
      dispatch(setPendingUserList([]));
      dispatch(setCompletedUserList([]));
    };
  }, [loading, activeSubTab, refreshOriginalListing]);

  useEffect(() => {
    const searchData = setTimeout(() => {
      searchKey ? searchList() : getData();
    }, 1000);

    return () => {
      clearTimeout(searchData);
      setSearchData([]);
      setSearchLoading(true);
    };
  }, [searchKey, activeSubTab]);

  const getData = async () => {
    if (activeSubTab === "total") {
      await getUserList();
    } else if (activeSubTab === "pending") {
      await getPendingList();
    } else if (activeSubTab === "completed") {
      await getCompletedList();
    }
    setRefreshOriginalListing((prev) => prev + 1);
    setSearchLoading(false);
  };

  const searchList = async () => {
    setSearchData([]);
    // setLoading(true);
    try {
      const payload = new FormData();
      payload.append("search", searchKey);
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? activeSubTab === "total"
            ? ENDPOINT.SEARCH_TOTAL_LIST_ADMIN
            : activeSubTab === "pending"
            ? ENDPOINT.SEARCH_PENDING_LIST_ADMIN
            : activeSubTab === "completed"
            ? ENDPOINT.SEARCH_COMPLETED_LIST_ADMIN
            : ""
          : activeSubTab === "total"
          ? ENDPOINT.SEARCH_TOTAL_LIST
          : activeSubTab === "pending"
          ? ENDPOINT.SEARCH_PENDING_LIST
          : activeSubTab === "completed"
          ? ENDPOINT.SEARCH_COMPLETED_LIST
          : "",
        payload
      );
      if (resp?.response?.data?.status === 200) {
        setSearchData(resp?.response?.data?.data);
        if (activeSubTab === "total") {
          dispatch(setTotalUserList(resp?.response?.data));
          dispatch(setTodaysTotalListCount(resp?.response?.data?.data?.length));
        } else if (activeSubTab === "pending") {
          dispatch(setPendingUserList(resp?.response?.data));
          dispatch(
            setTodaysPendingListCount(resp?.response?.data?.data?.length)
          );
        } else if (activeSubTab === "completed") {
          dispatch(setCompletedUserList(resp?.response?.data));
          dispatch(
            setTodaysCompletedListCount(resp?.response?.data?.data?.length)
          );
        }
        // dispatch(
        //   setTodaysTotalListCount(resp?.response?.data?.totalRecordsCount)
        // );
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setSearchLoading(false);
      setRefreshOriginalListing((prev) => prev + 1);
    }
  };

  const getUserList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_ALL_USERS_FOR_ADMIN
        : ENDPOINT.GET_ALL_USERS,
      payload
    );
    if (!resp?.error) {
      dispatch(setTotalUserList(resp?.data));
      dispatch(setTodaysTotalListCount(resp?.data?.totalRecordsCount));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getPendingList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_PENDING_USERS_FOR_ADMIN
        : ENDPOINT.GET_PENDING_USERS,
      payload
    );
    if (!resp?.error) {
      dispatch(setPendingUserList(resp?.data));
      dispatch(setTodaysPendingListCount(resp?.data?.totalRecordsCount));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getCompletedList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_COMPLETED_USERS_FOR_ADMIN
        : ENDPOINT.GET_COMPLETED_USERS,
      payload
    );
    if (!resp?.error) {
      dispatch(setCompletedUserList(resp?.data));
      dispatch(setTodaysCompletedListCount(resp?.data?.totalRecordsCount));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getDataToRender = () => {
    if (activeSubTab === "total") {
      return totalList?.data?.data || [];
    } else if (activeSubTab === "pending") {
      return pendingList?.data?.data || [];
    } else if (activeSubTab === "completed") {
      return completedList?.data?.data || [];
    } else {
      return [];
    }
  };

  const getRecordCount = () => {
    if (activeSubTab === "total") {
      return totalList?.data?.totalRecordsCount || 0;
    } else if (activeSubTab === "pending") {
      return pendingList?.data?.totalRecordsCount || 0;
    } else if (activeSubTab === "completed") {
      return completedList?.data?.totalRecordsCount || 0;
    } else {
      return 0;
    }
  };

  const handleSelectAll = () => {
    const allIds = searchData?.map((data) => data.contactNo);
    dispatch(setSelectedContacts(allIds));
  };

  const deselectAll = () => {
    dispatch(setSelectedContacts([]));
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
    dispatch(setSelectedContacts([]));
    // if (e.target.value?.length >= 2) {
    //   const filteredArray = getDataToRender().filter((item) => {
    //     if (
    //       item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
    //       item.lastName.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //       return item;
    //   });
    //   setSearchData(filteredArray);
    // } else {
    //   setSearchData(getDataToRender());
    // }
  };

  if (loading) {
    return (
      <div className={Style.loaderWrapper}>
        <CircularProgress color="primary" size={56} />
      </div>
    );
  }

  return (
    <>
      <div className={Style.tabsWrapper}>
        <Grid container>
          <Tabs
            tabs={tabsData}
            activeTab={activeSubTab}
            setActiveTab={(param) => {
              setActiveSubTab(param);
              setCurrentPage(1);
              setSearchKey("");
            }}
            totalCount={todaysListCount?.totalCount}
            pendingCount={todaysListCount?.pendingCount}
            completedCount={todaysListCount?.completedCount}
          />
        </Grid>
      </div>
      <div>
        <TextField
          id="searchName"
          name="searchName"
          fullWidth
          autoComplete="off"
          type="text"
          placeholder="Search"
          value={searchKey}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: "8px" }}>
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            sx: {
              textAlign: "end",
              padding: "20px",
            },
          }}
          sx={{
            marginBottom: "30px",
          }}
        />
      </div>
      {role === USER_TYPE.MANAGER && searchData?.length > 0 && (
        <FormLabel
          onClick={
            searchData?.length === selectedContacts?.length
              ? deselectAll
              : handleSelectAll
          }
          sx={{ textDecoration: "", "&:hover": { cursor: "pointer" } }}
        >
          {searchData?.length === selectedContacts?.length
            ? "Deselect All"
            : "Select All"}
        </FormLabel>
      )}
      <Grid container spacing={2} className={Style.custlistBlock}>
        {searchData?.map((customer, index) => (
          <Grid
            key={`${customer.id}${index}`}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            <CustomerList
              data={customer}
              dashboardActions
              showCheckBox={true}
            />
          </Grid>
        ))}
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 14,
            }}
          >
            {totalPageCount && searchData.length > 0 ? (
              <Pagination
                count={totalPageCount}
                variant="outlined"
                shape="rounded"
                color="primary"
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
              />
            ) : null}
          </Box>
        </Grid>
        {!searchData.length && !loading && !searchLoading && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="noResultWrapper">
              <img src={NoDataFound} alt="no-data-found" />
              <Typography variant="h5">No data found!</Typography>
            </div>
          </Grid>
        )}
      </Grid>
      {searchData.length === 0 && searchLoading && (
        <div className={Style.loaderWrapper} style={{ height: "20vh" }}>
          <CircularProgress color="primary" size={56} />
        </div>
      )}
    </>
  );
};

export default TodaysListTab;
