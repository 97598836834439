import React from "react";
import Style from "./style.module.scss";

const PrivacyPolicy = () => {
  return (
    <div className={Style.privacyWrap}>
      <h1>Privacy Policy</h1>
      <div>
        <p>
          This Privacy Notice describes how DATALEKT collects, receives, uses,
          stores, shares, transfers, and processes your personal information.
          <br />
          <br />
          By using our website and affiliated services, you consent to the terms
          of our privacy policy (“<span>Privacy Policy</span>”) in addition to
          our Terms of Use.
          <br />
          <br /> We reserve the right to change this policy from time to time.
          Any changes shall be effective immediately upon the posting of the
          revised Privacy Policy. We encourage you to periodically review this
          page for latest information on our privacy practices.
          <br />
          <br /> When you view content provided by us, we automatically collect
          information about your computer hardware and software. This
          information can include your IP address, browser type, domain names,
          internet service provider (ISP), the files viewed on our site (e.g.,
          HTML pages, graphics, etc.), operating system, clickstream data,
          access times and referring website addresses. This information is used
          by DATALEKT to provide general statistics regarding use of the
          Websites. For these purposes, we do link this automatically-collected
          data to other Personal Information such as name, email address,
          address, and phone number.
        </p>
        <h3>1. SHARING & DISCLOSURE OF DATA WITH THIRD PARTIES</h3>
        <p>
          As required by law, at times we might be required to disclose your
          personal information including personal, transactional and financial
          information to relevant regulatory, and governmental authorities and
          also to our advisors such as law firms and audit firms while
          responding to request from the regulatory authorities. In some cases,
          when we believe that such disclosure is necessary to protect our
          rights, or the rights of others, or to comply with a judicial
          proceeding, court order, or legal process served on our website/app we
          would share such information pursuant to a lawful request from law
          enforcement agencies.
          <br />
          <br />
          Subject to explicit and prior consent from you, we may use information
          created by your use of our App services, not including information
          collected from other sources such as e-mails etc. for marketing
          purposes. This consent is purely voluntary and you may at any time
          choose not to revoke the same.
        </p>
        <h3>2. INFORMATION SUBMITTED BY YOU</h3>
        <p>
          You may be asked to provide Personal Information anytime you are in
          contact with DATALEKT. DATALEKT may collect Personal Information about
          you (and through third parties or other sources where permitted by
          law) when you interact with us, such as creating or registering for an
          account, using our products and services, downloading our software,
          requesting information, participating in surveys, or when you
          otherwise actively send us data and Personal Information. In each such
          instance, you will know what data we collected because you voluntarily
          submitted it. You are not required to provide any Personal Information
          that we have requested, but if you chose not to do so, you may not be
          able to access certain content on our Website or products and services
          offered or we will not be able to respond to any inquiries you may
          have or process your orders. <br />
          <br />
          If you choose to provide DATALEKT with a third party's personal
          information (such as name, email, and phone number), you represent and
          warrant that you have the third party's permission to do so.
        </p>
        <h3>3. INFORMATION AUTOMATICALLY COLLECTED</h3>
        <p>
          Like many other websites, DATALEKT uses automatic data collection
          tools, such as cookies, embedded web links, web beacons, gifs and
          other similar technologies. These tools automatically collect certain
          standard information that your browser sends to our Website when you
          visit our Website or use our Services, such as your domain name, your
          browser type, operating system, web pages you view, links you click,
          your IP address, the length of time you visit our Website or use our
          Services, the referring URL or the webpage that led you to our
          Website. <br />
          <br />
          Combination We and the third parties engaged to provide services on
          our behalf may combine the information we collect from you over time
          and across our Website with Personal Information that you actively
          submit, to the extent permitted by applicable law.
        </p>
        <h3>4. HOW WE MAY USE YOUR INFORMATION</h3>
        <p>
          We may use your information for purposes of operating our business and
          other legitimate purposes permitted by applicable law. We may use your
          personal information in the following ways:
          <br />
          <br />
          <ul>
            <li>
              To communicate with you about our products or services, respond to
              your inquiries, fulfill and process your request for our products
              and services, including helping you to complete transactions,
              process payments, and other customer service purposes.
            </li>
            <li>
              To enable you to use certain features of our product and services
            </li>
            <li>
              To administer the careers portion of the Website and process your
              application.
            </li>
            <li>
              To better understand how visitors use our Website and products and
              services, both on an aggregated and individualized basis, in order
              to improve our Website and products and services and for other
              research and analytical purposes.
            </li>
            <li>
              To send communications to you, either directly or from our
              partners, including for marketing, newsletters, promotions,
              customer satisfaction surveys purposes and other communications
              related to our business.
            </li>
          </ul>
        </p>
        <h3>5. HOW WE SHARE YOUR INFORMATION</h3>
        <p>
          We are not in the business of selling or sharing your Personal
          Information with third parties for marketing purposes unless you have
          granted us permission to do so. We may share and use your Personal
          Information as described below:
        </p>
        <br />
        <br />
        <ul>
          <li>
            With third-party vendors, service providers, contractors or agents
            as required to perform specific purposes on our behalf.
          </li>
          <li>
            To geographically or sectorally relevant resellers and/or
            distributors, particularly if you or your company have purchased
            through a third party before. These companies and persons often
            provide service to DATALEKT customers. They have access to customer
            information to perform their jobs.
          </li>
          <li>
            Where we believe it is necessary to investigate, prevent, or take
            action regarding illegal activities, suspected fraud, brand
            protection, situations involving potential threats to the safety of
            any person, violations of our Terms of Use or this Policy, or as
            otherwise required by law.
          </li>
          <li>
            In connection with a prospective or actual sale, merger, transfer or
            other reorganization of all or parts of our business.
          </li>
        </ul>
        <br />
        <br />
        <p>
          We reserve the right to fully use and share information on an
          aggregated basis, which is not personally identifiable about the user,
          with third parties for marketing, advertising, research or similar
          purposes.
        </p>
        <h3>
          6. WE MAY USE COOKIES AND SIMILAR TECHNOLOGIES TO COLLECT INFORMATION
        </h3>
        <p>
          We and our third-party service providers use cookies and other common
          web tracking mechanisms to track information about your use of our
          Website and Services. We may use this information, which is collected
          and remains in aggregate form, to understand how visitors use our
          Website so that we can better tailor our interactions with you when
          you browse our Website and to improve our Website.
        </p>
        <h1>Cookie</h1>
        <p>
          A cookie is a small data file that our website stores on your computer
          hard drive for record-keeping purposes. We use cookies for website
          administration, such as maintaining your log-in or your preferences,
          to keep track of your navigation through our Website and to observe
          your navigation across other sites in order to better match your
          interests to DATALEKT’s products and services. Most web browsers
          automatically accept cookies. If you prefer not to receive cookies,
          you can set your browser to allow you to refuse the setting of all or
          some cookies. You can also refuse all cookies by turning them off in
          your browser. Although you may still use our Website without the
          cookie, some areas or features of our Website or Services may not
          function for you.
        </p>
        <h3>7. HOW YOU CAN ACCESS AND MODIFY YOUR PERSONAL INFORMATION</h3>
        <p>
          Where permitted by law, Microsemi will take reasonable steps to permit
          you to access and modify Personal Information that you have submitted
          by logging into your account and updating your profile but we reserve
          the right to use Personal Information obtained or provided previously
          to verify your identity or take other
          actionsthatwebelieveareappropriate.
          Youcanalsoexerciseyourrightsofaccess and request corrections,
          suppression or deactivation of your Personal Information under
          applicable data protection laws by contacting us directly at the email
          address provided below. We make good faith efforts to honor reasonable
          requests to access, delete, update, suppress, or correct your data. If
          we are unable to honor your request, we will provide you with an
          explanation. Please note that copies of information that you have
          updated, modified or deleted may remain viewable in cached and
          archived pages of the Website for a period of time.
        </p>
        <h3>8. HOW YOU CAN MANAGE YOUR COMMUNICATIONS PREFERENCES</h3>
        <p>
          We may send periodic promotional or informational emails to you about
          our products, services or our Website. You can manage your
          communication preference by following the instructions included in
          each promotional email from us to either continue to subscribe to or
          unsubscribe from that particular mailing. If you chose to unsubscribe
          to promotional or informational emails on a particular matter, we may
          still send you emails about your account, on any services you have
          requested or received from us or other required communications in
          connection with our products and services. You can also indicate your
          preferences for communication notifications, such as product changes
          or updated literature, or offers are only available electronically in
          your account setting on our Website. Please note that even if you
          choose not to receive electronic communications from us, our terms and
          conditions and policies, such as Website Terms of Use, Privacy Policy,
          and Terms and Condition will still govern your use of our Website.
        </p>
        <h3>9. HOW WE SECURE YOUR INFORMATION</h3>
        <p>
          We intend to protect the personal information entrusted to us and
          treat it securely in accordance with this Privacy Statement. We
          implement physical, administrative and technical safeguards to protect
          your Personal Information from misuse, and unauthorized access,
          disclosure, alteration and destruction. However, please be aware that
          despite our efforts, the internet cannot be guaranteed to be 100%
          secure or error-free and we cannot ensure or warrant the security of
          any Personal Information you provide to us. It is your responsibility
          to protect against unauthorized access to your user ID, password,
          phone number, and computer by, among other things, signing off after
          using a shared computer, choosing a robust password that nobody else
          knows or can easily guess, and keeping your user ID and password
          private. You must notify DATALEKT immediately if you suspect that your
          password or user ID has been compromised. You are solely responsible
          for any unauthorized use of the Website conducted via your password
          and user ID. We are not responsible for any lost, stolen, or
          compromised passwords or for any activity on your account via
          unauthorized password activity.
        </p>
        <h3>10.YOUR CONSENT TO TRANSFER AND STORING OF DATA</h3>
        <p>
          By using our Website or providing any Personal Information to us, you
          fully understand and consent to the transfer, processing, and storage
          of such information outside of your country of residence where data
          protection standards may be different. We will retain and use your
          Personal Information as needed to fulfill the purpose for which it was
          collected. We will retain and use Personal Information as necessary to
          comply with our business requirements, legal obligations, resolve
          disputes or enforce our agreements. Subject to any applicable
          business, legal, or regulatory requirements, we delete or erase
          Personal Information when it is no longer required to enforce our
          rights or meet our obligations.
        </p>
        <h3>11.CHILDREN'S PRIVACY</h3>
        <p>
          Our Website and Services are intended for adults. We do not
          intentionally or knowingly accept, collect, use or disclose personal
          information from anyone under the age of 13 and we request th14at
          individuals under the age of 13 not submit any personal information on
          the Website.
        </p>
        <h3>12.LINK TO THIRD-PARTY SITES</h3>
        <p>
          Our Website and Services may contain links to third party websites or
          automatically produce search results that refer or link to other party
          websites. DATALEKT is providing these links to you only as a
          convenience and the inclusion of any of these links does not
          constitute any endorsement by DATALEKT. Links to other websites are
          not under the control of DATALEKT and DATALEKT is not responsible for
          the content or the accuracy or reliability of any such contents and
          materials made on any linked site or any link contained in a linked
          site, or any changes or updates to such sites. Any access to and use
          of such linked sites is not governed by this Policy, but instead is
          governed by the privacy policies of those third-party websites. We are
          not responsible for the information and privacy practices of such
          third party websites. Third parties may independently collect
          information about their website visitors. We cannot control the
          collection of this information. Your use of third-party websites is at
          your own risk.
        </p>
        <h3>13.EFFECTIVE DATE, POLICY CHANGE</h3>
        <p>
          This Policy is current as of the Effective Date set forth above. We
          reserve the right to change, modify, add or remove portions of this
          Policy at any time and in our sole discretion. If we decide to change
          our Policy, we will post those changes on this page. When you visit
          the Website, you are accepting the current version of this Policy as
          posted on the Website at that time. We recommend that users revisit
          this Policy on occasion to learn of any changes.
        </p>
        <h3>14.HOW YOU CAN CONTACT US</h3>
        <p>
          Please feel free to contact us with any comments or questions you
          might have regarding the information practices described in this
          Policy. You may write to us at{" "}
          <a href="mailto:support@datalekt.com">support@datalekt.com</a>
        </p>
        <h3>15.COMMUNICATING WITH YOU</h3>
        <p>
          By using our Website and products and services or otherwise providing
          Personal information to us, you agree that we may communicate with you
          electronically regarding security, privacy, and administrative issues
          relating to your use. For example, if we learn of a security system's
          breach, we may attempt to notify you electronically by posting a
          notice on our Website, by sending an email, or otherwise contacting
          you.
        </p>
        <h3>16.LAW AND JURISDICTION</h3>
        <p>
          By visiting the Website, or using any of our Services, you agree that
          your Personal Information will be handled as described in this Policy.
          Your use of our Website or Services and any dispute over privacy, are
          subject to this Policy and our Website Terms of Use, including its
          applicable limitations on damages and the resolution of disputes.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
