import React, { useEffect, useState } from "react";
import Style from "./styles/allClients.module.css"; // Import the CSS module
import { Box, CircularProgress, FormLabel } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { getList } from "../../services/userServices";
import { ENDPOINT, ERROR_ACTIONS, USER_TYPE } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllClientList,
  setClientCounts,
  setCompletedClientList,
  setCompletedUserList,
  setTodaysListCount,
  setPendingClientList,
  setPendingUserList,
  setTotalUserList,
  setClientListActiveTab,
} from "../../redux/slices/masterDataSlice";
import { useNavigate } from "react-router-dom";
import { GET, POST } from "../../services/axiosRequestHandler";

function AllClients() {
  const { logout, role } = useAuth();
  const {
    clientCounts,
    allClientList,
    pendingClientList,
    completedClientList,
  } = useSelector((state) => state.masterData);
  const [totalLoading, setTotalLoading] = useState(true);
  const [pendingLoading, setPendingLoading] = useState(true);
  const [completedLoading, setCompletedLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState({
    totalCount: 0,
    pendingCount: 0,
    completedCount: 0,
  });

  const tabs = [
    { name: "Total", value: counts?.totalCount },
    { name: "Pending", value: counts?.pendingCount },
    { name: "Completed", value: counts?.completedCount },
  ];
  useEffect(() => {
    getClientsCount();
  }, []);

  const getClientsCount = async () => {
    try {
      const resp = await POST(ENDPOINT.GET_CLIENTS_COUNT, {});
      if (resp?.response?.data?.status === 200) {
        const data = resp.response?.data;
        setCounts({
          totalCount: data?.totalCount,
          completedCount: data?.completedCount,
          pendingCount: data?.pendingCount,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getPendingClientList = async () => {
    setPendingLoading(true);
    const payload = new FormData();
    payload.append("previousCount", "0");
    const resp = await getList(
      "POST",
      ENDPOINT.GET_PENDING_CLIENTS_FOR_ADMIN,
      payload
    );
    if (!resp?.error) {
      dispatch(setPendingClientList(resp?.data));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
    setPendingLoading(false);
  };

  const getCompletedClientList = async () => {
    setCompletedLoading(true);
    const payload = new FormData();
    payload.append("previousCount", "0");
    const resp = await getList(
      "POST",
      ENDPOINT.GET_COMPLETED_COMPLETED_FOR_ADMIN,
      payload
    );
    if (!resp?.error) {
      dispatch(setCompletedClientList(resp?.data));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
    setCompletedLoading(false);
  };
  if (loading) {
    return (
      <div className="loaderWrapper">
        <CircularProgress size={50} />
      </div>
    );
  }

  const handleRedirect = (tabName) => {
    dispatch(setClientListActiveTab(tabName?.toLowerCase()));
    navigate("/all-clients-list");
  };
  return (
    <div className={Style.container}>
      <div className={Style.tabsContainer}>
        <FormLabel className={Style.titleLabel}>All Clients</FormLabel>
        <div className={Style.tabsContainer}>
          {tabs.map((tab) => (
            <div
              className={Style.tab}
              key={tab.name}
              onClick={() => handleRedirect(tab.name)}
            >
              <p className={Style.tabName}>{tab.name}</p>
              <p className={Style.tabValue}>{tab.value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllClients;
