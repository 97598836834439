import { createContext, useContext, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { POST } from "../services/axiosRequestHandler";
import { ENDPOINT, USER_TYPE } from "../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetError,
  setError,
  setLastLogin,
  setLoading,
  setUserData,
  setUserType,
} from "../redux/slices/userSlice";
import { DESTROY_SESSION } from "../redux/types";
import { setServer } from "../redux/slices/masterDataSlice";
import { decrypt, encrypt } from "../Crypto-Helper";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/dashboard";
  const [user, setUser] = useLocalStorage("token", null);
  const [role, setRole] = useLocalStorage("role", null);

  // call this function when you want to authenticate the user
  const login = async (data, role) => {
    try {
      dispatch(resetError());
      dispatch(setLoading(true));
      const response = await POST(
        role === "admin" ? ENDPOINT.ADMIN_LOGIN : ENDPOINT.STAFF_LOGIN,
        data,
        true
      );
      if (
        response?.response?.data?.status === 200 &&
        response?.response?.data?.data?.jwtToken
      ) {
        dispatch(setUserData(response?.response?.data?.data));
        dispatch(
          setServer(
            response?.response?.data?.data?.sendingBy
              ? response?.response?.data?.data?.sendingBy
              : "weblas"
          )
        );
        dispatch(setUserType(response?.response?.data?.data?.userType));
        dispatch(setLastLogin(response?.response?.data?.lastLogin));
        setRole(response?.response?.data?.data?.userType);
        setUser(response?.response?.data?.data?.jwtToken.replace(/"/g, ""));
        navigate(
          response?.response?.data?.data?.userType === USER_TYPE?.STAFF
            ? "/customer-search"
            : "/dashboard",
          { replace: true }
        );
        // return <Navigate to={from} />;
      } else if (response?.response?.data?.status === 401) {
        dispatch(setError(response?.response?.data?.message));
      } else {
        dispatch(setError(response?.response?.data?.message));
        return <Navigate to="/login" state={{ from: location }} replace />;
      }
    } catch (error) {
      dispatch(setError(error?.message));
    } finally {
      dispatch(setLoading(false));
    }

    // navigate("/customer/add");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setRole(null);
    dispatch({ type: DESTROY_SESSION });
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      role,
      login,
      logout,
    }),
    [user, role]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
