import { Grid, Typography } from "@mui/material";
import React from "react";
import NoData from "../../assets/images/no-data.svg";
const NoDataFound = () => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <div className="noResultWrapper">
        <img src={NoData} alt="no-data-found" />
        <Typography variant="h5">No data found!</Typography>
      </div>
    </Grid>
  );
};

export default NoDataFound;
