import React from "react";
import Error from "../../assets/images/404 Error.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <div className="errorPage">
      <img src={Error} alt="error" />
      <Button variant="text" onClick={() => navigate("/dashboard")}>
        Go to Home!
      </Button>
    </div>
  );
};

export default ErrorPage;
