import { createSlice } from "@reduxjs/toolkit";
import { searchCustomer } from "../actions/customerActions";

const initialState = {
  mobileNumber: "",
  countryCode: "91",
  data: null,
  loading: false,
  hasError: false,
  errorMessage: "",
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setMobileNumber: (state, action) => {
      state.mobileNumber = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setCustomerData: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.errorMessage = "";
      state.hasError = false;
    },
    resetCustomerData: (state, action) => {
      state.data = null;
      state.loading = false;
      state.errorMessage = "";
      state.hasError = false;
      state.mobileNumber = "";
    },
    setError: (state, action) => {
      state.hasError = true;
      state.errorMessage = action.payload;
    },
    resetError: (state, action) => {
      state.error = null;
      state.hasError = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCustomer.pending, (state, action) => {
        state.loading = true;
        state.errorMessage = "";
        state.hasError = false;
      })
      .addCase(searchCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.errorMessage = "";
        state.hasError = false;
      })
      .addCase(searchCustomer.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
        state.hasError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCustomerData,
  setError,
  resetError,
  setLoading,
  resetCustomerData,
  setMobileNumber,
  setCountryCode,
} = customerSlice.actions;

export default customerSlice.reducer;
