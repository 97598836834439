import * as ACTION_TYPE from "../types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ENDPOINT } from "../../config/constants";
import { POST } from "../../services/axiosRequestHandler";
import { useAuth } from "../../hooks/useAuth";

export const searchCustomer = createAsyncThunk(
  ACTION_TYPE.CUSTOMER_SEARCH,
  async (payload, thunkAPI) => {
    const response = await POST(ENDPOINT.CHECK_MOBILE_EXIST, payload.data);
    if (response?.response?.data?.status === 200) {
      return response?.response?.data;
    }
    if (
      response?.response?.data?.status === 401 &&
      response?.response?.data?.message === "User Token Expired"
    ) {
      payload.action.logout();
    } else if (response?.response?.data?.message) {
      return thunkAPI.rejectWithValue(response?.response?.data?.message);
    } else {
      return thunkAPI.rejectWithValue(
        "An error occurred while searching customer details"
      );
    }
  }
);
