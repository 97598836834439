export const ENV_VARIABLE = {
  dev: {
    IMAGE_BASE_URL: "https://datalekt.net",
    API_BASE_URL: "https://datalekt.net/api",
    SITE_KEY: "6LdIb5ApAAAAAGCQftwNIApOolOnDoaxaXf3vYMW",
    SECRET_KEY: "6LdIb5ApAAAAAM_0iInwolK9nLWXz3h81HxAPZrH",
  },
  uat: {
    IMAGE_BASE_URL: "https://bang.datalekt.net/",
    API_BASE_URL: "https://bang.datalekt.net/api",
    SITE_KEY: "6LdIb5ApAAAAAGCQftwNIApOolOnDoaxaXf3vYMW",
    SECRET_KEY: "6LdIb5ApAAAAAM_0iInwolK9nLWXz3h81HxAPZrH",
  },
  prod: {
    IMAGE_BASE_URL: "https://bang.datalekt.com",
    API_BASE_URL: "https://bang.datalekt.com/api",
    SITE_KEY: "6LdIb5ApAAAAAGCQftwNIApOolOnDoaxaXf3vYMW",
    SECRET_KEY: "6LdIb5ApAAAAAM_0iInwolK9nLWXz3h81HxAPZrH",
  },
};
