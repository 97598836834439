import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import {
  MobileDatePicker,
  PickersLayout,
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";

const DatePicker = ({
  value,
  onChange,
  placeholder,
  onOccasionChange,
  occasion,
  ...props
}) => {
  function MyCustomLayout({ occasionType, setOccasion, ...props }) {
    const { toolbar, content, actionBar, tabs } = usePickerLayout(props);

    const handleOcassionChange = (e) => {
      setOccasion(e.target.value);
    };

    return (
      <PickersLayoutRoot
        className={pickersLayoutClasses.root}
        ownerState={props}
        // sx={{
        //   [`.${pickersLayoutClasses.toolbar}`]: {
        //     color: "white",
        //     backgroundColor: "#F95D44",
        //     "& .MuiTypography-root ": {
        //       color: "white",
        //     },
        //   },
        //   [`.${pickersLayoutClasses.actionBar}`]: {
        //     "& .MuiButton-text ": {
        //       color: "#F95D44",
        //     },
        //   },
        //   [`.${pickersLayoutClasses.contentWrapper}`]: {
        //     "& .Mui-selected": {
        //       backgroundColor: "#F95D44",
        //       color: "white",
        //     },
        //     "& .Mui-selected:hover": {
        //       backgroundColor: "#F95D44",
        //       color: "white",
        //     },
        //     "& .Mui-selected:focus": {
        //       backgroundColor: "#F95D44",
        //       color: "white",
        //     },
        //   },
        // }}
      >
        <PickersLayoutContentWrapper
          className={pickersLayoutClasses.contentWrapper}
        >
          <Box>
            <ToggleButtonGroup
              color="primary"
              size="large"
              value={occasionType}
              exclusive
              onChange={handleOcassionChange}
              aria-label="Platform"
              sx={{ width: "100%" }}
            >
              <ToggleButton
                size="large"
                sx={{ borderRadius: "0px", width: "100%", height: "60px" }}
                value="birthday"
              >
                Birthday
              </ToggleButton>
              <ToggleButton
                sx={{ borderRadius: "0px", width: "100%", height: "60px" }}
                value="anniversary"
              >
                Anniversary
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {toolbar}
          {content}
        </PickersLayoutContentWrapper>
        {actionBar}
      </PickersLayoutRoot>
    );
  }
  const isMobile = useMediaQuery("(min-width:320px) and (max-width:1024px)");
  return (
    <MobileDatePicker
      {...props}
      placeholder={placeholder}
      format="DD/MM/YYYY"
      value={value ? dayjs(value) : null}
      onChange={onChange}
      slots={{
        layout: (props) => (
          <MyCustomLayout
            {...props}
            setOccasion={onOccasionChange}
            occasionType={occasion}
          />
        ),
      }}
      slotProps={{
        textField: {
          variant: "standard",
          placeholder: `${placeholder}`,
          sx: {
            marginBottom: "45px",
            width: !isMobile ? "150px" : "120px",
            borderBottom: "2px",
            "& .MuiInput-underline:before": {
              borderBottom: "2px solid #D9D9D9",
            },
          },
          inputProps: {
            fontSize: "16px",
            fontWeight: "400",
            marginBottom: "5px",
          },
        },
        actionBar: {
          actions: ["clear", "accept"],
        },
      }}
    />
  );
};

export default DatePicker;
