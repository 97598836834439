import React, { useEffect, useRef } from "react";
import * as htmlToImage from "html-to-image";
import "./BookingTicket.css"; // Import your CSS or use inline styles
import { convertToAMPM, formatDatetime } from "../../utils";

function BookingTicket({
  restaurant,
  date,
  time,
  peopleCount,
  handlBookingTicket,
}) {
  const domEl = useRef(null);
  useEffect(() => {
    if (domEl?.current) {
      downloadImage();
    }
  }, [domEl?.current]);

  const downloadImage = async () => {
    const dataUrl = await htmlToImage?.toPng(domEl?.current);
    handlBookingTicket(dataUrl);

    // download image
    // const link = document.createElement("a");
    // link.download = "html-to-img.png";
    // link.href = dataUrl;
    // link.click();
  };
  return (
    <div ref={domEl}>
      <div className="booking-ticket">
        <div className="ticket-header">
          <h2>Booking Details</h2>
        </div>
        <div className="ticket-body">
          <p className="rest-name">{restaurant?.name}</p>
          <p className="ticket-info">
            <span>Date:</span> {date ? formatDatetime(date, true) : "N.A"}
          </p>
          <p className="ticket-info">
            <span>Time:</span>{" "}
            {time ? convertToAMPM(new Date(time).toLocaleTimeString()) : "N.A"}
            {/* <span>Time:</span> {time ? time : "N.A"} */}
          </p>
          <p className="ticket-info">
            <span>No. of people:</span>{" "}
            {peopleCount?.toString().length === 1
              ? "0" + peopleCount
              : peopleCount}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BookingTicket;
