import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./layouts/ErrorBoundry";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

console.warn("Env: ", process.env.REACT_APP_ENV);

const theme = createTheme({
  palette: {
    primary: {
      main: "#001A27",
      light: "#F3F3F3",
      dark: "#001A27",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F3F3F3",
      light: "#F3F3F3",
      dark: "#D9D9D9",
      contrastText: "#001A27",
    },
  },
  shape: {
    borderRadius: 12.5,
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        // selected: {
        //   backgroundColor: "#001A27",
        // },
        root: {
          border: "0",
          height: "30px",
          backgroundColor: "#F3F3F3",
          color: "#001A27",
          padding: "10px",
          textTransform: "none",
          borderRadius: "70px",
          fontSize: "14px",
          gap: "10px",
          fontStyle: "normal",
          fontWeight: "500",
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#001A27",
            color: "#fff",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        secondary: {
          backgroundColor: "#F3F3F3",
        },
        root: {
          textTransform: "none",
          minWidth: "100px",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          boxShadow: "none",
          "&:active": {
            boxShadow: "none", // Remove box shadow when Fab is clicked
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          fontSize: "16px",
          height: "51px",
          padding: "20px 40px",
        },
        sizeSmall: {
          fontSize: "16px",
          height: "51px",
          padding: "10px 20px",
        },
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            textAlign: "center",
            color: "#797979",
            fontFamily: "Poppins",
            fontSize: "17.5px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#001A27",
          fontFamily: "Poppins",
          fontSize: "17.5px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: "#001A27",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
          "&:focus": {
            backgroundColor: "#FFFFFF",
          },
        },
        standard: {
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
          "&:focus": {
            backgroundColor: "#FFFFFF",
          },
          borderBottom: "2px",
          "& .MuiSelect-underline:before": {
            borderBottom: "2px solid #D9D9D9",
          },
        },
      },
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <App />
              <ToastContainer />
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (e) => {
    const { waiting: { postMessage = null } = {}, update } = e || {};
    if (postMessage) {
      postMessage({ type: "SKIP_WAITING" });
    }
    update().then(() => {
      window.location.reload();
    });
  },
});
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
