import React, { useEffect, useState } from "react";
import { Button, Grid, useMediaQuery } from "@mui/material";
import Style from "./styles/StaffDashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetDashboardSubTabs,
  resetMainFilter,
  setActiveDashboardTab,
  setActiveExportTab,
  setEmailPayload,
  setPreviousPageSize,
  setPreviousScreen,
  setSelectedContacts,
} from "../../redux/slices/masterDataSlice";
import TodaysListTab from "../../components/dashboard/TodaysListTab";
import ReservationTab from "../../components/dashboard/ReservationTab";
import { useLocation, useNavigate } from "react-router-dom";
import CheckInOutTab from "../../components/dashboard/CheckInOutTab";

const StaffDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedContacts } = useSelector((state) => state.masterData);
  const { activeDashboardTab } = useSelector((state) => state.masterData);

  const isMobile = useMediaQuery("(max-width:650px)");
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollBy(0, 0);
    }
  }, []);

  useEffect(() => {
    dispatch(resetMainFilter());
    dispatch(setEmailPayload(null));
    dispatch(setPreviousPageSize(""));
    return () => {
      // dispatch(setActiveDashboardTab("todaysList"));
      dispatch(setActiveExportTab("database"));
    };
  }, []);
  const handleChangeActiveTab = (tab) => {
    dispatch(setActiveDashboardTab(tab));
    dispatch(resetDashboardSubTabs());
    dispatch(setSelectedContacts([]));
  };

  const handleNext = () => {
    navigate("/send-template");
    dispatch(setPreviousScreen(location?.pathname));
  };

  return (
    <>
      <div className={Style.staffDashboardWrappper}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={4} className={Style.listBtnWrapper}>
              <Button
                variant="contained"
                color={
                  activeDashboardTab === "todaysList" ? "primary" : "secondary"
                }
                sx={{
                  border: "2px solid black",
                  fontSize: isMobile ? "small" : "inherit",
                  padding: isMobile && 0,
                }}
                fullWidth
                onClick={() => handleChangeActiveTab("todaysList")}
              >
                Todays List
              </Button>
            </Grid>
            <Grid item xs={4} className={Style.listBtnWrapper}>
              <Button
                variant="contained"
                color={
                  activeDashboardTab === "reservations"
                    ? "primary"
                    : "secondary"
                }
                sx={{
                  border: "2px solid black",
                  fontSize: isMobile ? "small" : "inherit",
                  padding: isMobile && 0,
                }}
                fullWidth
                onClick={() => handleChangeActiveTab("reservations")}
              >
                Reservations
              </Button>
            </Grid>
            <Grid item xs={4} className={Style.listBtnWrapper}>
              <Button
                variant="contained"
                color={
                  activeDashboardTab === "checkInOut" ? "primary" : "secondary"
                }
                sx={{
                  border: "2px solid black",
                  fontSize: isMobile ? "small" : "inherit",
                  padding: isMobile && 0,
                }}
                fullWidth
                onClick={() => handleChangeActiveTab("checkInOut")}
              >
                Check In / Out
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={Style.emptyLine}></div>
        {activeDashboardTab === "todaysList" ? <TodaysListTab /> : null}
        {activeDashboardTab === "reservations" ? <ReservationTab /> : null}
        {activeDashboardTab === "checkInOut" ? <CheckInOutTab /> : null}
      </div>
      {selectedContacts?.length > 0 && (
        <div className="footerWrapper">
          <div className="submitBtnWrapper">
            <Button size="medium" variant="contained" onClick={handleNext}>
              Next
            </Button>
            <div className="footer"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default StaffDashboard;
