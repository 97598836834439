import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../pages/dashboard/Tabs";
import CustomerList from "../../pages/dashboard/CustomerList";
import { useAuth } from "../../hooks/useAuth";
import Style from "./DashboardTabs.module.css";
import { getList } from "../../services/userServices";
import {
  setSelectedContacts,
  setCheckInList,
  setCheckOutList,
  setCheckInCounts,
  setCheckOutCount,
} from "../../redux/slices/masterDataSlice";
import {
  ENDPOINT,
  ERROR_ACTIONS,
  TOAST_CONFIG,
  USER_TYPE,
} from "../../config/constants";
import { Search } from "@mui/icons-material";
import NoDataFound from "../../assets/images/no-data.svg";
import { PAGE_SIZE } from "../../config/config";
import { toast } from "react-toastify";
import { POST_MULTIPART } from "../../services/axiosRequestHandler";

const CheckInOutTab = () => {
  const dispatch = useDispatch();
  const { checkInList, checkOutList, checkInOutCount } = useSelector(
    (state) => state.masterData
  );
  const { selectedContacts } = useSelector((state) => state.masterData);
  const { logout, role } = useAuth();
  const [searchKey, setSearchKey] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState("checkIn");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [refreshOriginalListing, setRefreshOriginalListing] = useState(0);
  const isMobile = useMediaQuery("(max-width:650px)");
  const tabsData = [
    {
      name: "Check-Ins",
      id: "checkIn",
      count: checkInOutCount?.checkInCount,
    },
    {
      name: "Check-Outs",
      id: "checkOut",
      count: checkInOutCount?.checkOutCount,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getCheckInList();
      await getCheckOutList();
      setLoading(false);
    };
    getData();
  }, [currentPage]);

  useEffect(() => {
    if (!loading) {
      setSearchData(getDataToRender());
      setTotalPageCount(
        getRecordCount() > PAGE_SIZE
          ? Math.ceil(getRecordCount() / PAGE_SIZE)
          : 0
      );
    }

    return () => {
      dispatch(setCheckInList([]));
      dispatch(setCheckOutList([]));
    };
  }, [loading, activeSubTab, refreshOriginalListing]);

  useEffect(() => {
    const searchData = setTimeout(() => {
      searchKey ? searchList() : getData();
    }, 1000);

    return () => {
      clearTimeout(searchData);
      setSearchData([]);
      setSearchLoading(true);
    };
  }, [searchKey, activeSubTab]);

  const getData = async () => {
    if (activeSubTab === "checkIn") {
      await getCheckInList();
    } else if (activeSubTab === "checkOut") {
      await getCheckOutList();
    }
    setRefreshOriginalListing((prev) => prev + 1);
    setSearchLoading(false);
  };

  const searchList = async () => {
    setSearchData([]);
    try {
      const payload = new FormData();
      // payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      payload.append("search", searchKey);
      const resp = await POST_MULTIPART(
        role === USER_TYPE.MANAGER
          ? activeSubTab === "checkIn"
            ? ENDPOINT.SEARCH_CHECKIN_LIST_ADMIN
            : activeSubTab === "checkOut"
            ? ENDPOINT.SEARCH_CHECKOUT_LIST_ADMIN
            : ""
          : activeSubTab === "checkIn"
          ? ENDPOINT.SEARCH_CHECKIN_LIST
          : activeSubTab === "checkOut"
          ? ENDPOINT.SEARCH_CHECKOUT_LIST
          : "",
        payload
      );
      if (resp?.response?.data?.status === 200) {
        setSearchData(resp?.response?.data?.data);
        if (activeSubTab === "checkIn") {
          dispatch(setCheckInList(resp?.response?.data));
          dispatch(setCheckInCounts(resp?.response?.data?.data?.length));
        } else if (activeSubTab === "checkOut") {
          dispatch(setCheckOutList(resp?.response?.data));
          dispatch(setCheckOutCount(resp?.response?.data?.data?.length));
        }
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      setSearchLoading(false);
      setRefreshOriginalListing((prev) => prev + 1);
    }
  };

  const getCheckInList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_CHECK_IN_LIST_ADMIN
        : ENDPOINT.GET_CHECK_IN_LIST,
      payload
    );
    if (!resp?.error) {
      dispatch(setCheckInList(resp?.data));
      dispatch(setCheckInCounts(resp?.data?.totalRecordsCount));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getCheckOutList = async () => {
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      role === USER_TYPE.MANAGER
        ? ENDPOINT.GET_CHECK_OUT_LIST_ADMIN
        : ENDPOINT.GET_CHECK_OUT_LIST,
      payload
    );
    if (!resp?.error) {
      dispatch(setCheckOutList(resp?.data));
      dispatch(setCheckOutCount(resp?.data?.totalRecordsCount));
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      logout();
    }
  };

  const getDataToRender = () => {
    if (activeSubTab === "checkIn") {
      return checkInList?.data?.data || [];
    } else if (activeSubTab === "checkOut") {
      return checkOutList?.data?.data || [];
    } else {
      return [];
    }
  };
  const getRecordCount = () => {
    if (activeSubTab === "checkIn") {
      return checkInList?.data?.totalRecordsCount || 0;
    } else if (activeSubTab === "checkOut") {
      return checkOutList?.data?.totalRecordsCount || 0;
    } else {
      return 0;
    }
  };

  const handleSelectAll = () => {
    const allIds = searchData?.map((data) => data.contactNo);
    dispatch(setSelectedContacts(allIds));
  };

  const deselectAll = () => {
    dispatch(setSelectedContacts([]));
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
    dispatch(setSelectedContacts([]));
    // if (e.target.value?.length >= 2) {
    //   const filteredArray = getDataToRender().filter((item) => {
    //     if (
    //       item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
    //       item.lastName.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //       return item;
    //   });
    //   setSearchData(filteredArray);
    // } else {
    //   setSearchData(getDataToRender());
    // }
  };

  // if (loading) {
  //   return (
  //     <div className={Style.loaderWrapper}>
  //       <CircularProgress color="primary" size={56} />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className={Style.tabsWrapper}>
        <Grid container>
          <Tabs
            tabs={tabsData}
            activeTab={activeSubTab}
            setActiveTab={(param) => {
              setActiveSubTab(param);
              setCurrentPage(1);
              setSearchKey("");
            }}
          />
        </Grid>
      </div>
      <div>
        <TextField
          id="searchName"
          name="searchName"
          fullWidth
          autoComplete="off"
          type="text"
          placeholder="Search"
          value={searchKey}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ paddingLeft: "8px" }}>
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            sx: {
              textAlign: "end",
              padding: "20px",
            },
          }}
          sx={{
            marginBottom: "30px",
          }}
        />
      </div>
      <>
        {loading ? (
          <div className={Style.loaderWrapper}>
            <CircularProgress color="primary" size={56} />
          </div>
        ) : (
          <>
            {role === USER_TYPE.MANAGER && searchData?.length > 0 && (
              <FormLabel
                onClick={
                  searchData?.length === selectedContacts?.length
                    ? deselectAll
                    : handleSelectAll
                }
                sx={{ textDecoration: "", "&:hover": { cursor: "pointer" } }}
              >
                {searchData?.length === selectedContacts?.length
                  ? "Deselect All"
                  : "Select All"}
              </FormLabel>
            )}
            <Grid container spacing={2}>
              {searchData?.map((customer, index) => (
                <Grid
                  key={`${customer.id}${index}`}
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  <CustomerList
                    data={customer}
                    dashboardActions
                    showCheckBox={true}
                    checkInOut
                  />
                </Grid>
              ))}
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 14,
                  }}
                >
                  {totalPageCount && searchData.length > 0 ? (
                    <Pagination
                      count={totalPageCount}
                      variant="outlined"
                      shape="rounded"
                      color="primary"
                      page={currentPage}
                      onChange={(event, value) => setCurrentPage(value)}
                    />
                  ) : null}
                </Box>
              </Grid>
              {!searchData.length && !loading && !searchLoading && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="noResultWrapper">
                    <img src={NoDataFound} alt="no-data-found" />
                    <Typography variant="h5">No data found!</Typography>
                  </div>
                </Grid>
              )}
            </Grid>
            {searchLoading && (
              <div className={Style.loaderWrapper} style={{ height: "20vh" }}>
                <CircularProgress color="primary" size={56} />
              </div>
            )}
          </>
        )}
      </>
    </>
  );
};

export default CheckInOutTab;
