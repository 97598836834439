import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateId: null,
  server: "weblas", //weblas | wassenger
  openMainFilter: false,
  openReservationFilter: false,
  openCheckInFilter: false,
  openQRModal: false,
  openChooseServerModal: false,
  previousDashboardTab: "todaysList",
  activeDashboardTab: "todaysList",
  activeExportTab: "database",
  activeDashboardSubTab: "total",
  clientListActiveTab: "total",
  countryCodes: [],
  previousPageSize: "",
  todaysListCount: {
    totalCount: 0,
    pendingCount: 0,
    completedCount: 0,
  },
  reservationCount: {
    yesterday: 0,
    today: 0,
    tomorrow: 0,
  },
  clientCounts: {
    totalCount: 0,
    pendingCount: 0,
    completedCount: 0,
  },
  checkInOutCount: {
    checkInCount: 0,
    checkOutCount: 0,
  },
  allClientList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  pendingClientList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  completedClientList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  totalList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  pendingList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  completedList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  age: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  categories: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  templates: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  birthdays: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  anniversaries: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  whoseHereList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  yesterdaysList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  todaysList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  tomorrowsList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  restaurantsList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  checkInList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  checkOutList: {
    data: [],
    hasError: false,
    errorMessage: "",
    loading: false,
  },
  selectedContacts: [],
  selectedIds: [],
  mainFilter: {
    fromDate: null,
    fromTime: {
      hh: "",
      mm: "",
      meridiem: "",
    },
    toDate: null,
    toTime: {
      hh: "",
      mm: "",
      meridiem: "",
    },
    startTime: null,
    endTtime: null,
    rating: 0,
    age: [],
    category: [],
    dnd: false,
    gender: [],
    spending: [],
    restaurants: [],
  },
  customerReservationData: {
    firstName: "",
    lastName: "",
  },
  selectedReservationIds: [],
  previousScreen: "/dashboard",
  emailPayload: null,
};

export const masterDataSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {
    setAgeLoading: (state, action) => {
      state.age.loading = action.payload;
    },
    setCategoriesLoading: (state, action) => {
      state.categories.loading = action.payload;
    },
    setAgeError: (state, action) => {
      state.age.hasError = true;
      state.age.errorMessage = action.payload;
    },
    setCategoriesError: (state, action) => {
      state.categories.hasError = true;
      state.categories.errorMessage = action.payload;
    },
    setAgeData: (state, action) => {
      state.age.data = action.payload;
      state.age.errorMessage = "";
      state.age.hasError = false;
      state.age.loading = false;
    },
    setCategoriesData: (state, action) => {
      state.categories.data = action.payload;
      state.categories.errorMessage = "";
      state.categories.hasError = false;
      state.categories.loading = false;
    },
    resetAgeError: (state, action) => {
      state.age.hasError = false;
      state.age.errorMessage = "";
    },
    resetCategoriesError: (state, action) => {
      state.categories.hasError = false;
      state.categories.errorMessage = "";
    },
    setTotalUserList: (state, action) => {
      state.totalList.data = action.payload;
      state.totalList.errorMessage = "";
      state.totalList.hasError = false;
      state.totalList.loading = false;
    },
    setPendingUserList: (state, action) => {
      state.pendingList.data = action.payload;
      state.pendingList.errorMessage = "";
      state.pendingList.hasError = false;
      state.pendingList.loading = false;
    },
    setCompletedUserList: (state, action) => {
      state.completedList.data = action.payload;
      state.completedList.errorMessage = "";
      state.completedList.hasError = false;
      state.completedList.loading = false;
    },
    setTemplates: (state, action) => {
      state.templates.data = action.payload;
      state.templates.errorMessage = "";
      state.templates.hasError = false;
      state.templates.loading = false;
    },
    setBirthdays: (state, action) => {
      state.birthdays.data = action.payload;
      state.birthdays.errorMessage = "";
      state.birthdays.hasError = false;
      state.birthdays.loading = false;
    },
    setAnniversaries: (state, action) => {
      state.anniversaries.data = action.payload;
      state.anniversaries.errorMessage = "";
      state.anniversaries.hasError = false;
      state.anniversaries.loading = false;
    },

    setAllClientList: (state, action) => {
      state.allClientList.data = action.payload;
      state.allClientList.errorMessage = "";
      state.allClientList.hasError = false;
      state.allClientList.loading = false;
    },
    setPendingClientList: (state, action) => {
      state.pendingClientList.data = action.payload;
      state.pendingClientList.errorMessage = "";
      state.pendingClientList.hasError = false;
      state.pendingClientList.loading = false;
    },
    setCompletedClientList: (state, action) => {
      state.completedClientList.data = action.payload;
      state.completedClientList.errorMessage = "";
      state.completedClientList.hasError = false;
      state.completedClientList.loading = false;
    },
    setWhosHereList: (state, action) => {
      state.whoseHereList.data = action.payload;
      state.whoseHereList.errorMessage = "";
      state.whoseHereList.hasError = false;
      state.whoseHereList.loading = false;
    },
    setYesterdaysList: (state, action) => {
      state.yesterdaysList.data = action.payload;
      state.yesterdaysList.errorMessage = "";
      state.yesterdaysList.hasError = false;
      state.yesterdaysList.loading = false;
    },
    setTodaysList: (state, action) => {
      state.todaysList.data = action.payload;
      state.todaysList.errorMessage = "";
      state.todaysList.hasError = false;
      state.todaysList.loading = false;
    },
    setTomorrowsList: (state, action) => {
      state.tomorrowsList.data = action.payload;
      state.tomorrowsList.errorMessage = "";
      state.tomorrowsList.hasError = false;
      state.tomorrowsList.loading = false;
    },
    setRestaurantsList: (state, action) => {
      state.restaurantsList.data = action.payload;
      state.restaurantsList.errorMessage = "";
      state.restaurantsList.hasError = false;
      state.restaurantsList.loading = false;
    },
    setCheckInList: (state, action) => {
      state.checkInList.data = action.payload;
      state.checkInList.errorMessage = "";
      state.checkInList.hasError = false;
      state.checkInList.loading = false;
    },
    setCheckOutList: (state, action) => {
      state.checkOutList.data = action.payload;
      state.checkOutList.errorMessage = "";
      state.checkOutList.hasError = false;
      state.checkOutList.loading = false;
    },
    setActiveDashboardTab: (state, action) => {
      state.activeDashboardTab = action.payload;
    },
    setPreviousDashboardTab: (state, action) => {
      state.previousDashboardTab = action.payload;
    },
    setActiveExportTab: (state, action) => {
      state.activeExportTab = action.payload;
    },
    setActiveDashboardSubTab: (state, action) => {
      state.activeDashboardSubTab = action.payload;
    },
    setTodaysListCount: (state, action) => {
      state.todaysListCount = action.payload;
    },
    setTodaysTotalListCount: (state, action) => {
      state.todaysListCount.totalCount = action.payload;
    },
    setTodaysPendingListCount: (state, action) => {
      state.todaysListCount.pendingCount = action.payload;
    },
    setTodaysCompletedListCount: (state, action) => {
      state.todaysListCount.completedCount = action.payload;
    },
    setClientCounts: (state, action) => {
      state.clientCounts = action.payload;
    },
    setCheckInCounts: (state, action) => {
      state.checkInOutCount.checkInCount = action.payload;
    },
    setCheckOutCount: (state, action) => {
      state.checkInOutCount.checkOutCount = action.payload;
    },
    setSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    resetDashboardSubTabs: (state, action) => {
      state.activeDashboardSubTab = "total";
    },
    setYestedaysCount: (state, action) => {
      state.reservationCount.yesterday = action.payload;
    },
    setTodaysCount: (state, action) => {
      state.reservationCount.today = action.payload;
    },
    setTomorrowsCount: (state, action) => {
      state.reservationCount.tomorrow = action.payload;
    },
    setOpenMainFilter: (state, action) => {
      state.openMainFilter = action.payload;
    },
    setOpenReservationFilter: (state, action) => {
      state.openReservationFilter = action.payload;
    },
    setOpenCheckInFilter: (state, action) => {
      state.openCheckInFilter = action.payload;
    },
    setMainFilter: (state, action) => {
      state.mainFilter = action.payload;
    },
    resetMainFilter: (state, action) => {
      state.mainFilter = initialState.mainFilter;
    },
    setClientListActiveTab: (state, action) => {
      state.clientListActiveTab = action.payload;
    },
    setOpenQRModal: (state, action) => {
      state.openQRModal = action.payload;
    },
    setOpenChooseServerModal: (state, action) => {
      state.openChooseServerModal = action.payload;
    },
    setServer: (state, action) => {
      state.server = action.payload;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setCountryCodes: (state, action) => {
      state.countryCodes = action.payload;
    },
    setCustomerReservationData: (state, action) => {
      state.customerReservationData = action.payload;
    },
    resetCustomerReservationData: (state, action) => {
      state.customerReservationData = initialState.customerReservationData;
    },
    setPreviousScreen: (state, action) => {
      state.previousScreen = action.payload;
    },
    setPreviousPageSize: (state, action) => {
      state.previousPageSize = action.payload;
    },
    setSelectedReservations: (state, action) => {
      state.selectedReservationIds = action.payload;
    },
    setEmailPayload: (state, action) => {
      state.emailPayload = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAgeData,
  setAgeError,
  setAgeLoading,
  setCategoriesData,
  setCategoriesError,
  setCategoriesLoading,
  resetAgeError,
  resetCategoriesError,
  setTotalUserList,
  setPendingUserList,
  setCompletedUserList,
  setActiveDashboardTab,
  setActiveDashboardSubTab,
  setTodaysListCount,
  setTemplates,
  setSelectedContacts,
  setSelectedIds,
  setBirthdays,
  setAnniversaries,
  setAllClientList,
  setPendingClientList,
  setCompletedClientList,
  setClientCounts,
  setWhosHereList,
  resetDashboardSubTabs,
  setYesterdaysList,
  setTodaysList,
  setTomorrowsList,
  setYestedaysCount,
  setTodaysCount,
  setTomorrowsCount,
  setOpenMainFilter,
  setOpenReservationFilter,
  setMainFilter,
  resetMainFilter,
  setRestaurantsList,
  setClientListActiveTab,
  setOpenCheckInFilter,
  setOpenQRModal,
  setOpenChooseServerModal,
  setCheckInList,
  setCheckOutList,
  setCheckInCounts,
  setCheckOutCount,
  setServer,
  setTemplateId,
  setTodaysTotalListCount,
  setTodaysPendingListCount,
  setTodaysCompletedListCount,
  setCountryCodes,
  setCustomerReservationData,
  resetCustomerReservationData,
  setPreviousScreen,
  setPreviousPageSize,
  setActiveExportTab,
  setSelectedReservations,
  setEmailPayload,
  setPreviousDashboardTab,
} = masterDataSlice.actions;

export default masterDataSlice.reducer;
