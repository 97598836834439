import React from "react";
import "./Pagination.scss";
export const Pagination = () => {
  return (
    <div className="pagination">
      <span className="active">1</span>
      <span>2</span>
      <span>3</span>
      <span>4</span>
    </div>
  );
};
