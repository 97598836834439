import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { POST } from "../../services/axiosRequestHandler";

const QRModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const handleSendQR = async () => {
    setLoading(true);

    try {
      const resp = await POST(ENDPOINT.SEND_QR);
      if (resp?.response?.data?.status === 200) {
        toast.success(resp?.response?.data?.message, TOAST_CONFIG);
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
    }

    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">QR Code</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          request QR Code for scan on your registered E-mail address ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          No
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleSendQR}
          autoFocus
        >
          {loading ? <CircularProgress color="primary" size={30} /> : "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRModal;
