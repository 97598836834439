import React from "react";
import { FormLabel, Box } from "@mui/material";
import OptionPill from "../../components/option-pill/OptionPill";

function Reservations({
  categories,
  categoryState,
  handleCategoryOptionPillClick,
}) {
  return (
    <div>
      <FormLabel>Restaurants:</FormLabel>
      <Box
        sx={{
          flexGrow: "1",
          marginTop: "15px",
        }}
      >
        {categories?.map((category) => (
          <OptionPill
            key={category.id}
            active={categoryState.id === category.id}
            text={category.name}
            onClick={() => handleCategoryOptionPillClick(category)}
          />
        ))}
      </Box>
    </div>
  );
}

export default Reservations;
