import React from "react";
import Styles from "./styles/MessageCardStyle.module.css";
import { Avatar } from "@mui/material";
import {
  convertNumbers,
  convertToAMPM,
  formatDatetime,
  formatTableNumber,
} from "../../utils";

const ReservationCard = ({ data }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.reservationInfoWrapper}>
        <p className={Styles.userName}>{data?.name}</p>
        <div className={Styles.flexContainer}>
          <div>
            {data?.tableNumberDetails && (
              <p className={Styles.table}>
                Table No.:{" "}
                {formatTableNumber(
                  data?.tableNumberDetails
                    ? data.tableNumberDetails +
                    (data?.pdrNumberDetails
                      ? `, ${data.pdrNumberDetails}`
                      : "")
                    : data?.pdrNumberDetails
                )}
              </p>
            )}
            <p className={Styles.message}>
              No. of People:{" "}
              {data?.peopleCount?.toString()?.length === 1
                ? "0" + data?.peopleCount
                : data?.peopleCount}
            </p>
          </div>


          <div className={Styles.uidMessageWrapper}>
            {data?.spending ? (
              <p className={Styles.message}>
                Spending: {data?.spending || "N.A"}
              </p>
            ) : null}

            {data?.username ? (
              <p className={Styles.message}>
                uid: {data?.username || "N.A"}
              </p>
            ) : null}
          </div>

        </div>

        <p className={Styles.dateTime}>
          {data?.booking_date ? formatDatetime(data?.booking_date, true) : null}{" "}
          &nbsp;
          {data?.booking_time ? convertToAMPM(data?.booking_time) : null}
        </p>
        {data?.cancelationReason ? (
          <>
            <p className={Styles.message}>
              Status: Cancelled ({data?.cancelationReason})
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReservationCard;
