import React from "react";
import {
  FormLabel,
  Avatar,
  Button,
  TextField,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Styles from "./styles/customer-search-result.module.css";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerData,
  setError,
  setMobileNumber,
} from "../../redux/slices/customerSlice";
import { setLoading } from "../../redux/slices/userSlice";
import { POST } from "../../services/axiosRequestHandler";
import { ENDPOINT } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";

function CustomerSearchResult() {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { loading, data, mobileNumber, countryCode } = useSelector(
    (state) => state.customer
  );
  const [mobile, setMobile] = React.useState(mobileNumber);
  const [imagePresent, setImagePresent] = React.useState(true);

  const handleImageError = () => {
    setImagePresent(false);
  };

  const handleAddRedirect = () => {
    navigate("/customer/add");
  };
  const handleRedirect = () => {
    navigate("/reservation");
  };

  const handleAddLater = () => {
    navigate("/customer/add-later");
  };

  const handleMobileNumberChange = (event) => {
    if (event.target.value.length > 10 || isNaN(event.target.value)) {
      return;
    }
    setMobile(event.target.value);
    if (event.target.value.length === 10) {
      dispatch(setMobileNumber(event.target.value));
      checkMobileNumberExist(event.target.value);
    }
  };

  const checkMobileNumberExist = async (value) => {
    try {
      dispatch(setLoading(true));
      const response = await POST(ENDPOINT.CHECK_MOBILE_EXIST, {
        contactNo: value,
      });
      dispatch(setLoading(false));
      if (response?.response?.data?.status === 200) {
        dispatch(setCustomerData(response?.response?.data));
        navigate("/customer-search");
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else if (response?.response?.data?.status === 201) {
        dispatch(setError(response.response.data.message));
        if (response?.response?.data?.isDataAvailable === 0) {
          // navigate("/customer-search-result");
        }
      }
    } catch (error) {
      dispatch(setError(error.message));
    }
  };

  const isMobile = useMediaQuery("(min-width:320px) and (max-width:1024px)");

  return (
    <div className={Styles.container}>
      <div className={Styles.avatarWrapper}>
        <Avatar
          alt=" "
          src="/path/to/your/image.jpg"
          onError={handleImageError}
          sx={{
            height: "100%",
            width: "100%",
            marginBottom: "50px",
            backgroundColor: imagePresent ? "transparent" : "#eee",
            borderRadius: "15px",
            border: "1px solid #d9d9d9",
          }}
        />
      </div>
      <div className={Styles.actionButtonContainer}>
        <Button
          variant="contained"
          onClick={handleAddRedirect}
          sx={{
            mr: "10px",
            width: "175px",
            padding: !isMobile ? "" : "20px 28px",
          }}
        >
          Add Record
        </Button>
        <Button variant="contained" onClick={handleRedirect}>
          Reservation
        </Button>
      </div>
      <TextField
        disabled={true}
        id="mobileNumber"
        name="mobileNumber"
        autoComplete="off"
        type="text"
        placeholder="**********"
        value={mobile}
        // onChange={handleMobileNumberChange}
        InputProps={{
          startAdornment: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "25%",
                marginLeft: "11%",
                borderRight: "2px solid #D9D9D9",
                height: "14px",
                paddingRight: "15px",
              }}
              className={Styles.addrecordInput}
            >
              +{countryCode}
            </Box>
          ),
          endAdornment: (
            <IconButton
              onClick={() => {
                navigate("/customer-search");
              }}
            >
              <CancelIcon />
            </IconButton>
          ),
        }}
        inputProps={{
          sx: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "10px",
            verticleAlign: "middle",
            color: "#797979",
            fontSize: "17.5px",
            fontWeight: "500",
          },
        }}
        sx={{
          width: "313px",
          marginBottom: "24px",
        }}
      />
      <div className={Styles.notFoundTextWrapper}>
        <FormLabel className={Styles.noRecord}>No Record Found</FormLabel>
        <p className={Styles.guestNotText}>Guest not Enrolled.</p>
      </div>
      <div className={Styles.footerWraper}>
        <div className={Styles.submitBtnWrapper}>
          <Button
            size="medium"
            variant="contained"
            color="secondary"
            sx={{
              border: "3px solid black",
              fontWeight: "700",
            }}
            onClick={handleAddLater}
          >
            Add Later
          </Button>
        </div>
        <div className={Styles.footer}></div>
      </div>
    </div>
  );
}

export default CustomerSearchResult;
